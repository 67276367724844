<template>
  <label
    :class="[
      'vast-service-order-now-checkbox',
      {
        'vast-service-order-now-checkbox--checked': checked,
        'vast-service-order-now-checkbox--disabled': disabled,
      }
    ]"
  >
    <input
      v-model="checked"
      :disabled="disabled"
      class="vast-service-order-now-checkbox__el"
      type="checkbox"
    >

    <div class="vast-service-order-now-checkbox__icon">
      <IconCheck class="vast-service-order-now-checkbox__check" />
    </div>

    <div
      v-show="label"
      class="vast-service-order-now-checkbox__label"
    >
      {{ label }}
    </div>
  </label>
</template>

<script>
import IconCheck from '@/components/icons/IconCheck.vue';

export default {
  components: {
    IconCheck,
  },
  props: {
    value: Boolean,
    disabled: Boolean,
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    checked: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.vast-service-order-now-checkbox {
  user-select: none;
  cursor: pointer;

  &__icon {
    border: 1px solid var(--selected-elements-color);
    width: 20px;
    height: 20px;
    transform: translateY(2px);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: background .1s;
    border-radius: 5px;
  }

  &__check {
    opacity: 0;
    transition: opacity .1s;
  }

  &__el {
    display: none;
  }

  &--checked {
    .vast-service-order-now-checkbox {
      &__icon {
        background: var(--selected-elements-color);
      }
      &__check {
        opacity: 1;
      }
    }
  }

  &--disabled {
    cursor: default;

    .vast-service-order-now-checkbox {
      &__icon {
        border: 1px solid rgba(#000, .15);
        background: #d4d4d4;
        opacity: .6;
      }
    }
  }

  &__label {
    display: inline-block;
    margin-left: 10px;
    font-size: 14px;
  }
}
</style>
