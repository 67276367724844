import ServiceType from '@/classes/ServiceType';
import objectAssignByDictionary from '@/utils/objectAssignByDictionary';

const dictionary = {
  service_types: 'serviceTypes',
  url_examples: 'urlPlaceholder',
};

export default class Network {
  constructor(data) {
    this.id = 0;
    this.name = '';
    this.icon = '';
    this.urlPlaceholder = '';
    this.serviceTypes = [];

    if (data) {
      objectAssignByDictionary(this, data, dictionary);

      this.id = Number(this.id);
      this.serviceTypes = this.serviceTypes
        .map((serviceType) => new ServiceType(serviceType))
        .filter((serviceType) => serviceType.services?.length > 0);
    }
  }
}
