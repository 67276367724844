export default {
  namespaced: true,
  state: {
    isShown: false,
    serviceType: {},
    previousConfigurationService: {},
    previousSelectedTarget: [],
    mode: null,
    selectedTargetId: null,
    selectables: null,
  },
  mutations: {
    SET_IS_SHOWN: (state, val) => { state.isShown = val; },
    SET_SERVICE_TYPE: (state, val) => { state.serviceType = val; },
    SET_PREVIOUS_CONFIGURATION_SERVICE: (state, val) => {
      state.previousConfigurationService = val;
    },
    SET_PREVIOUS_SELECTED_TARGET: (state, val) => {
      state.previousSelectedTarget = val;
    },
    SET_MODE: (state, { mode, id, selectables }) => {
      state.mode = mode;
      state.selectedTargetId = id;
      state.selectables = selectables;
    },
  },
};
