<template>
  <ModalContent>
    <ModalHeader @close="checkClose">
      {{ selectedNetwork.name }}
      {{ serviceType.name }}
    </ModalHeader>

    <ModalBody>
      <div class="vast-service-order-now-service-type-settings">
        <div class="block-title">
          {{ locale.service }}
        </div>

        <template>
          <div
            class="vast-service-order-now-service-type-settings__service"
            @click="openServiceSelect"
          >
            <div class="vast-service-order-now-service-type-settings__service-info">
              <p class="vast-service-order-now-dashed-link">
                {{ selectedService.name }}
              </p>

              <p
                :class="[
                  'vast-service-order-now-service-type-settings__service-cost',
                  { 'subtotal-discount': hasDiscount }
                ]">
                <span class="vast-service-order-now-service-type-settings__service-old-cost">
                  {{ defaultCost }}
                </span>

                <span class="vast-service-order-now-service-type-settings__service-new-cost">
                  {{ cost }}
                </span>
              </p>
            </div>

            <div class="vast-service-order-now-service-type-settings__service-arrow">
              <IconChevron width="11px" />
            </div>
          </div>
        </template>

        <div class="block-title">
          {{ locale.quantity }}
        </div>

        <div class="vast-service-order-now-service-type-settings__quantity">
          <quantity-input
            :service-type="serviceType"
            :selected-service="selectedService"
            :disabled="isQuantityDisabled"
            @clear-errors="clearErrors('quantity')"
          />
        </div>

        <p :class="[
          'vast-service-order-now-service-type-settings__cost',
          { 'subtotal-discount': hasDiscount }
        ]">
          <span class="vast-service-order-now-service-type-settings__cost-title">
            {{ locale.price }}
          </span>

          <span class="vast-service-order-now-service-type-settings__old-cost">
            {{  defaultCost }}
          </span>

          <span class="vast-service-order-now-service-type-settings__new-cost">
            {{ cost }}
          </span>
        </p>

        <target-field
          v-for="target in selectedService.targets"
          :key="target.id"
          :target="target"
          :service-type="serviceType"
          @clear-errors="clearErrors()"
        />
      </div>
    </ModalBody>

    <ModalFooter>
      <ApplySettingsBtn @click="close"/>
    </ModalFooter>
  </ModalContent>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import IconChevron from '@/components/icons/IconChevron.vue';
import ServiceType from '@/classes/ServiceType';
import TargetField from '@/components/ServiceSettingsModal/TargetField.vue';
import QuantityInput from './QuantityInput.vue';
import ApplySettingsBtn from './ApplySettingsBtn.vue';

export default {
  components: {
    TargetField,
    IconChevron,
    QuantityInput,
    ApplySettingsBtn,
  },
  props: {
    serviceType: ServiceType,
  },
  watch: {
    // eslint-disable-next-line
    'selectedService' () {
      if (!this.selectedService.allowedUseCountries) {
        this.serviceType.settings.countries = [];
      }

      if (!this.selectedService.dripFeedIsAllowed) {
        this.serviceType.settings.dripFeedIsEnabled = false;
        this.serviceType.settings.dripFeedRuns = 2;
        this.serviceType.settings.dripFeedMinutesBetweenRuns = 0;
      }
    },
  },
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
    ...mapGetters([
      'selectedNetwork',
    ]),
    ...mapState([
      'errors',
    ]),
    ...mapState('costs', [
      'subtotals',
    ]),
    selectedService() {
      return this.serviceType.services.find(
        (service) => service.id === this.serviceType.settings.selectedServiceId,
      );
    },
    isQuantityDisabled() {
      return this.selectedService.calculableQuantity;
    },
    cost() {
      return this.subtotal?.cost || 0;
    },
    defaultCost() {
      return this.subtotal?.defaultCost;
    },
    hasDiscount() {
      return this.subtotal?.priceType === 'discount';
    },
    subtotal() {
      return this.subtotals[this.serviceType.id];
    },
  },
  created() {
    if (this.isQuantityDisabled) {
      this.serviceType.settings.quantity = 0;
    }
  },
  methods: {
    clearErrors() {
      this.errors[`${this.serviceType.id}.targetable_values`] = [];
    },
    close() {
      this.$emit('close');
    },
    checkClose() {
      this.$emit('checkClose');
    },
    openServiceSelect() {
      this.$store.commit('serviceSettingsModal/SET_MODE', { mode: 'serviceSelect' });
      this.$store.commit('serviceSettingsModal/SET_PREVIOUS_CONFIGURATION_SERVICE', {
        ...this.serviceType.settings,
        selectedServiceId: this.serviceType.settings.selectedServiceId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  padding: 20px;
}
.block-title {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: bold;

  &__icon {
    transform: translateY(1px);
    margin-right: 10px;
  }
}

.vast-service-order-now-service-type-settings {
  min-height: calc(100% - 40px);
  padding-bottom: 20px;
  &__service {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 8px 12px;
    background: var(--input-background-color);
    color: var(--input-text-color);
    border-radius: 6px;
    &:hover {
      cursor: pointer;
    }
  }
  &__service-old-cost, &__old-cost {
    display: none;
    margin-right: 4px;
    color: var(--input-text-color);
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
    line-height: 10px;
    text-decoration: line-through;
  }
  &__service-new-cost, &__new-cost {
    color: var(--input-text-color);
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
  }
  &__old-cost {
    color: var(--modal-text-color);
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
  }
  &__new-cost {
    color: var(--modal-text-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
  &__cost-title {
    margin-right: 20px;
    color: var(--modal-text-color);
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
  &__service-cost {
    margin: 0px;
  }
  &__cost {
    margin: 15px 0px;
  }
  &__service-cost, &__cost {
    &.subtotal-discount {
      .vast-service-order-now-service-type-settings {
        &__service-old-cost, &__old-cost {
          display: inline;
        }
        &__service-new-cost, &__new-cost {
          color: var(--errors-color);
        }
      }
    }
  }
  &__service-arrow {
    transform: rotate(-90deg);
    color: var(--input-text-color);
  }
  &__quantity {
    width: 50%;
  }
}
.vast-service-order-now-dashed-link {
  margin: 0px;
  color: var(--input-text-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

@media (max-width: 768px) {
  .vast-service-order-now-service-type-settings {
    &__quantity {
      width: 100%;
    }
  }
}
</style>
