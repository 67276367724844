<template>
  <div
    class="vast-service-order-now-service-description"
  >
    <div
      v-if="service.exampleLink"
      class="mb-2"
    >
      Example link:
      {{ service.exampleLink }}
    </div>

    <div
      v-if="service.geo"
      class="mb-2"
    >
      Geo:
      {{ service.geo }}
    </div>

    <div
      v-if="service.guarantee"
      class="mb-2"
    >
      Guarantee:
      {{ service.guarantee }}
    </div>

    <div
      v-if="service.refill"
      class="mb-2"
    >
      Refill:
      {{ service.refill }}
    </div>

    <div
      v-if="service.requirements"
      class="mb-2"
    >
      Requirements:
      {{ service.requirements }}
    </div>

    <div
      v-if="service.speed"
      class="mb-2"
    >
      Speed:
      {{ service.speed }}
    </div>

    <div
      v-if="service.startTime"
      class="mb-2"
    >
      Start time:
      {{ service.startTime }}
    </div>

    <div v-html="service.shortDescription" />
  </div>
</template>

<script>
export default {
  props: {
    service: Object,
  },
};
</script>

<style lang="scss" scoped>
.vast-service-order-now-service-description {
  font-size: 12px;
  margin-top: 7px;
  padding-right: 8px;
}
</style>
