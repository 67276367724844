<template>
  <button
    class="back"
    @click="click"
  >
    <IconBack />
  </button>
</template>

<script>
import IconBack from '@/components/icons/IconBack.vue';

export default {
  name: 'ButtonBack',
  components: {
    IconBack,
  },
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.back {
  display: inline-block;
  cursor: pointer;
  background: 0;
  border: 0;
  border-radius: 4px;
  outline: none;
  transform: translateY(1px);
  color: var(--secondary-button-color);
  transition: all 0.25s;
  &:hover {
    box-shadow: 6px 6px 10px 0px rgba(0, 0, 0, 0.10);
  }
}
</style>
