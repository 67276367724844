<template>
  <InputWithControl
    v-model="serviceType.settings.quantity"
    :min="selectedService.min"
    :max="selectedService.max"
    :step="selectedService.step"
    :errors="errors[`${serviceType.id}.quantity`]"
    :disabled="disabled"
    :calculableQuantity="selectedService.calculableQuantity"
    @input="clearErrors"
    @change="changeHandler"
  />
</template>

<script>
import Service from '@/classes/Service';
import ServiceType from '@/classes/ServiceType';
import getStepRoundedServiceQuantity from '@/utils/getStepRoundedServiceQuantity';
import { mapGetters, mapState } from 'vuex';
import { debounce } from 'debounce';
import InputWithControl from '@/components/InputWithControl.vue';

export default {
  components: {
    InputWithControl,
  },
  props: {
    serviceType: ServiceType,
    selectedService: Service,
    disabled: Boolean,
  },
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
    ...mapGetters([
      'selectedNetwork',
    ]),
    ...mapState([
      'errors',
    ]),
  },
  created() {
    this.debouncedRoundQty = debounce(this.roundQty, 500);
  },
  methods: {
    clearErrors() {
      this.$emit('clear-errors');
    },
    roundQty() {
      this.serviceType.settings.quantity = getStepRoundedServiceQuantity(
        this.serviceType.settings.quantity,
        this.selectedService,
      );
    },
    changeHandler() {
      this.debouncedRoundQty();
    },
  },
};
</script>
