<template>
  <div class="vast-service-order-now-apply-button">
    <OBtn
      v-on="$listeners"
    >
      {{ locale.save }}
    </OBtn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.vast-service-order-now-apply-button {
  margin-top: 40px;
  border-radius: 10px;
  & > * {
    width: 100%;
    text-align: center;
    display: block !important;
    border-radius: 10px!important;
    border: 0px!important;
  }
}
</style>
