import EventCollector from './utils/EventsCollector';
/* eslint-disable no-underscore-dangle */
export default class WidgetJSApi {
  constructor() {
    this._actionsCallbacks = {};
    this._eventsCallbacks = {};

    this._eventCollector = new EventCollector([
      'onNetworksLoaded',
      'onPaymentMethodsLoaded',
    ]);

    this._eventCollector.then((payload) => {
      setTimeout(() => this.dispatchEvent('onInit', payload), 100);
    });
  }

  onInit(callback) {
    this._addEventCallback('onInit', callback);
    return this;
  }

  onInvoiceCreated(callback) {
    this._addEventCallback('onInvoiceCreated', callback);
    return this;
  }

  onCost(callback) {
    this._addEventCallback('onCost', callback);
    return this;
  }

  onNetworksLoaded(callback) {
    this._addEventCallback('onNetworksLoaded', callback);
    return this;
  }

  onPaymentMethodsLoaded(callback) {
    this._addEventCallback('onPaymentMethodsLoaded', callback);
    return this;
  }

  onNetworkSelected(callback) {
    this._addEventCallback('onNetworkSelected', callback);
    return this;
  }

  destroy() {
    this._callAction('destroy');
  }

  getNetworks() {
    return this._callAction('getNetworks');
  }

  getSelectedNetwork() {
    return this._callAction('getSelectedNetwork');
  }

  selectNetwork(networkValue) {
    this._callAction('selectNetwork', networkValue);
    return this;
  }

  getShownServiceTypes() {
    return this._callAction('getShownServiceTypes');
  }

  selectServiceTypes(servicesValues) {
    this._callAction('selectServiceTypes', servicesValues);
    return this;
  }

  setServiceTypeSettings({
    serviceTypeId,
    settings,
  }) {
    this._callAction('setServiceTypeSettings', {
      serviceTypeId,
      settings,
    });
    return this;
  }

  onServicesSelected(callback) {
    this._addEventCallback('onServicesSelected', callback);
    return this;
  }

  onFormSend(callback) {
    this._addEventCallback('onFormSend', callback);
    return this;
  }

  onURLInput(callback) {
    this._addEventCallback('onURLInput', callback);
    return this;
  }

  getURL() {
    return this._callAction('getURL');
  }

  setURL(url) {
    this._callAction('setURL', url);
    return this;
  }

  getSelectedServiceTypesIds() {
    return this._callAction('getSelectedServiceTypesIds');
  }

  getEmail() {
    return this._callAction('getEmail');
  }

  setEmail(email) {
    this._callAction('setEmail', email);
    return this;
  }

  onEmailInput(callback) {
    this._addEventCallback('onEmailInput', callback);
    return this;
  }

  addActionCallback(actionName, callback) {
    this._actionsCallbacks[actionName] = [
      ...(this._actionsCallbacks[actionName] || []),
      callback,
    ];
  }

  _callAction(actionName, payload) {
    if (!this._actionsCallbacks[actionName]) {
      return false;
    }

    if (this._actionsCallbacks[actionName].length === 1) {
      const result = this._actionsCallbacks[actionName][0](payload);

      if (result) {
        return JSON.parse(JSON.stringify(result));
      }

      return result;
    }

    this._actionsCallbacks[actionName].forEach((callback) => callback(payload));
    return true;
  }

  _addEventCallback(eventName, callback) {
    this._eventsCallbacks[eventName] = [
      ...(this._eventsCallbacks[eventName] || []),
      callback,
    ];
  }

  dispatchEvent(eventName, payload) {
    this._eventCollector.event(eventName);

    if (!this._eventsCallbacks[eventName]) {
      return;
    }

    this._eventsCallbacks[eventName].forEach(
      (callback) => callback(
        this,
        payload ? JSON.parse(JSON.stringify(payload)) : payload,
      ),
    );
  }

  hideNetworks() {
    this._callAction('hideNetworks');
    return this;
  }

  showNetworks() {
    this._callAction('showNetworks');
    return this;
  }

  hideServiceTypes() {
    this._callAction('hideServiceTypes');
    return this;
  }

  showServiceTypes() {
    this._callAction('showServiceTypes');
    return this;
  }

  onCheckboxServiceTypeSelect(callback) {
    this._addEventCallback('onCheckboxServiceTypeSelect', callback);
    return this;
  }

  onEndInputQuantity(callback) {
    this._addEventCallback('onEndInputQuantity', callback);
    return this;
  }

  onClickSettingsServiceType(callback) {
    this._addEventCallback('onClickSettingsServiceType', callback);
    return this;
  }

  onCheckboxServiceSelect(callback) {
    this._addEventCallback('onCheckboxServiceSelect', callback);
    return this;
  }

  onGoToPaymentPage(callback) {
    this._addEventCallback('onGoToPaymentPage', callback);
    return this;
  }

  onURLInsert(callback) {
    this._addEventCallback('onURLInsert', callback);
    return this;
  }

  onClickShowMore(callback) {
    this._addEventCallback('onClickShowMore', callback);
    return this;
  }
}
