import getUrlQueryParams from '@/utils/getUrlQueryParams';
import Cookies from 'js-cookie';
import getDomainName from './getDomainName';

export function saveAffiliateCode() {
  const query = getUrlQueryParams(window.location.href);
  if (query.ref) {
    Cookies.set('affiliate_code', query.ref, {
      expires: 60,
      domain: process.env.NODE_ENV === 'production'
        ? `.${getDomainName(window.location.hostname)}`
        : '',
    });
  }
}

export function getSavedAffiliateCode() {
  return Cookies.get('affiliate_code');
}
