<template>
  <OCheckBox
    class="country"
    v-model="isSelected"
    :label="country.name"
  />
</template>

<script>
export default {
  props: {
    country: Object,
    value: Array,
    viewType: String,
  },
  computed: {
    isSelected: {
      get() {
        return this.selectedCountries.includes(this.country.id);
      },
      set(val) {
        if (val) {
          this.selectedCountries = this.viewType === 'select' ? [this.country.id] : [
            ...this.selectedCountries,
            this.country.id,
          ];
        } else {
          this.selectedCountries = this.selectedCountries.filter(
            (id) => id !== this.country.id,
          );
        }
      },
    },
    selectedCountries: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.country {
  display: block;
  padding: 10px;

  &:hover {
    background: rgba(#000, .05);
  }
}
</style>
