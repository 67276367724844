<template>
  <div class="vast-input-with-control">
    <div class="vast-input-with-control__minus-container">
      <span
        :class="[
          'vast-input-with-control__minus',
          { 'disabled': isDisabledMin }
        ]"
        @mousedown.prevent="startMinus"
        @mouseup.prevent="removeMinusInterval"
        @mouseleave.prevent="removeMinusInterval"
      >
        <span>
          -
        </span>
      </span>
    </div>

    <o-input
      v-model="computedValue"
      type="number"
      :min="min"
      :step="step"
      :text-align="textAlign"
      :disabled="isDisabledInput"
      :errors="errors"
      @input="input"
      @blur="blur"
      @change="change"
    />

    <div class="vast-input-with-control__plus-container">
      <span
        :class="[
          'vast-input-with-control__plus',
          { 'disabled': isDisabledMax }
        ]"
        @mousedown.prevent="startPlus"
        @mouseup.prevent="removePlusInterval"
        @mouseleave.prevent="removePlusInterval"
      >
        <span>
          +
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputWithControl',
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    disabled: Boolean,
    calculableQuantity: Boolean,
    value: {
      type: [String, Number],
      default: '',
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    step: {
      type: Number,
      default: null,
    },
    textAlign: {
      type: String,
      default: 'center',
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('update', newVal);
      },
    },
    isDisabledInput() {
      return this.calculableQuantity;
    },
    isDisabledMin() {
      return this.calculableQuantity || this.isMinQuantity;
    },
    isDisabledMax() {
      return this.calculableQuantity || this.isMaxQuantity;
    },
    isMaxQuantity() {
      return this.computedValue >= this.max;
    },
    isMinQuantity() {
      return this.computedValue <= this.min;
    },
  },
  methods: {
    minus(quantity = 1) {
      if (this.isDisabledMin) return;

      this.computedValue = this.value - quantity < this.min
        ? this.min
        : this.value - quantity;
    },
    plus(quantity = 1) {
      if (this.isDisabledMax) return;

      this.computedValue = this.value + quantity > this.max
        ? this.max
        : this.value + quantity;
    },
    startMinus() {
      this.minus();

      this.startTime = window.performance.now();
      this.minusInterval = setInterval(() => {
        const timeNow = window.performance.now();
        const howMuchQuantity = parseInt(((timeNow - this.startTime) / 200)
          * (this.max / 100), 10);

        this.minus(howMuchQuantity);
      }, 200);
    },
    startPlus() {
      this.plus();

      this.startTime = window.performance.now();
      this.plusInterval = setInterval(() => {
        const timeNow = window.performance.now();
        const howMuchQuantity = parseInt(((timeNow - this.startTime) / 200)
          * (this.max / 100), 10);

        this.plus(howMuchQuantity);
      }, 200);
    },
    removeMinusInterval() {
      clearInterval(this.minusInterval);
    },
    removePlusInterval() {
      clearInterval(this.plusInterval);
    },
    blur() {
      this.$emit('blur');

      if (this.computedValue > this.max) {
        this.computedValue = this.max;

        return;
      }

      this.computedValue = this.computedValue < this.min
        ? this.min
        : this.computedValue;
    },
    input() {
      this.$emit('input');
    },
    change() {
      this.$emit('change');
    },
  },
};
</script>

<style lang="scss" scoped>
.vast-input-with-control {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 16px;
  border-radius: 12px;
  background: #F3F3F6;
  ::v-deep {
    .field {
      width: 100%;
      margin: 0px;
    }
    .label, .field__hint {
      display: none;
    }
    INPUT {
      padding: 12px 45px!important;
    }
  }

  &__minus, &__plus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: var(--primary-button-color);
    color: var(--primary-button-text-color);
    font-size: 20px;
    &:hover {
      cursor: pointer;
    }
    &.disabled {
      background: var(--primary-button-disabled-color);
      color: var(--primary-button-disabled-text-color);
      cursor: default;
    }
  }

  &__minus {
    SPAN {
      margin-top: -3px;
    }
  }

  &__minus-container, &__plus-container {
    position: absolute;
    bottom: 0px;
    padding: 12px;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__minus-container {
    left: 0px;
  }

  &__plus-container {
    right: 0px;
  }
}
</style>
