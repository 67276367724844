import { debounce } from 'debounce';

export default (api, widgetJSApiInstance) => ({
  namespaced: true,
  state: {
    loading: false,
    networkIsLoaded: false,
    networks: [],
  },
  mutations: {
    SET_LOADING: (state, value) => { state.loading = value; },
    SET_NETWORK_IS_LOADED: (state, value) => { state.networkIsLoaded = value; },
    SET_NETWORKS: (state, value) => { state.networks = value; },
  },
  actions: {
    fetchNetworks({ commit }) {
      commit('SET_LOADING', true);
      api.fetchNetworks()
        .then((networks) => {
          commit('SET_NETWORKS', networks);
          commit('SET_NETWORK_IS_LOADED', true);
          widgetJSApiInstance.dispatchEvent('onNetworksLoaded', { networks });
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    },
    async identifyNetworkByLink({ rootState, dispatch }, url) {
      const networkId = await api.identifyNetworkByLink(url);
      if (!networkId || parseInt(networkId, 10) === parseInt(rootState.selectedNetworkId, 10)) {
        return;
      }
      dispatch('setSelectedNetworkId', networkId, { root: true });
      dispatch('networkChangedFromLink', networkId, { root: true });
    },
    identifyNetworkAndFetchServiceTypes: debounce(async ({ dispatch }, url) => {
      await dispatch('identifyNetworkByLink', url);
      dispatch('url/fetchAvailableServicesTypesForUrl', null, { root: true });
    }, 600),
  },
  getters: {
    availableNetworks(state, getters, rootState) {
      if (
        !rootState.config.availableNetworks
        || rootState.config.availableNetworks.length === 0
      ) {
        return state.networks;
      }

      return state.networks.filter(
        (item) => rootState.config.availableNetworks.includes(item.id),
      );
    },
  },
});
