<template>
  <div>
    <OTabs
      v-model="tab"
      :tabs="tabs"
      class="vast-filter-by-selected-countries"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    value: Boolean,
    countries: Array,
  },
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
    tabs() {
      return [
        {
          label: this.locale.allCountries,
          value: 0,
        },
        {
          label: `${this.locale.onlySelectedCountries} (${this.countries.length})`,
          value: 1,
        },
      ];
    },
    tab: {
      get() {
        return +this.value;
      },
      set(val) {
        this.$emit('input', !!val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.vast-filter-by-selected-countries {
  ::v-deep .tabs__tab {
    color: var(--modal-text-color);
  }
}
</style>
