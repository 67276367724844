<template>
  <ModalContent>
    <ModalHeader @close="close">
      <ButtonBack @click="close" />

      {{ locale.service }}
    </ModalHeader>

    <ModalBody>
      <div class="vast-service-select__services">
        <div
          v-for="service in serviceType.services"
          :key="service.id"
        >
          <Package
            v-if="service.packageContents.length"
            v-model="selectedServiceId"
            :service="service"
          />
          <Service
            v-else
            v-model="selectedServiceId"
            :service="service"
          />
        </div>
      </div>

      <div class="vast-service-select__save">
        <OBtn
          @click="save"
        >
          {{ locale.settingsModalSuccess }}
        </OBtn>
      </div>
    </ModalBody>
  </ModalContent>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import ServiceType from '@/classes/ServiceType';
import ButtonBack from '@/components/ButtonBack.vue';
import Service from './Service.vue';
import Package from './Package.vue';

export default {
  components: {
    ButtonBack,
    Service,
    Package,
  },
  inject: [
    'widgetJSApiInstance',
  ],
  props: {
    value: Number,
    serviceType: ServiceType,
  },
  computed: {
    ...mapState([
      'countries',
    ]),
    ...mapGetters('config', [
      'locale',
    ]),
    selectedServiceId: {
      get() {
        return this.value;
      },
      set(val) {
        this.widgetJSApiInstance.dispatchEvent('onCheckboxServiceSelect', val);
        this.$emit('input', val);
      },
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      this.$emit('save');
    },
  },
};
</script>

<style lang="scss" scoped>
.vast-service-select {
  &__services {
    height: calc(100% - 60px);
    overflow: auto;
  }
  &__save {
    margin-top: auto;
    & > * {
      width: 100%;
      text-align: center;
      display: block !important;
      border-radius: 10px!important;
      border: 0px!important;
    }
  }
}
::v-deep .modal-body {
  display: flex;
  flex-direction: column;
}
</style>
