<template functional>
<svg
  width="22"
  height="22"
  viewBox="0 0 22 22"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M10.9982 20.1654C16.0608 20.1654 20.1649 16.0613
    20.1649 10.9987C20.1649 5.93609 16.0608 1.83203 10.9982
    1.83203C5.9356 1.83203 1.83154 5.93609 1.83154 10.9987C1.83154
    16.0613 5.9356 20.1654 10.9982 20.1654Z"
    stroke="#FF5252"
    stroke-width="1.33333"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M13.75 8.25L8.25 13.75"
    stroke="#FF5252"
    stroke-width="1.33333"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M8.25 8.25L13.75 13.75"
    stroke="#FF5252"
    stroke-width="1.33333"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: [String, Number],
      default: '100%',
    },
    height: {
      type: [String, Number],
      default: '100%',
    },
  },
};
</script>
