<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.875 4.95834L6.375 13.4583L2.47916 9.56251L3.47791
        8.56376L6.375 11.4538L13.8762 3.95959L14.875 4.95834Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
};
</script>
