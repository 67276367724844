<template>
  <div
    :class="[
      'vast-service-order-now-card',
      {
        'vast-service-order-now-card--selected': isSelected,
        'vast-service-order-now-card--inactive': isInactive,
        'vast-service-order-now-card--dense': dense,
        'vast-service-order-now-card--selected_from-link': networkIsChangedFromLink.color
      }
    ]"
    :style="{
      minHeight: minHeight,
    }"
    @click="isSelected = value"
  >
    <slot />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  model: {
    prop: 'selected',
    event: 'input',
  },
  props: {
    disabled: Boolean,
    dense: Boolean,
    minHeight: {
      type: String,
      default: '0px',
    },
    value: {
      validator: () => true,
    },
    selected: {
      validator: () => true,
    },
  },
  computed: {
    ...mapState([
      'networkIsChangedFromLink',
    ]),
    isInactive() {
      return !this.value || this.disabled;
    },
    isSelected: {
      get() {
        return this.value === this.selected;
      },
      set(val) {
        if (this.isInactive) {
          return;
        }

        this.$emit('input', val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.vast-service-order-now-card {
  position: relative;
  box-sizing: border-box;
  width: 78px;
  height: 80px;
  flex-shrink: 0;
  padding: 10px;
  border: 1px solid var(--network-selected-color);
  margin: 0 0px 15px 0;
  transition: all .15s;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;

  &--selected {
    background-color: var(--network-selected-text-color);
    &.vast-service-order-now-card--selected_from-link {
      border-color: #ffb116!important;
      background-color: #ffb116!important;
    }
    .vast-service-order-now-card {
      &__check {
        opacity: 1;
      }

      &__logo {
        opacity: 1;
      }
    }
  }
}
</style>
