<template>
  <div class="vast-modal-save-settings">
    <div class="vast-modal-save-settings__container">
      <p class="vast-modal-save-settings__title">
        {{ locale.settingsModalTitle }}
      </p>

      <div class="vast-modal-save-settings__save">
        <OBtn
          @click="save"
          height="41"
        >
          {{ locale.settingsModalSuccess }}
        </OBtn>
      </div>

      <div class="vast-modal-save-settings__dont-save">
        <OBtn
          class="vast-modal-save-settings__dont-save-button"
          text
          height="41"
          @click="dontSave"
        >
          {{ locale.settingsModalRefusal }}
        </OBtn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ModalSaveSettings',
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
  },
  methods: {
    save() {
      this.$emit('save');
    },
    dontSave() {
      this.$emit('dontSave');
    },
  },
};
</script>

<style lang="scss" scoped>
.vast-modal-save-settings {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  &__container {
    width: 100%;
    max-width: 350px;
    padding: 41px 65px;
    border-radius: 12px;
    background-color: var(--modal-background);
    box-shadow: 6px 6px 30px 0px rgba(0, 0, 0, 0.20);
    color: var(--modal-text-color);
  }
  &__title {
    margin-bottom: 24px;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  &__save {
    & > * {
      width: 100%;
      text-align: center;
      display: block !important;
      border-radius: 10px!important;
      border: 0px!important;
    }
  }
  &__dont-save-button {
    width: 100%;
    margin-top: 10px;
    border-radius: 8px !important;
  }
}
</style>
