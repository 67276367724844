var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CardSelect',{class:[
    'vast-payment-method',
    { 'vast-payment-method--selected':
      _vm.computedSelectedPaymentMethodId === _vm.paymentMethod.id } ],attrs:{"value":_vm.paymentMethod.id,"min-height":_vm.minHeight,"dense":""},model:{value:(_vm.computedSelectedPaymentMethodId),callback:function ($$v) {_vm.computedSelectedPaymentMethodId=$$v},expression:"computedSelectedPaymentMethodId"}},[_c('img',{class:[
      'vast-payment-method__logo',
      {
        'vast-payment-method__logo--selected':
          _vm.computedSelectedPaymentMethodId === _vm.paymentMethod.id,
      }
    ],attrs:{"src":_vm.paymentMethod.icon,"alt":_vm.paymentMethod.name}}),_c('div',{staticClass:"vast-payment-method__label"},[_vm._v(" "+_vm._s(_vm.paymentMethod.name)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }