<template>
  <div class="countries-list">
    <div>
      <OInput
        v-model="search"
        class="search-field"
        :placeholder="locale.searchCountries"
      />

      <FilterBySelectedCountries
        :countries="selectedCountries"
        v-model="isOnlySelected"
      />
    </div>

    <div class="countries-list__scroll">
      <div
        v-if="isEmpty"
        class="countries-list__empty"
      >
        {{ emptyText }}
      </div>

      <Country
        v-for="country in filteredCountries"
        :key="country.id"
        :country="country"
        :view-type="viewType"
        v-model="selectedCountries"
      />
    </div>

  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import escapeRegExp from '@/utils/escapeRegexp';
import createAssoc from '@/utils/createAssoc';
import Country from './Country.vue';
import FilterBySelectedCountries from './FilterBySelectedCountries.vue';

export default {
  components: {
    Country,
    FilterBySelectedCountries,
  },
  props: {
    value: Array,
    countries: {
      type: Array,
      required: true,
    },
    viewType: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    search: '',
    isOnlySelected: false,
  }),
  computed: {
    ...mapState('serviceSettingsModal', ['selectables']),
    ...mapGetters('config', [
      'locale',
    ]),
    filteredCountries() {
      return this.selectableCountries.filter(
        (country) => {
          if (this.search) {
            if (this.search.match(',')) {
              const searchingIsoCodes = createAssoc(this.search.split(','));
              return searchingIsoCodes[country.iso_code_2] || false;
            }

            const regexp = new RegExp(escapeRegExp(this.search), 'ig');
            if (!country.name.match(regexp)) {
              return false;
            }
          }

          if (this.isOnlySelected) {
            return this.selectedCountries.includes(country.id);
          }

          return true;
        },
      );
    },
    selectableCountries() {
      return this.selectables?.length !== 0 ? this.countries.filter((language) => (
        this.selectables?.includes(language.id)
      )) : [];
    },
    selectedCountries: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    isEmpty() {
      return this.filteredCountries?.length === 0;
    },
    emptyText() {
      if (this.isOnlySelected && !this.search) {
        return this.locale.noCountriesSelected;
      }

      return this.locale.noCountriesFound;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-field {
  display: block;
  width: 100%;
  ::v-deep {
    .label {
      display: none;
    }
  }
}

.countries-list {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  &__scroll {
    height: 100%;
    overflow: auto;
  }

  &__empty {
    text-align: center;
    user-select: none;
    color: var(--modal-text-color);
  }
}
</style>
