import locales from '@/locales';

export default {
  namespaced: true,
  state: {
    apiUrl: '',
    apiToken: '',
    language: '',
    currency: '',
    availableNetworks: [],
    availableServiceTypes: [],
    networksAreShown: true,
    title: '',
    theme: {},
    currencySymbol: {
      USD: '$',
      RUB: '₽',
    },
  },
  mutations: {
    SET_API_URL: (state, val) => { state.apiUrl = val; },
    SET_API_TOKEN: (state, val) => { state.apiToken = val; },
    SET_LANGUAGE: (state, val) => { state.language = val; },
    SET_CURRENCY: (state, val) => { state.currency = val; },
    SET_AVAILABLE_NETWORKS: (state, val) => { state.availableNetworks = val; },
    SET_AVAILABLE_AVAILABLE_SERVICE_TYPES: (state, val) => { state.availableServiceTypes = val; },
    SET_NETWORKS_ARE_SHOWN: (state, val) => { state.networksAreShown = val; },
    SET_TITLE: (state, val) => { state.title = val; },
    SET_THEME: (state, val) => { state.theme = val; },
  },
  getters: {
    locale(state) {
      return locales[state.language];
    },
  },
};
