<template>
  <div
    class="vast-service-service-select"
    ref="serviceTypesContainer"
  >
    <ServiceSettingsModal />

    <OLabel class="vast-service-service-select__label">
      {{ selectServiceTitle }}
    </OLabel>

    <OLoader v-if="loading" />

    <div
      class="vast-service-service-select__scroll-wrapper"
      v-resize="updateResize"
    >
      <div
        v-if="selectedNetwork
          && selectedNetwork.serviceTypes
          && selectedNetwork.serviceTypes.length
          && sortedServiceTypes.length > 0"
        class="vast-service-service-select__scroll"
        ref="serviceSelectRow"
      >
        <div
          class="vast-service-service-select__first"
        >
          <div
            v-for="(serviceType, index) in firstServiceTypes"
            :key="index"
            class="vast-service-service-select__container"
          >
            <ServiceType
              v-if="serviceType.id"
              :service-type="serviceType"
              :class="{ 'last-card-in-row': ((index + 1) % countRow) === 0 }"
            />

            <div
              v-else
              :class="[
                'vast-service-service-select__empty-card',
                { 'last-card-in-row': ((index + 1) % countRow) === 0 }
              ]"
            />
          </div>
        </div>

        <div
          :class="[
            'vast-service__animated-element',
            { show: isAllServiceTypeShown }
          ]"
          :style="elementStyle"
        >
          <div
            v-show="isAllServiceTypeShown"
            class="vast-service-service-select__second vast-service__inner-content"
            ref="content"
          >
            <div
              v-for="(serviceType, index) in secondServiceTypes"
              :key="index"
              class="vast-service-service-select__container"
            >
              <ServiceType
                v-if="serviceType.id"
                :service-type="serviceType"
                :class="{
                  'last-card-in-row': ((index + 1) % countRow) === 0
                }"
              />

              <div
                v-else
                :class="[
                  'vast-service-service-select__empty-card',
                  { 'last-card-in-row': ((index + 1) % countRow) === 0 }
                ]"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-else-if="!loading"
        class="vast-service-service-select__empty"
      >
        {{ locale.noServices }}
      </div>

      <ShowMoreLine
        v-if="isShownMore"
        :is-show-more="isAllServiceTypeShown"
        @click="showMore"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ShowMoreLine from '@/components/ShowMoreLine.vue';
import useStoreBinding from '@/mixins/use-store-binding';
import ServiceType from './ServiceType.vue';
import ServiceSettingsModal from '../ServiceSettingsModal/Index.vue';

export default {
  components: {
    ShowMoreLine,
    ServiceType,
    ServiceSettingsModal,
  },
  mixins: [
    useStoreBinding('selectedServiceTypesIds', 'selectedServiceTypesIds', 'SET_SELECTED_SERVICE_TYPES_IDS'),
  ],
  inject: [
    'widgetJSApiInstance',
  ],
  data: () => ({
    isAllServiceTypeShown: false,
    countServicesTypeInFirst: 6,
    countRow: 6,
    isMobile: false,
    sortedServiceTypes: [],
    elementStyle: {
      maxHeight: '0',
      overflow: 'hidden',
      transition: 'max-height 0.5s ease-in-out',
    },
  }),
  created() {
    this.widgetJSApiInstance.addActionCallback('getSelectedServiceTypesIds', () => this.selectedServiceTypesIds);
    this.widgetJSApiInstance.addActionCallback('setServiceTypeSettings', ({
      serviceTypeId,
      settings,
    }) => {
      const foundServiceType = this.networks.reduce((acc, network) => {
        if (acc) {
          return acc;
        }

        return network.serviceTypes.find((serviceType) => serviceType.id === serviceTypeId);
      }, null);

      if (!foundServiceType) {
        return;
      }

      foundServiceType.settings = {
        ...foundServiceType.settings,
        ...settings,
      };
    });

    this.widgetJSApiInstance.addActionCallback('selectServiceTypes', (selectingServiceTypesIds) => {
      const availableSelectingServiceTypesIds = selectingServiceTypesIds.filter(
        (serviceTypeId) => {
          const foundServiceType = this.sortedServiceTypes.find(
            (serviceType) => serviceType.id === serviceTypeId,
          );

          if (!foundServiceType) {
            return false;
          }

          return foundServiceType.services && foundServiceType.services.length;
        },
      );

      if (!availableSelectingServiceTypesIds.length) {
        return;
      }

      this.$store.commit('SET_SELECTED_SERVICE_TYPES_IDS', availableSelectingServiceTypesIds);
    });

    this.sortedServiceTypes = this.serviceTypesFilteredWithConfig.slice();
    this.widgetJSApiInstance.addActionCallback('showServiceTypes', () => {
      this.isAllServiceTypeShown = true;
    });
    this.widgetJSApiInstance.addActionCallback('hideServiceTypes', () => {
      this.isAllServiceTypeShown = false;
    });
  },
  watch: {
    networkIsLoaded() {
      setTimeout(() => {
        this.setDefaultServiceTypes();
      });
    },
    selectedNetwork() {
      this.sortedServiceTypes = this.serviceTypesFilteredWithConfig.slice();
      if (this.availableServicesTypesIdsForUrl?.length > 0) {
        this.selectedServiceTypesIds = this.selectedServiceTypesIds.filter(
          (serviceValue) => this.availableServicesTypesIdsForUrl.includes(serviceValue),
        );
      }
      setTimeout(() => {
        this.sortingServiceTypesBySelected();
        this.sortingServiceTypesByUrl();
        this.updateResize();
      });
    },
    availableServicesTypesIdsForUrl() {
      if (this.availableServicesTypesIdsForUrl.length === 0) {
        return;
      }

      this.sortedServiceTypes = this.serviceTypesFilteredWithConfig.slice();
      this.selectedServiceTypesIds = this.selectedServiceTypesIds.filter(
        (serviceValue) => this.availableServicesTypesIdsForUrl.includes(serviceValue),
      );
      setTimeout(() => {
        this.sortingServiceTypesBySelected();
        this.sortingServiceTypesByUrl();
      });
    },
  },
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
    ...mapState('config', [
      'availableServiceTypes',
      'networksAreShown',
    ]),
    ...mapState('networks', [
      'networks',
      'loading',
      'networkIsLoaded',
    ]),
    ...mapGetters([
      'selectedNetwork',
    ]),
    ...mapState([
      'networkIsChangedFromLink',
    ]),
    ...mapState('url', [
      'availableServicesTypesIdsForUrl',
    ]),
    isShownMore() {
      return this.countServicesTypeInFirst < this.sortedServiceTypes.length;
    },
    serviceTypesFilteredWithConfig() {
      if (!this.availableServiceTypes || this.availableServiceTypes.length === 0) {
        return this.selectedNetwork?.serviceTypes || [];
      }
      return this.selectedNetwork.serviceTypes.filter(
        (serviceType) => this.availableServiceTypes.includes(serviceType.id),
      );
    },
    firstServiceTypes() {
      const services = this.sortedServiceTypes.slice(0, this.countServicesTypeInFirst);
      const countEmptyServices = this.sortedServiceTypes.length > this.countServicesTypeInFirst
        ? 0
        : this.countServicesTypeInFirst - services.length;

      return [...services, ...new Array(countEmptyServices).fill({})];
    },
    secondServiceTypes() {
      const countServicesType = this.sortedServiceTypes.length - this.countServicesTypeInFirst;
      if (countServicesType <= 0) {
        return [];
      }
      const services = this.sortedServiceTypes.slice(countServicesType * -1);
      const countEmptyServices = services.length > this.countServicesTypeInFirst
        ? this.countServicesTypeInFirst - (services.length % this.countServicesTypeInFirst)
        : this.countServicesTypeInFirst - services.length;

      return [...services, ...new Array(countEmptyServices).fill({})];
    },
    countSelectedServicesType() {
      return this.$store.getters.selectedServiceTypes?.length;
    },
    selectServiceTitle() {
      return this.networksAreShown
        ? this.locale.selectService : this.locale.selectServiceWithoutNetworks;
    },
  },
  methods: {
    setDefaultServiceTypes() {
      this.selectedServiceTypesIds = this.sortedServiceTypes
        .reduce((acc, serviceType) => {
          if (serviceType.isDefault) {
            acc.push(serviceType.id);
          }

          return acc;
        }, []);
    },
    sortingServiceTypesByUrl() {
      this.sortedServiceTypes = this.sortedServiceTypes.slice()
        .sort((a, b) => {
          const aIsSelected = this.availableServicesTypesIdsForUrl.includes(a.id);
          const bIsSelected = this.availableServicesTypesIdsForUrl.includes(b.id);

          if (aIsSelected === bIsSelected) return 0;

          return aIsSelected ? -1 : 1;
        })
        .filter((serviceType) => serviceType.services && serviceType.services.length);
    },
    sortingServiceTypesBySelected() {
      this.sortedServiceTypes = this.sortedServiceTypes.slice()
        .sort((a, b) => {
          const aIsSelected = this.$store.state.selectedServiceTypesIds.includes(a.id);
          const bIsSelected = this.$store.state.selectedServiceTypesIds.includes(b.id);

          if (aIsSelected === bIsSelected) return 0;

          return aIsSelected ? -1 : 1;
        });
    },
    checkScroll() {
      const element = this.$refs.serviceSelectRow;
      const rect = element.getBoundingClientRect();
      if (rect.top < 0) {
        window.scrollTo({ top: window.scrollY + (rect.top - 120), behavior: 'smooth' });
      }
    },
    showMore() {
      if (!this.isAllServiceTypeShown) {
        this.isAllServiceTypeShown = true;
        this.$nextTick(() => {
          this.elementStyle.maxHeight = `${this.$refs.content.scrollHeight}px`;
        });
      } else {
        this.elementStyle.maxHeight = '0';
        this.checkScroll();

        setTimeout(() => {
          this.sortingServiceTypesBySelected();
          this.sortingServiceTypesByUrl();
        });
        setTimeout(() => {
          this.isAllServiceTypeShown = false;
        }, 500);
      }
    },
    updateResize() {
      const containerWidth = this.$refs.serviceSelectRow.getBoundingClientRect().width;
      const widthCard = this.$refs
        .serviceSelectRow.children[0].children[0].children[0].getBoundingClientRect().width;
      const marginCard = parseInt(
        getComputedStyle(this.$refs.serviceSelectRow.children[0].children[0].children[0])
          .marginRight,
        10,
      );
      const countRow = parseInt(
        (containerWidth + marginCard) / (widthCard + marginCard),
        10,
      );

      if (countRow < 2) return;

      this.countRow = countRow;
      if (countRow >= this.countSelectedServicesType) {
        this.countServicesTypeInFirst = countRow;
      } else {
        this.countServicesTypeInFirst = Math
          .ceil(this.countSelectedServicesType / countRow) * countRow;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vast-service-service-select {
  margin-top: 30px;

  &__label {
    margin-bottom: 20px !important;
  }

  &__empty {
    font-size: 15px;
    color: rgba(#000, .5);
  }

  &__scroll-wrapper {
    position: relative;
  }

  &__scroll-controls-icon {
    transform: translateY(2px);
  }

  &__scroll-controls-button {
    position: absolute;
    background: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: inherit;
    top: 50px;
    z-index: 6;
    border: 0;
    cursor: pointer;
    outline: none;
    background: rgba(#000, .1);

    &:hover {
      background: rgba(#000, .1);
    }

    &--left {
      left: -15px;
      transform: rotate(90deg);
    }

    &--right {
      right: -15px;
      transform: rotate(-90deg);
    }
  }


  &__scroll {
    display: flex;
    flex-direction: column;
  }

  &__container {
    display: flex;
  }

  &__first, &__second {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    margin: 0 -20px;
    padding: 0 20px 10px;
    & > * {
      flex-shrink: 0;
    }
  }
  &__empty-card {
    width: 180px;
    margin-right: 24px;
    margin-bottom: 24px;
  }
}

.vast-service__inner-content {
  opacity: 1;
  transition: opacity 0.5s;
}

.vast-service__animated-element.show .vast-service__inner-content {
  opacity: 1;
}

::v-deep .last-card-in-row {
  margin-right: 0px!important;
}

@media (max-width: 1200px) {
  .vast-service-service-select__empty-card {
    width: 168px;
  }
}

@media (max-width: 1024px) {
  .vast-service-service-select__empty-card {
    width: 160px;
    margin-right: 16px;
  }
}

@media (max-width: 768px) {
  .vast-service-service-select__empty-card {
    width: 148px;
  }
}
</style>
