<template>
  <div class="vast-service-order-now">

    <ManualPaymentModal />
    <QRCodePaymentModal />
    <ModalEmptyServices />

    <div :class="{
      'vast-service-order-now__block--border': theme.shadow === 'off',
      'vast-service-order-now__block': theme.shadow !== 'off'
    }">
      <p
        v-if="title"
        class="vast-service-order-now__title"
      >
        {{ title }}
      </p>

      <NetworkSelect />
      <UrlInput />
      <ServiceTypesSelect />

      <div class="vast-service-order-now__checkout-row">
        <div class="vast-service-order-now__checkout-row-summary">
          <SummaryAndCommission />
        </div>

        <div class="vast-service-order-now__checkout-row-payment">
          <PaymentMethodSelect />
        </div>

        <div class="vast-service-order-now__checkout-row-email">
          <EmailInput />
        </div>

        <div class="vast-service-order-now__bonus-row">
          <BonusAndCoupon />
        </div>

        <div class="vast-service-order-now__create-order">
          <CreateOrderBtn />


          <div class="vast-service-order-now__create-order-info">
            {{ locale.createOrderButtonInfo }}
            {{ infoCreateOrder }}
          </div>
        </div>
      </div>
    </div>

    <div v-html="dinamicGlobalStyles"></div>

  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import BonusAndCoupon from '@/components/BonusAndCoupon.vue';
import SummaryAndCommission from '@/components/SummaryAndCommission.vue';
import EmailInput from './components/EmailInput/Index.vue';
import NetworkSelect from './components/NetworkSelect/Index.vue';
import UrlInput from './components/UrlInput.vue';
import ServiceTypesSelect from './components/ServiceTypesSelect/Index.vue';
import PaymentMethodSelect from './components/PaymentMethodSelect/Index.vue';
import CreateOrderBtn from './components/CreateOrderBtn.vue';
import ManualPaymentModal from './components/ManualPaymentModal.vue';
import QRCodePaymentModal from './components/QRCodePaymentModal.vue';
import ModalEmptyServices from './components/ModalEmptyServices/Index.vue';

export default {
  components: {
    BonusAndCoupon,
    EmailInput,
    NetworkSelect,
    UrlInput,
    ServiceTypesSelect,
    PaymentMethodSelect,
    CreateOrderBtn,
    ManualPaymentModal,
    QRCodePaymentModal,
    SummaryAndCommission,
    ModalEmptyServices,
  },
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
    ...mapState('config', [
      'theme',
      'title',
      'currency',
      'currencySymbol',
    ]),
    ...mapState('settings', [
      'currencies',
      'minimumPurchaseAmount',
    ]),
    infoCreateOrder() {
      const minimumPurchaseAmount = this.currencies?.default !== 'USD' && this.currencies?.exchangeRates?.[this.currency]
        ? parseFloat(this.minimumPurchaseAmount * this.currencies.exchangeRates[this.currency])
          .toFixed(2)
        : this.minimumPurchaseAmount;
      const currencySymbol = minimumPurchaseAmount !== this.minimumPurchaseAmount
        ? this.currencySymbol[this.currency]
        : this.currencySymbol.USD;

      return `${minimumPurchaseAmount} ${currencySymbol}`;
    },
    dinamicGlobalStyles() {
      return `
      <style>
        :root {
          --main-background: ${this.theme.mainBackground};
          --title-color: ${this.theme.titleColor};
          --text-color: ${this.theme.textColor};
          --input-background-color: ${this.theme.inputBackgroundColor};
          --input-text-color: ${this.theme.inputTextColor};
          --selected-elements-color: ${this.theme.selectedElementsColor};
          --errors-color: ${this.theme.errorsColor};
          --modal-background: ${this.theme.modalBackground};
          --modal-text-color: ${this.theme.modalTextColor};
          --loader-color: ${this.theme.loaderColor};
          --network-color: ${this.theme.networkColor};
          --network-text-color: ${this.theme.networkTextColor};
          --network-selected-color: ${this.theme.networkSelectedColor};
          --network-selected-text-color: ${this.theme.networkSelectedTextColor};
          --icon-color: ${this.theme.iconColor};
          --primary-button-color: ${this.theme.primaryButtonColor};
          --primary-button-text-color: ${this.theme.primaryButtonTextColor};
          --primary-button-disabled-color: ${this.theme.primaryButtonDisabledColor};
          --primary-button-disabled-text-color: ${this.theme.primaryButtonDisabledTextColor};
          --secondary-button-color: ${this.theme.secondaryButtonColor};
          --secondary-button-disabled-color: ${this.theme.secondaryButtonDisabledColor};
        }
      </style>
      `;
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

.vast-service-order-now {
  font-family: 'Montserrat', sans-serif;
  padding: 40px 0;

  * {
    box-sizing: border-box;
  }

  &__title {
    margin-top: 0px;
    margin-bottom: 60px;
    color: var(--title-color);
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }

  &__block {
    padding: 44px;
    width: 100%;
    max-width: 1290px;
    margin: 0 auto;
    position: relative;
    background: var(--main-background);
    box-shadow: 0 12px 35px rgba(#000, .36);

    &--border {
      background: var(--main-background);
      padding: 44px;
      width: 100%;
      max-width: 1290px;
      margin: 0 auto;
      position: relative;
      border: 1px solid black;
    }
  }

  &__checkout-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    column-gap: 26px;
    row-gap: 7px;
    margin-top: 50px;
  }

  &__checkout-row-summary {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  &__checkout-row-payment {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  &__checkout-row-email {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  &__bonus-row {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  &__create-order {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
    display: flex;
    flex-direction: column;
  }

  &__checkout-row-summary,
  &__checkout-row-payment,
  &__checkout-row-email,
  &__bonus-row,
  &__create-order {
   display: flex;
  }

  &__create-order-info {
    margin-top: 6px;
    color: var(--text-color);
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
  }

  &__bonus-row {
    display: flex;

    @media (max-width: 800px) {
      flex-direction: column-reverse;
    }
  }
}

@media (max-width: 1024px) {
  .vast-service-order-now {
    &__block--border, &__block {
      padding: 38px;
    }
    &__checkout-row {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto;
      column-gap: 16px;
      row-gap: 13px;
      margin-top: 43px;
    }
    &__checkout-row-summary {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    &__checkout-row-payment {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    &__checkout-row-email {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    &__bonus-row {
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    &__create-order {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 3;
      grid-row-end: 4;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (max-width: 768px) {
  .vast-service-order-now {
    &__block--border, &__block {
      padding: 16px;
    }
    &__checkout-row {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto auto auto;
      row-gap: 12px;
      margin-top: 25px;
    }
    &__checkout-row-summary {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    &__checkout-row-payment {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    &__checkout-row-email {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 4;
      grid-row-end: 5;
    }

    &__bonus-row {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 3;
      grid-row-end: 4;
    }

    &__create-order {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 5;
      grid-row-end: 6;
      justify-content: center;
    }
  }
}
</style>
