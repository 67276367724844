export default function (items, key) {
  return items.reduce((acc, item) => {
    if (key) {
      acc[item[key]] = item;
    } else {
      acc[item] = true;
    }

    return acc;
  }, {});
}
