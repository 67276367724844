export default function (propName, storePropPath, mutationName) {
  return {
    computed: {
      [propName]: {
        get() {
          if (storePropPath.match(/\./)) {
            try {
              return storePropPath
                .split('.')
                .reduce(
                  (acc, pathItem) => acc[pathItem],
                  this.$store.state,
                );
            } catch {
              // eslint-disable-next-line
              console.error('Error in store binding', storePropPath);
            }
          }

          return this.$store.state[storePropPath];
        },
        set(val) {
          this.$store.commit(mutationName, val);
        },
      },
    },
  };
}
