<template>
  <Modal v-model="isShown">
    <ModalSaveSettings
      v-show="isSettingsModalShown"
      @save="close"
      @dontSave="closeWhithoutSave"
    />

    <ServiceTypeSettings
      v-if="!mode"
      :service-type.sync="serviceType"
      @close="close"
      @checkClose="checkCloseSettings"
    />

    <CountriesSelect
      v-if="mode === 'countriesSelect'"
      v-model="selectedTarget"
      :countries="countries"
      :target="target"
      @save="close"
      @close="checkCloseTarget"
    />

    <LanguagesSelect
      v-if="mode === 'languagesSelect'"
      v-model="selectedTarget"
      :languages="languages"
      :target="target"
      @save="close"
      @close="checkCloseTarget"
    />

    <ServiceSelect
      v-if="mode === 'serviceSelect'"
      v-model="serviceType.settings.selectedServiceId"
      :service-type.sync="serviceType"
      @save="close"
      @close="checkCloseService"
    />
  </Modal>
</template>

<script>
import { mapState } from 'vuex';
import useStoreBinding from '@/mixins/use-store-binding';
import areArraysEqual from '@/utils/areArraysEqual';
import ServiceTypeSettings from './ServiceTypeSettings.vue';
import CountriesSelect from './CountriesSelect.vue';
import LanguagesSelect from './LanguagesSelect.vue';
import ServiceSelect from './ServiceSelect.vue';
import ModalSaveSettings from './ModalSaveSettings.vue';

export default {
  components: {
    CountriesSelect,
    LanguagesSelect,
    ServiceTypeSettings,
    ServiceSelect,
    ModalSaveSettings,
  },
  mixins: [
    useStoreBinding('isShown', 'serviceSettingsModal.isShown', 'serviceSettingsModal/SET_IS_SHOWN'),
    useStoreBinding('mode', 'serviceSettingsModal.mode', 'serviceSettingsModal/SET_MODE'),
    useStoreBinding('serviceType', 'serviceSettingsModal.serviceType', 'serviceSettingsModal/SET_SERVICE_TYPE'),
  ],
  data() {
    return {
      isSettingsModalShown: false,
      isCheckSettings: false,
      isCheckTarget: false,
      isCheckService: false,
    };
  },
  watch: {
    selectedService(val) {
      this.serviceType.settings.targetableValues = val.targets.map((target) => ({
        targetId: target.id,
        viewType: target.type.viewType,
        values: [],
        quantity: 0,
      }));
    },
  },
  computed: {
    ...mapState([
      'languages',
      'countries',
    ]),
    ...mapState('serviceSettingsModal', [
      'selectedTargetId',
      'previousConfigurationService',
      'previousSelectedTarget',
    ]),
    selectedService() {
      return this.serviceType.services?.find(
        (service) => service.id === this.serviceType.settings.selectedServiceId,
      );
    },
    target() {
      return this.selectedService.targets.find((target) => target.id === this.selectedTargetId);
    },
    selectedTarget: {
      get() {
        return this.serviceType.settings.targetableValues[this.targetableValueIndex].values;
      },
      set(val) {
        this.serviceType.settings.targetableValues[this.targetableValueIndex].values = val;
      },
    },
    targetableValueIndex() {
      return this.serviceType.settings.targetableValues.findIndex((target) => (
        target.targetId === this.selectedTargetId
      ));
    },
  },
  methods: {
    closeSettings() {
      this.isShown = false;
      this.isCheckSettings = false;
      this.closeSevaSettingsModal();
    },
    close() {
      if (!this.mode) {
        this.closeSettings();

        return;
      }

      this.clearMode();
    },
    clearMode() {
      this.mode = { mode: null };
      this.isCheckTarget = false;
      this.isCheckService = false;
      this.closeSevaSettingsModal();
    },
    openSevaSettingsModal() {
      this.isSettingsModalShown = true;
    },
    closeSevaSettingsModal() {
      this.isSettingsModalShown = false;
    },
    checkCloseTarget() {
      if (
        !areArraysEqual(this.selectedTarget, this.previousSelectedTarget.map((item) => item.id))
      ) {
        this.isCheckTarget = true;
        this.openSevaSettingsModal();

        return;
      }

      this.clearMode();
    },
    checkCloseService() {
      if (this.serviceType.settings.selectedServiceId
        !== this.previousConfigurationService.selectedServiceId) {
        this.isCheckService = true;
        this.openSevaSettingsModal();

        return;
      }

      this.clearMode();
    },
    checkCloseSettings() {
      this.isCheckSettings = true;
      if (this.serviceType.settings.quantity !== this.previousConfigurationService.quantity) {
        this.openSevaSettingsModal();

        return;
      }

      this.close();
    },
    closeWhithoutSave() {
      if (this.isCheckTarget) {
        this.selectedTarget = this.previousSelectedTarget.map((item) => item.id);
        this.close();

        return;
      }

      if (this.isCheckService) {
        this.serviceType.settings.selectedServiceId = this
          .previousConfigurationService.selectedServiceId;
        this.close();

        return;
      }

      this.serviceType.settings = { ...this.previousConfigurationService };
      this.close();
    },
  },
};
</script>
