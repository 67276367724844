<template>
  <div
    v-if="isShown"
    class="vast-service-order-now-overlay"
  >
    <div
      :class="[
        'vast-service-order-now-modal',
        {
          'vast-service-order-now-modal--large': large
        }
      ]"
      :style="modalStyle"
    >
      <slot />
    </div>
  </div>
</template>

<script>

const WIDTH_MODAL = {
  large: '530px',
  default: '464px',
};

export default {
  props: {
    value: Boolean,
    large: Boolean,
    width: {
      type: String,
      default: WIDTH_MODAL.default,
    },
    height: {
      type: String,
      default: '100%',
    },
  },
  watch: {
    isShown(val) {
      if (val) {
        document.addEventListener('keydown', this.keyDownHandler);
      } else {
        document.removeEventListener('keydown', this.keyDownHandler);
      }
    },
  },
  computed: {
    isShown: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    modalStyle() {
      let width = this.width !== WIDTH_MODAL.default
        ? this.width : WIDTH_MODAL.default;

      if (this.large) {
        width = this.width !== WIDTH_MODAL.large
          ? this.width : WIDTH_MODAL.large;
      }

      return {
        maxWidth: width,
        height: this.height,
      };
    },
  },
  methods: {
    keyDownHandler(event) {
      if (event.key === 'Escape') {
        this.isShown = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vast-service-order-now-overlay {
  position: fixed;
  background: rgba(#000, .1);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 10px;
  z-index: 9999999;
  display: flex;
  justify-content: center;

  @media (max-width: 720px) {
    padding: 0;
  }
}

.vast-service-order-now-modal {
  position: relative;
  align-self: flex-start;
  width: 100%;
  max-height: 700px;
  margin: auto;
  background: var(--modal-background);
  color: var(--modal-text-color);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  border-radius: 15px;

  @media (max-width: 720px) {
    max-width: unset;
    max-height: unset;
  }
}
</style>
