<template>
  <field v-bind="$props">
    <input
      v-bind="$props"
      v-model="modeledValue"
      :disabled="disabled"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      :class="[
        'inherited-field-styles',
        'o-input',
        {
          'o-input--large': large,
          'o-input--disabled': disabled,
          'o-input--error': hasError,
        }
      ]"
      :style="{
        color: theme.mainTextColFor,
        height: height,
        textAlign: textAlign
      }"
      :maxlength="maxLength"
      @change="$emit('change', $event)"
      @keydown="$emit('keydown', $event)"
    />
  </field>
</template>

<script>
import { mapState } from 'vuex';
import useInputOptions from '@/mixins/use-input-options';
import useVModel from '@/mixins/use-vmodel';
import Field from './Field.vue';

export default {
  components: {
    Field,
  },
  mixins: [
    useInputOptions,
    useVModel(),
  ],
  computed: {
    ...mapState('config', [
      'theme',
    ]),
    hasError() {
      return this.errors[0];
    },
  },
  props: {
    disabled: Boolean,
    large: Boolean,
    success: Boolean,
    height: {
      type: [String, Number],
      default: '46px',
    },
    textAlign: {
      type: String,
      default: 'left',
    },
    pattern: {
      type: String,
      default: '',
    },
    maxLength: {
      type: [String, Number],
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    min: {
      type: [String, Number],
      default: '',
    },
    max: {
      type: [String, Number],
      default: '',
    },
    step: {
      type: Number,
      default: 1,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.o-input {
  transition: all 0.25s;
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &::placeholder {
    color: var(--input-text-color);
  }
  &--large {
    height: 45px;
    font-size: 15px;
  }
  &:hover {
    box-shadow: 6px 6px 10px 0px rgba(0, 0, 0, 0.10) inset;
  }
  &:focus {
    outline: none;
    border: 1px solid var(--input-text-color)!important;
    box-shadow: none;
  }
  &--disabled {
    background-color: #EEEEEE!important;
    &:hover {
      box-shadow: none;
    }
  }
  &--error {
    border: 1px solid var(--errors-color)!important;
    &:hover {
      box-shadow: none;
    }
    &:focus {
      border: 1px solid var(--errors-color)!important;
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
