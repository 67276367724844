<template>
  <field v-bind="$props">
    <select
      v-bind="$props"
      v-model="modeledValue"
      class="inherited-field-styles"
    >
      <option
        v-if="placeholder"
        disabled
        value=""
      >
        {{ placeholder }}
      </option>

      <option
        v-for="(option, i) in options"
        :key="i"
        :value="option"
      >
        {{ option[itemLabel] || option }}
      </option>
    </select>
  </field>
</template>

<script>
import useInputOptions from '@/mixins/use-input-options';
import useVModel from '@/mixins/use-vmodel';

export default {
  mixins: [
    useInputOptions,
    useVModel(),
  ],
  props: {
    value: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    itemLabel: {
      type: String,
      default: '',
    },
  },
};
</script>
