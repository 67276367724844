<template>
  <div class="summary">
    <div class="summary__row">
      <div
        class="summary__amount"
      >
        {{ summary }}
        <OLoader v-if="loading" />
      </div>
    </div>

    <OErrors :errors="errors.balance" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import debounce from 'debounce';
import removeSpace from '@/utils/removeSpace';

export default {
  inject: [
    'api',
  ],
  filters: {
    removeSpace,
  },
  created() {
    this.debouncedCalculateCost = debounce(this.calculateTotalCost, 500);
    this.debouncedCalculateAllCost = debounce(this.getCostAllServices, 500);

    this.$watch(
      () => this.selectedServiceTypes,
      () => {
        this.debouncedCalculateCost();
      },
      {
        deep: true,
      },
    );
    this.$watch(
      () => this.allServiceTypes,
      () => {
        this.debouncedCalculateAllCost();
      },
      {
        deep: true,
      },
    );
  },
  computed: {
    ...mapState([
      'errors',
    ]),
    ...mapGetters([
      'selectedServiceTypes',
      'allServiceTypes',
    ]),
    ...mapGetters('config', [
      'locale',
    ]),
    ...mapState('costs', [
      'loading',
      'summary',
    ]),
    ...mapGetters([
      'isNetworkSelected',
    ]),
  },
  methods: {
    calculateTotalCost() {
      this.$store.dispatch('costs/calculateTotalCost');
    },
    getCostAllServices() {
      this.$store.dispatch('costs/getCostAllServices');
    },
  },
};
</script>

<style lang="scss" scoped>
.summary {
  &__row {
    display: flex;
    align-items: center;
  }

  &__amount {
    max-width: 240px;
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    color: var(--input-text-color);
    text-overflow: ellipsis;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }
}
</style>
