<template>
  <div>
    <br>
    <div class="block-title">
      {{ translations.name }}
    </div>
    <div class="languages-select">
      <div class="languages-select__row">
        <div
          v-if="disabled"
          class="languages-select__empty"
        >
          {{ disabled ? locale.geoTargetingDisabled : locale.noLanguagesSelected }}
        </div>

        <template v-else>
          <div
            v-if="selectedLanguages.length === 0"
            class="languages-select__empty"
          >
            {{ locale.noLanguagesSelected }}
          </div>

          <div
            v-else
            class="languages-select__selected-list"
          >
            <OChip
              v-for="language in selectedLanguages"
              :key="language.id"
              :label="language.name"
              @close="unselectLanguage(language)"
            />
          </div>

          <div class="languages-select__button">
            <OBtn
              :disabled="disabled"
              text
              height="35"
              @click="openSelectLanguages"
            >
            {{ selectedLanguages.length ? locale.manageLanguages : locale.selectLanguages }}
            </OBtn>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    disabled: Boolean,
    value: Array,
    target: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
    ...mapState([
      'languages',
    ]),
    ...mapState('config', [
      'language',
    ]),
    selectedLanguages: {
      get() {
        if (this.value.length > 0) {
          this.clearErrors();
        }
        return this.value.map((id) => this.languages.find((language) => language.id === id));
      },
      set(val) {
        this.$emit('input', val.map((language) => language.id));
      },
    },
    translations() {
      return this.target.translations[this.language] || this.target.translations.en;
    },
  },
  methods: {
    clearErrors() {
      this.$emit('clear-errors');
    },
    unselectLanguage(selectedLanguage) {
      this.selectedLanguages = this.selectedLanguages.filter(
        (language) => language.id !== selectedLanguage.id,
      );
    },
    openSelectLanguages() {
      this.$store.commit('serviceSettingsModal/SET_MODE', {
        mode: 'languagesSelect',
        id: this.target.id,
        selectables: this.target.selectables,
      });
      this.$store.commit('serviceSettingsModal/SET_PREVIOUS_SELECTED_TARGET', this.selectedLanguages);
    },
  },
};
</script>

<style lang="scss" scoped>
.languages-select {
  margin-bottom: 20px;

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__empty {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  &__button {
    min-width: 155px;
    border-radius: 10px;
    & > * {
      width: 100%;
      text-align: center;
      border-radius: 8px !important;
    }
  }
}
.block-title {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}
</style>
