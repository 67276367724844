<template>
  <div>
    <br>
    <div class="block-title">
      {{ translations.name }}
    </div>
    <o-textarea
      v-model="commentModel"
      :placeholder="translations.placeholder"
      :errors="errors[`${serviceType.id}.targetable_values`]
      || errors[`${serviceType.id}.targetable_values.${targetableValueIndex}.values`]"
      class="comment-textarea"
      @focus="clearErrors"
    />
    <p
      v-show="isCalculateQuantity"
      class="comment-textarea__info"
    >
      {{ locale.targetInfoCalculateQuantity }}
    </p>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import textToMapArray from '@/utils/textToMapArray';
import ServiceType from '@/classes/ServiceType';

export default {
  props: {
    target: {
      type: Object,
      required: true,
    },
    serviceType: ServiceType,
  },
  computed: {
    ...mapState([
      'errors',
    ]),
    ...mapState('config', [
      'language',
    ]),
    ...mapGetters('config', [
      'locale',
    ]),
    selectedService() {
      return this.serviceType.services.find(
        (service) => service.id === this.serviceType.settings.selectedServiceId,
      );
    },
    commentModel: {
      get() {
        return this.targetableValues.join(' ');
      },
      set(val) {
        this.targetableValues = val.length ? [val.trim()] : [];
        this.setQuantity();
      },
    },
    targetableValueIndex() {
      return this.serviceType.settings.targetableValues.findIndex((target) => (
        target.targetId === this.target.id
      ));
    },
    targetableValues: {
      get() {
        return this.serviceType.settings.targetableValues[this.targetableValueIndex].values;
      },
      set(val) {
        this.serviceType.settings.targetableValues[this.targetableValueIndex].values = val;
        if (this.viewType !== 'textarea') {
          this.setQuantity();
        }
      },
    },
    viewType() {
      return this.target.type.viewType;
    },
    translations() {
      return this.target.translations[this.language] || this.target.translations.en;
    },
    isCalculateQuantity() {
      return this.selectedService.calculableQuantity;
    },
  },
  created() {
    this.setQuantity();
  },
  methods: {
    clearErrors() {
      this.$emit('clear-errors');
    },
    setQuantity() {
      const targetable = this.serviceType.settings.targetableValues[this.targetableValueIndex];
      if (this.isCalculateQuantity) {
        targetable.quantity = textToMapArray({
          text: targetable.values,
          viewType: targetable.viewType,
        }).length;
        this.serviceType.settings.quantity = this.serviceType.settings.targetableValues
          .reduce((currentQuantity, target) => (
            currentQuantity + target.quantity
          ), 0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-textarea {
  ::v-deep {
    .o-textarea {
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  &__info {
    margin-top: 0px;
    margin-bottom: 0px;
    color: var(--modal-text-color);
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
  }
}
.block-title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}
</style>
