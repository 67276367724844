export default (api) => ({
  namespaced: true,
  state: {
    analytics: null,
    minimumPurchaseAmount: null,
    currencies: {},
  },
  mutations: {
    SET_ANALYTICS: (state, val) => { state.analytics = val; },
    SET_MINIMUM_PURCHASE_AMOUNT: (state, val) => { state.minimumPurchaseAmount = val; },
    SET_CURRENCIES: (state, val) => { state.currencies = val; },
  },
  actions: {
    fetchSettings({ commit }) {
      api.fetchSettings()
        .then((settings) => {
          commit('SET_ANALYTICS', settings.analytics);
          commit('SET_MINIMUM_PURCHASE_AMOUNT', settings.minimumPurchaseAmount);
          commit('SET_CURRENCIES', settings.currencies);
        });
    },
  },
});
