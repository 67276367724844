<template>
  <button
    v-bind="$props"
    :class="[
      'vast-service-order-now-btn',
      {
        'vast-service-order-now-btn--text': text,
      }
    ]"
    v-on="$listeners"
    :disabled="disabled || !!loading"
    :style="styleButton"
  >
    <div
      v-if="!loading"
      class="vast-service-order-now-btn__container"
    >
      <slot />
    </div>

    <template v-else>
      <div class="btn__loader">
        <OLoader />
      </div>
      {{ typeof loading === 'string' ? loading : locale.loadingText }}
    </template>
  </button>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  props: {
    disabled: Boolean,
    text: Boolean,
    height: {
      type: String,
      default: '40',
    },
    loading: {
      type: [String, Boolean],
      default: '',
    },
    href: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    ...mapState('config', [
      'theme',
    ]),
    ...mapGetters('config', [
      'locale',
    ]),
    computedHeaght() {
      return `${this.height.replace('px', '')}px`;
    },
    styleButton() {
      return {
        height: this.computedHeaght,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.vast-service-order-now-btn {
  // height: 40px;
  // padding-left: 50px;
  // padding-right: 50px;
  display: inline-flex;
  border-radius: 2px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  align-items: center;
  transition: all 0.25s;
  outline: none;
  border: 0;
  border: 1px solid;
  white-space: nowrap;
  background-color: var(--primary-button-color);
  color: var(--primary-button-text-color);

  &__container {
    width: 100%;
    text-align: center;
  }

  &--dense {
    height: 25px;
    padding-left: 18px;
    padding-right: 18px;
  }

  &--text {
    background: transparent !important;
    border-radius: 0;
    border: 1px solid var(--secondary-button-color);
    color: var(--secondary-button-color);
  }

  &:disabled {
    background-color: var(--primary-button-disabled-color);
    color: var(--primary-button-disabled-text-color);
    box-shadow: none;
    cursor: default;
    &.vast-service-order-now-btn--text {
      border: 1px solid var(--secondary-button-disabled-color);
      color: var(--secondary-button-disabled-color);
    }
  }

  &__loader {
    margin-right: 10px;
  }
  &:hover {
    box-shadow: 6px 6px 30px 0px rgba(0, 0, 0, 0.20);
  }
  &:active {
    box-shadow: 6px 6px 30px 0px rgba(0, 0, 0, 0.20) inset;
  }
}
</style>
