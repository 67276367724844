export default function (
  name = 'modeledValue',
  propName = 'value',
  updateEventName = 'input',
) {
  return {
    computed: {
      [name]: {
        get() {
          return this[propName];
        },
        set(value) {
          this.$emit(updateEventName, value);
        },
      },
    },
  };
}
