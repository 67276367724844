import ValidationError from './ValidationError';

export default function (dictionary = {}) {
  return (error) => {
    if (error.response && error.response.data && error.response.data.errors) {
      const serverErrors = error.response.data.errors;

      const localErrors = Object.entries(dictionary)
        .reduce((acc, [localParamName, serverParamName]) => {
          if (serverErrors[serverParamName]) {
            if (acc[localParamName]) {
              acc[localParamName] = [
                ...acc[localParamName],
                ...serverErrors[serverParamName],
              ];
            } else {
              acc[localParamName] = serverErrors[serverParamName];
            }
          }

          return acc;
        }, serverErrors);

      throw new ValidationError(localErrors);
    }

    throw error;
  };
}
