import Cookies from 'js-cookie';
import getDomainName from './getDomainName';

const COOKIE_KEY = 'gatewayFrom';

export default function saveGateway() {
  Cookies.set(COOKIE_KEY, window.location.href, {
    domain: process.env.NODE_ENV === 'production'
      ? `.${getDomainName(window.location.hostname)}`
      : '',
  });
}
