var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('field',_vm._b({},'field',_vm.$props,false),[(((_vm.$props).type)==='checkbox')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.modeledValue),expression:"modeledValue"}],class:[
      'inherited-field-styles',
      'o-input',
      {
        'o-input--large': _vm.large,
        'o-input--disabled': _vm.disabled,
        'o-input--error': _vm.hasError,
      }
    ],style:({
      color: _vm.theme.mainTextColFor,
      height: _vm.height,
      textAlign: _vm.textAlign
    }),attrs:{"disabled":_vm.disabled,"maxlength":_vm.maxLength,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.modeledValue)?_vm._i(_vm.modeledValue,null)>-1:(_vm.modeledValue)},on:{"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"change":[function($event){var $$a=_vm.modeledValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.modeledValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.modeledValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.modeledValue=$$c}},function($event){return _vm.$emit('change', $event)}],"keydown":function($event){return _vm.$emit('keydown', $event)}}},'input',_vm.$props,false)):(((_vm.$props).type)==='radio')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.modeledValue),expression:"modeledValue"}],class:[
      'inherited-field-styles',
      'o-input',
      {
        'o-input--large': _vm.large,
        'o-input--disabled': _vm.disabled,
        'o-input--error': _vm.hasError,
      }
    ],style:({
      color: _vm.theme.mainTextColFor,
      height: _vm.height,
      textAlign: _vm.textAlign
    }),attrs:{"disabled":_vm.disabled,"maxlength":_vm.maxLength,"type":"radio"},domProps:{"checked":_vm._q(_vm.modeledValue,null)},on:{"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"change":[function($event){_vm.modeledValue=null},function($event){return _vm.$emit('change', $event)}],"keydown":function($event){return _vm.$emit('keydown', $event)}}},'input',_vm.$props,false)):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.modeledValue),expression:"modeledValue"}],class:[
      'inherited-field-styles',
      'o-input',
      {
        'o-input--large': _vm.large,
        'o-input--disabled': _vm.disabled,
        'o-input--error': _vm.hasError,
      }
    ],style:({
      color: _vm.theme.mainTextColFor,
      height: _vm.height,
      textAlign: _vm.textAlign
    }),attrs:{"disabled":_vm.disabled,"maxlength":_vm.maxLength,"type":(_vm.$props).type},domProps:{"value":(_vm.modeledValue)},on:{"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')},"change":function($event){return _vm.$emit('change', $event)},"keydown":function($event){return _vm.$emit('keydown', $event)},"input":function($event){if($event.target.composing){ return; }_vm.modeledValue=$event.target.value}}},'input',_vm.$props,false))])}
var staticRenderFns = []

export { render, staticRenderFns }