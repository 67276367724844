<template>
  <div
    :class="[
      'field',
      { 'field-success': success }
    ]">
    <OLabel>{{ label }}</OLabel>

    <div class="field__slot">
      <slot />
      <div class="field__loader" >
        <OLoader v-if="loading" />
      </div>
    </div>

    <OErrors :errors="errors" />

    <div class="field__hint">
      {{ hint }}
    </div>
  </div>
</template>

<script>
import useInputOptions from '@/mixins/use-input-options';

export default {
  mixins: [
    useInputOptions,
  ],
};
</script>

<style scoped>
.field {
    margin-bottom: 15px;
}

.field__hint {
    font-size: 12px;
    margin-top: 5px;
    color: var(--text-color);
    padding-left: 15px;
}

.field /deep/ .inherited-field-styles {
    box-sizing: border-box;
    padding: 8px 15px;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 12px !important;
    background: var(--input-background-color);
    color: var(--input-text-color);
    display: block;
    -webkit-appearance: none;
}

.field.field-success /deep/ .inherited-field-styles {
  border: 1px solid #008001 !important;
}

.field__slot {
    position: relative;
}

.field__loader {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}
</style>
