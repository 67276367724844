<template>
  <Modal
    v-model="isModalShown"
    large
    width="698px"
    height="auto"
  >
    <ModalContent>
      <ModalHeader @close="close">
        {{ locale.selectPaymentMethod }}
      </ModalHeader>

      <ModalBody>
        <div
          class="vast-payment-methods"
          v-resize="updateResize"
        >
          <div
            class="vast-payment-methods-row"
            ref="paymentMethodsRow"
            :style="stylePaymentMethodsRow"
          >
            <PaymentMethod
              v-for="paymentMethod in paymentMethods"
              ref="paymentMethodCard"
              :key="paymentMethod.id"
              :payment-method="paymentMethod"
              :selected-payment-method-id="selectedPaymentMethodId"
              :min-height="`${minHeight}px`"
              @selected="selectPaymentMethod"
            />
          </div>

          <div class="vast-payment-methods-actions">
            <OBtn
              width
              @click="save"
            >
              {{ locale.save }}
            </OBtn>
          </div>
        </div>
      </ModalBody>
    </ModalContent>
  </Modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import useStoreBinding from '@/mixins/use-store-binding';
import PaymentMethod from './PaymentMethod.vue';

export default {
  components: {
    PaymentMethod,
  },
  mixins: [
    useStoreBinding('isModalShown', 'paymentMethods.isModalShown', 'paymentMethods/SET_IS_MODAL_SHOWN'),
  ],
  data() {
    return {
      selectedPaymentMethodId: null,
      minHeight: 0,
      countRow: 4,
      countColumns: 4,
    };
  },
  watch: {
    isModalShown(newVal) {
      if (newVal) {
        this.selectedPaymentMethodId = this.$store.state.selectedPaymentMethodId;
        this.$nextTick(() => {
          this.minHeight = this.$refs.paymentMethodCard.reduce((acc, item) => {
            // eslint-disable-next-line
            acc = acc > item.$el.getBoundingClientRect().height
              ? acc : item.$el.getBoundingClientRect().height;

            return acc;
          }, 0);
          this.updateResize();
        });
      }
    },
  },
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
    ...mapState('paymentMethods', [
      'paymentMethods',
    ]),
    stylePaymentMethodsRow() {
      return {
        gridTemplateColumns: `${new Array(this.countColumns).fill('1fr').join(' ')}`,
        gridTemplateRows: `${new Array(this.countRow).fill('1fr').join(' ')}`,
      };
    },
  },
  methods: {
    close() {
      this.isModalShown = false;
    },
    save() {
      this.$store.commit('SET_SELECTED_PAYMENT_METHOD_ID', this.selectedPaymentMethodId);
      this.$store.dispatch('costs/calculateTotalCost');
      this.close();
    },
    selectPaymentMethod(val) {
      this.selectedPaymentMethodId = val;
    },
    updateResize() {
      const containerWidth = this.$refs.paymentMethodsRow.getBoundingClientRect().width;
      const widthCard = this.$refs
        .paymentMethodsRow.children[0].getBoundingClientRect().width;

      this.countColumns = parseInt(
        (containerWidth / (widthCard + 10)),
        10,
      );
      this.countRow = Math.ceil(this.paymentMethods.length / this.countRow);
    },
  },
};
</script>

<style lang="scss" scoped>
.qrcode {
  text-align: center;
  margin-top: 20px;
}

.vast-payment-methods {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.vast-payment-methods-row {
  display: grid;
  overflow: auto;
}

.vast-payment-methods-actions {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  border-radius: 10px;
  & > * {
    width: 270px;
    text-align: center;
    display: block !important;
    border-radius: 10px!important;
    border: 0px!important;
  }
}
</style>
