<script>
import { mapGetters } from 'vuex';

export default {
  render() {
    // eslint-disable-next-line
    return this._v(this.text);
  },
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
    text() {
      const raw = this.$scopedSlots.default()[0].text.trim();

      if (raw.match(/^_locale/)) {
        const localeKey = raw.split('.')[1];
        return this.locale[localeKey];
      }

      return raw;
    },
  },
};
</script>
