export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    loading: Boolean,
    success: Boolean,
    errors: {
      type: Array,
      default: () => [],
    },
  },
};
