<template>
  <div
    class="vast-service-order-now-service-description"
  >
    <div class="vast-service-order-now-service-description__title">
      <img
        :src="require('@/assets/images/package.svg')"
        class="vast-service-order-now-service-description__icon"
      >
      <p class="vast-service-order-now-service-description__title-text">
        {{ locale.packgeDescriptionTitle }}
      </p>
    </div>
    <table class="vast-service-order-now-service-description__table">
      <tr
        v-for="item in service.packageContents"
        :key="item.id"
        class="mb-2"
      >
        <td class="vast-service-order-now-service-description__table-name">
          {{ item.service_type.name }}
        </td>
        <td class="vast-service-order-now-service-description__table-cost">
          {{ item.quantity }}
        </td>
      </tr>
    </table>

    <div v-html="service.shortDescription" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    service: Object,
  },
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.vast-service-order-now-service-description {
  font-size: 12px;
  margin-top: 7px;
  padding-right: 8px;
  &__title {
    position: relative;
    display: flex;
    color: var(--modal-text-color);
    &-text {
      margin: 10px 0px;
    }
  }
  &__icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 8px;
    left: -28px;
  }
  &__table {
    border-style: hidden;
    color: var(--modal-text-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    TR, TD {
      padding: 0px;
    }
    &-name {
      min-width: 140px;
    }
  }
}
</style>
