<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.9981 11.0779V11.9979C21.9968 14.1543 21.2985 16.2526 20.0074
      17.9797C18.7162 19.7069 16.9013 20.9704 14.8334 21.5818C12.7655
      22.1932 10.5553 22.1198 8.53252 21.3725C6.50973 20.6252 4.7827
      19.244 3.60901 17.435C2.43531 15.6259 1.87784 13.486 2.01972 11.3342C2.16161
      9.18248 2.99525 7.13424 4.39633 5.49498C5.7974 3.85573 7.69083 2.71329
      9.79424 2.23806C11.8976 1.76282 14.0983 1.98025 16.0681 2.85791"
      stroke="#008001"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22 4.00195L12 14.012L9 11.012"
      stroke="#008001"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
};
</script>
