<template>
  <div class="languages-list">
    <div>
      <OInput
        v-model="search"
        class="search-field"
        :placeholder="locale.searchLanguages"
      />

      <FilterBySelectedLanguages
        :languages="selectedLanguages"
        v-model="isOnlySelected"
      />
    </div>

    <div class="languages-list__scroll">
      <div
        v-if="isEmpty"
        class="languages-list__empty"
      >
        {{ emptyText }}
      </div>

      <Language
        v-for="language in filteredLanguages"
        :key="language.id"
        :language="language"
        :view-type="viewType"
        v-model="selectedLanguages"
      />
    </div>

  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import escapeRegExp from '@/utils/escapeRegexp';
import createAssoc from '@/utils/createAssoc';
import Language from './Language.vue';
import FilterBySelectedLanguages from './FilterBySelectedLanguages.vue';

export default {
  components: {
    Language,
    FilterBySelectedLanguages,
  },
  props: {
    value: Array,
    languages: {
      type: Array,
      required: true,
    },
    viewType: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    search: '',
    isOnlySelected: false,
  }),
  computed: {
    ...mapState('serviceSettingsModal', ['selectables']),
    ...mapGetters('config', [
      'locale',
    ]),
    filteredLanguages() {
      return this.selectableLanguages.filter(
        (language) => {
          if (this.search) {
            if (this.search.match(',')) {
              const searchingIsoCodes = createAssoc(this.search.split(','));
              return searchingIsoCodes[language.iso_code_2] || false;
            }

            const regexp = new RegExp(escapeRegExp(this.search), 'ig');
            if (!language.name.match(regexp)) {
              return false;
            }
          }

          if (this.isOnlySelected) {
            return this.selectedLanguages.includes(language.id);
          }

          return true;
        },
      );
    },
    selectableLanguages() {
      return this.languages.filter((language) => this.selectables.includes(language.id));
    },
    selectedLanguages: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    isEmpty() {
      return this.filteredLanguages.length === 0;
    },
    emptyText() {
      if (this.isOnlySelected && !this.search) {
        return this.locale.noLanguagesSelected;
      }

      return this.locale.noLanguagesFound;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-field {
  display: block;
  width: 100%;
  ::v-deep {
    .label {
      display: none;
    }
  }
}

.languages-list {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  &__scroll {
    height: 100%;
    overflow: auto;
  }

  &__empty {
    text-align: center;
    user-select: none;
    color: var(--modal-text-color);
  }
}
</style>
