<template>
  <div>
    <br>
    <div class="block-title">
      {{ translations.name }}
    </div>
    <div class="countries-select">
      <div class="countries-select__row">
        <div
          v-if="disabled"
          class="countries-select__empty"
        >
          {{ disabled ? locale.geoTargetingDisabled : locale.noCountriesSelected }}
        </div>

        <template v-else>
          <div
            v-if="selectedCountries.length === 0"
            class="countries-select__empty"
          >
            {{ locale.noCountriesSelected }}
          </div>

          <div
            v-else
            class="countries-select__selected-list"
          >
            <OChip
              v-for="country in selectedCountries"
              :key="country.id"
              :label="country.name"
              @close="unselectCountry(country)"
            />
          </div>

          <div class="countries-select__button">
            <OBtn
              :disabled="disabled"
              text
              height="35"
              @click="openSelectCountries"
            >
              {{ selectedCountries.length ? locale.manageCountries : locale.selectCountries }}
            </OBtn>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    disabled: Boolean,
    value: Array,
    target: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
    ...mapState([
      'countries',
    ]),
    ...mapState('config', [
      'language',
    ]),
    selectedCountries: {
      get() {
        if (this.value.length > 0) {
          this.clearErrors();
        }
        return this.value.map((id) => this.countries.find((country) => country.id === id));
      },
      set(val) {
        this.$emit('input', val.map((country) => country.id));
      },
    },
    translations() {
      return this.target.translations[this.language] || this.target.translations.en;
    },
  },
  methods: {
    clearErrors() {
      this.$emit('clear-errors');
    },
    unselectCountry(selectedCountry) {
      this.selectedCountries = this.selectedCountries.filter(
        (country) => country.id !== selectedCountry.id,
      );
    },
    openSelectCountries() {
      this.$store.commit('serviceSettingsModal/SET_MODE', {
        mode: 'countriesSelect',
        id: this.target.id,
        selectables: this.target.selectables,
      });
      this.$store.commit('serviceSettingsModal/SET_PREVIOUS_SELECTED_TARGET', this.selectedCountries);
    },
  },
};
</script>

<style lang="scss" scoped>
.countries-select {
  margin-bottom: 20px;

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__empty {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  &__button {
    min-width: 155px;
    border-radius: 10px;
    & > * {
      width: 100%;
      text-align: center;
      border-radius: 8px !important;
    }
  }
}
.block-title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}
</style>
