import ValidationError from '@/utils/ValidationError';

export default (api) => ({
  namespaced: true,
  state: {
    loading: false,
    url: '',
    errors: {},
    availableServicesTypesIdsForUrl: [],
  },
  mutations: {
    SET_LOADING: (state, val) => { state.loading = val; },
    SET_URL: (state, val) => { state.url = val; },
    SET_ERRORS: (state, val) => { state.errors = val; },
    SET_AVAILABLE_SERVICES_TYPES: (state, val) => { state.availableServicesTypesIdsForUrl = val; },
  },
  actions: {
    fetchAvailableServicesTypesForUrl({ commit, state, rootState }) {
      commit('SET_LOADING', true);
      api.fetchAvailableServicesTypesForUrl({
        url: state.url,
        selectedNetworkId: rootState.selectedNetworkId,
      })
        .then((availableServicesTypesIdsForUrl) => {
          commit('SET_AVAILABLE_SERVICES_TYPES', availableServicesTypesIdsForUrl);
        })
        .catch((error) => {
          if (error instanceof ValidationError) {
            commit('SET_ERRORS', error.messages);
          }
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    },
  },
});
