<template>
  <div
    :class="[
      'bonus-and-coupon',
      { open: couponInputIsShown }
    ]">
    <div class="bonus-and-coupon__wrapper">
      <div class="bonus-and-coupon__coupon-section">
        <button
          v-if="!couponInputIsShown"
          class="bonus-and-coupon__coupon-section__button"
          @click="couponInputIsShown = true"
        >
          {{ locale.haveABonusCouponCode }}
        </button>

        <div v-if="couponInputIsShown">
          <CouponInput :errors="errors.coupon_code" />
        </div>
      </div>

      <div class="bonus-and-coupon__bonus-section" >
        <div
          v-if="couponBonus && couponBonus.value"
          class="bonus-and-coupon__bonus-summary-section"
        >
          <div
            :class="[
              {discount: !isCashback},
              'bonus-and-coupon__bonus-summary-section__coupon'
            ]"
          >
            <span class="bonus-and-coupon__title">
              {{ couponBonusTitle }}
            </span>

            <span class="bonus-and-coupon__value">
              {{couponBonus.value}} ({{ couponBonus.percent * 100 }}%)
              <div
                class="bonus-coupon__clear"
                @click="clearBonus"
              >
                <IconClearCoupon />
              </div>
            </span>
          </div>

          <div
            v-show="isCashback"
            class="bonus-and-coupon__info"
          >
            {{ locale.bonusInfoText }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      class="bonus-and-coupon__bonus-summary-section__errors"
      v-if="errors && errors.coupon_code"
    >
        {{ errors.coupon_code[0] }}
    </div> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import IconClearCoupon from '@/components/icons/IconClearCoupon.vue';
import CouponInput from '@/components/CouponInput.vue';

export default {
  name: 'BonusAndCoupon',
  components: {
    CouponInput,
    IconClearCoupon,
  },
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
    ...mapState('costs', [
      'totalBonus',
      'couponBonus',
      'fee',
    ]),
    ...mapState(['errors']),
    couponInputIsShown: {
      get() {
        return this.$store.state.coupons.isShown;
      },
      set(val) {
        this.$store.commit('coupons/SET_IS_SHOWN', val);
      },
    },
    isCashback() {
      return this.couponBonus.couponBonusType === 'cashback';
    },
    couponBonusTitle() {
      return this.isCashback ? this.locale.couponBonus : this.locale.couponBonusDiscount;
    },
  },
  methods: {
    clearBonus() {
      this.$store.commit('coupons/SET_COUPON_CODE', '');
      this.$store.commit('costs/SET_COUPON_BONUS', {});
      this.$store.commit('costs/SET_TOTAL_BONUS', 0);
      this.errors.coupon_code = [];
      this.calculateTotalCost();
    },
    calculateTotalCost() {
      this.$store.dispatch('costs/calculateTotalCost');
    },
  },
};
</script>

<style lang="scss" scoped>
  .bonus-and-coupon {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 14px;

    &.open {
      margin: 0px;
    }
    &__wrapper {
      display: flex;
      align-items: flex-end;

      @media (max-width: 500px) {
        flex-direction: column;
      }
    }

    @media (max-width: 800px) {
      margin-bottom: 23px;
    }

    @media (max-width: 500px) {
      flex-direction: column-reverse;
    }

    &__bonus-section {
      width: 100%;
      margin-bottom: 5px;

      @media (max-width: 500px) {
        max-width: 100%;
        margin-bottom: 10px;
      }

      &__button {
        color: white;
        background-color: #4cd427;
        border: none;
        width: 20px;
        margin-left: 3px;
        height: 20px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
      }

      &__message {
        margin-top: 8px;
        font-size: 12px;
        color: rgba(#000, .6);
      }
    }

    &__bonus-summary-section {
      width: 100%;
      margin-top: 8px;
      margin-left: 25px;

      &__coupon {
        max-width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.discount {
          flex-direction: column;
          justify-content: start;
          align-items: start;
          margin-bottom: 7px
        }
        .bonus-coupon__clear {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          margin-left: 8px;
          &:hover {
            cursor: pointer;
          }
        }
      }

      &__errors {
        margin-top: 8px;
        color: var(--errors-color);
        font-size: 12px;
      }
    }

    &__coupon-section {
      min-width: 179px;

      @media (max-width: 500px) {
        max-width: 100%;
        margin-left: 0;
      }

      &__button {
        background: none!important;
        border: none;
        padding: 0!important;
        color: var(--secondary-button-color);
        border-bottom: 1px dashed var(--secondary-button-color);
        cursor: pointer;
        opacity: 0.8;
        transition: all 0.25s;
        &:hover {
          opacity: 1;
        }
      }
    }
    &__title, &__value {
      color: var(--title-color);
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }
    &__value {
      display: flex;
      align-items: center;
      color: var(--text-color);
    }
    &__info {
      color: var(--text-color);
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
    }
  }

  @media (max-width: 1024px) {
    .bonus-and-coupon {
      margin: 0px;
      padding-right: 0px;
      &__wrapper {
        flex-direction: column;
      }
      &__coupon-section {
        width: 100%;
        &__button {
          margin-top: 50px;
        }
      }
      &__bonus-summary-section {
        margin-left: 0px;
        &__coupon {
          width: 100%;
          max-width: 100%;
          &.discount {
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 0px
          }
        }
      }
      .coupon-input {
        margin-top: 5px;
      }
    }
  }

  @media (max-width: 768px) {
    .bonus-and-coupon {
      margin: 0px;
      &__coupon-section {
        &__button {
          margin-top: 0px;
          padding: 2px 0px!important;
        }
      }
    }
  }
</style>
