import axios from 'axios';
import Network from '@/classes/Network';
import SettingsWidget from '@/classes/SettingsWidget';
import textToMapArray from '@/utils/textToMapArray';
import ValidationError from './utils/ValidationError';
import replaceText from './utils/replaceText';
import createValidationErrors from './utils/createValidationErrors';
import createOrders from './utils/createOrders';
import { getSavedAffiliateCode } from './utils/saveAffiliateCode';

const errorKeyDictionary = {
  seo_keyword: 'seoKeyword',
};

const errorValueDictionary = {
  seo_keyword: 'keyword',
};

export default ({
  apiUrl,
  apiToken,
  currency,
  language,
}) => ({
  fetchNetworks() {
    return axios.get(`${apiUrl}/api/v1/order-widget/networks`, {
      params: {
        currency,
        api_token: apiToken,
        locale: language,
      },
    })
      .then((response) => response.data.data.map((network) => new Network(network)));
  },
  fetchSettings() {
    return axios.get(`${apiUrl}/api/v1/order-widget/settings`, {
      params: {
        api_token: apiToken,
      },
    })
      .then((response) => new SettingsWidget(response.data.data));
  },
  fetchAvailableServicesTypesForUrl({
    url,
    selectedNetworkId,
  }) {
    return new Promise((resolve, reject) => {
      if (this.fetchAvailableServicesTypesForUrl.abort) {
        this.fetchAvailableServicesTypesForUrl.abort();
      }

      if (!url) {
        resolve([]);
        return;
      }

      axios.post(`${apiUrl}/api/v1/order-widget/services/available-types-by-url`,
        {
          url,
          network_id: selectedNetworkId,
        },
        {
          params: {
            api_token: apiToken,
          },
          cancelToken: new axios.CancelToken((c) => {
            this.fetchAvailableServicesTypesForUrl.abort = c;
          }),
        })
        .then((response) => resolve(response.data.data.service_type_ids))
        .catch((error) => {
          try {
            createValidationErrors()(error);
          } catch (e) {
            reject(e);
          }
        });
    });
  },
  calculateAllCostServices(selectedServiceTypes, selectedPaymentMethodId, couponCode, url) {
    if (this.calculateAllCostServices.abort) {
      this.calculateAllCostServices.abort();
    }

    const services = selectedServiceTypes.map((serviceType) => ({
      service_id: serviceType.settings.selectedServiceId,
      quantity: serviceType.settings.quantity,
      url,
    }));

    return axios.post(
      `${apiUrl}/api/v1/order-widget/services/total-cost`,
      {
        services,
        coupon_code: couponCode,
        order_widget_type: 'single',
      },
      {
        params: {
          api_token: apiToken,
          payment_method_id: selectedPaymentMethodId,
          currency,
        },
        cancelToken: new axios.CancelToken((c) => {
          this.calculateAllCostServices.abort = c;
        }),
      },
    )
      .then((response) => {
        const subtotals = response.data.data.items[0].services.reduce((acc, subtotal) => {
          const localService = selectedServiceTypes.find(
            (item) => item.settings.selectedServiceId === subtotal.service_id,
          );

          return {
            ...acc,
            [localService.id]: {
              cost: subtotal.cost,
              defaultCost: subtotal.default_cost,
              priceType: subtotal.price_type,
            },
          };
        }, {});

        return {
          subtotals,
        };
      });
  },
  calculateTotalCost(selectedServiceTypes, selectedPaymentMethodId, couponCode, url) {
    if (this.calculateTotalCost.abort) {
      this.calculateTotalCost.abort();
    }

    if (selectedServiceTypes.length === 0) {
      return Promise.resolve({
        summary: 0,
        subtotals: {},
      });
    }

    const selectedServiceTypesCopied = JSON.parse(JSON.stringify(selectedServiceTypes));
    const services = selectedServiceTypesCopied.map((serviceType) => {
      const selectedServiceId = serviceType.settings.selectedServiceId;
      const selectedService = serviceType.services
        .find((service) => service.id === selectedServiceId);

      const returnObject = {
        service_id: selectedServiceId,
        url,
      };

      if (selectedService.calculableQuantity) {
        const targetableValues = serviceType.settings.targetableValues.filter((target) => (
          target.viewType === 'seo_keywords'
          || target.viewType === 'comments'
        ));

        returnObject.targetable_values = targetableValues.map((target) => ({
          target_id: target.targetId,
          values: textToMapArray({
            text: target.values,
            viewType: target.viewType,
          }),
        }));
        returnObject.targetable_values = returnObject.targetable_values
          .filter((target) => target.values.length);
      }

      returnObject.quantity = selectedService.calculableQuantity
        ? null
        : serviceType.settings.quantity;

      return returnObject;
    });
    return axios.post(
      `${apiUrl}/api/v1/order-widget/services/total-cost`,
      {
        services,
        coupon_code: couponCode,
        order_widget_type: 'single',
      },
      {
        params: {
          api_token: apiToken,
          payment_method_id: selectedPaymentMethodId,
          currency,
        },
        cancelToken: new axios.CancelToken((c) => {
          this.calculateTotalCost.abort = c;
        }),
      },
    )
      .then((response) => {
        const subtotals = response.data.data.items[0].services.reduce((acc, subtotal) => {
          const localService = selectedServiceTypesCopied.find(
            (item) => item.settings.selectedServiceId === subtotal.service_id,
          );

          return {
            ...acc,
            [localService.id]: {
              cost: subtotal.cost,
              defaultCost: subtotal.default_cost,
              priceType: subtotal.price_type,
            },
          };
        }, {});

        return {
          summary: response.data.data.total_amount,
          subtotals,
          fee: {
            value: response.data.data.fee,
            percent: response.data.data.fee_percent,
          },
          paymentMethodBonus: {
            value: response.data.data.payment_method_bonus,
            percent: response.data.data.payment_method_bonus_percent,
          },
          couponBonus: {
            value: response.data.data.coupon_bonus,
            percent: response.data.data.coupon_bonus_percentage,
            couponBonusType: response.data.data.coupon_bonus_type,
          },
          totalBonus: response.data.data.total_bonus,
        };
      });
  },
  getCountries() {
    return axios.get(`${apiUrl}/api/v1/order-widget/countries`, {
      params: {
        api_token: apiToken,
      },
    })
      .then((response) => response.data.data);
  },
  getLanguages() {
    return axios.get(`${apiUrl}/api/v1/order-widget/languages`, {
      params: {
        api_token: apiToken,
      },
    })
      .then((response) => response.data.data);
  },
  createOrder({
    selectedServiceTypes,
    url,
    email,
    paymentMethod,
    gaClientId,
    ga4ClientId,
    ga4SessionId,
    couponCode,
  }) {
    const { orders, orderIndexToServiceTypeIdMap } = createOrders(url, selectedServiceTypes);

    return axios.post(
      `${apiUrl}/api/v1/order-widget/widget-order-requests`,
      {
        email,
        payment_method_id: paymentMethod.id,
        affiliate_code: getSavedAffiliateCode(),
        google_analytics_client_id: gaClientId,
        google_analytics4_client_id: ga4ClientId,
        google_analytics4_session_id: ga4SessionId,
        coupon_code: couponCode,
        orders,
        source: 'order_widget',
      },
      {
        params: {
          api_token: apiToken,
          locale: language,
        },
        withCredentials: true,
      },
    )
      .then((response) => response.data.data)
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.errors) {
          const errors = error.response.data.errors;

          const localErrors = Object.entries(errors).reduce((acc, [fieldKey, fieldErrors]) => {
            if (fieldKey.match(/orders\.\d+\.url/)) {
              acc.url = [
                ...(acc.url || []),
                ...fieldErrors,
              ];
            } else if (fieldKey.match(/orders\.\d+\./)) {
              // eslint-disable-next-line
              const [_, orderIndex, paramName] = fieldKey.match(/orders\.(\d)\.(.+)/);
              const serviceTypeId = orderIndexToServiceTypeIdMap[orderIndex];
              const replacedParamName = replaceText(errorKeyDictionary, paramName);

              acc[`${serviceTypeId}.${replacedParamName}`] = fieldErrors
                .map((fieldError) => replaceText(errorValueDictionary, fieldError));
            } else {
              acc[fieldKey] = fieldErrors;
            }

            return acc;
          }, {});

          throw new ValidationError(localErrors);
        }
      });
  },
  confirmPaid(invoiceId) {
    return axios.post(
      `${apiUrl}/api/v1/order-widget/invoiced/${invoiceId}/set-manual-payment-status-is-pending`,
      null,
      {
        params: {
          api_token: apiToken,
        },
      },
    );
  },
  getPaymentMethods() {
    return axios.get(`${apiUrl}/api/v1/order-widget/payment-methods`, {
      params: {
        api_token: apiToken,
      },
    })
      .then((response) => response.data.data);
  },
  sendgridUrl() {
    axios.post(`${apiUrl}/api/v1/order-widget/events/enter-url`, {
      params: {
        api_token: apiToken,
      },
    });
  },
  sendgridPaymentPage() {
    axios.post(`${apiUrl}/api/v1/order-widget/events/payment-page`, {
      params: {
        api_token: apiToken,
      },
    });
  },
  async identifyNetworkByLink(url) {
    return axios.post(
      `${apiUrl}/api/v1/order-widget/networks/identify`,
      {
        url,
      },
      {
        params: {
          api_token: apiToken,
        },
      },
    )
      .then((response) => response.data.data.id)
      .catch(() => null);
  },
});
