import Vue from 'vue';
import VueResizeObserver from 'vue-resize-observer';
import { loadStripe } from '@clickadilla/components/PaymentStrategies';
import App from './App.vue';
import storeFactory from './store';
import apiFactory from './api';
import WidgetJSApi from './WidgetJSApi';
import './global-components';
import { saveAffiliateCode } from './utils/saveAffiliateCode';
import processApiUrl from './utils/processApiUrl';

Vue.use(VueResizeObserver);

Vue.directive('click-outside', {
  bind: (el, binding, vnode) => {
    // eslint-disable-next-line
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: (el) => {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

Vue.config.productionTip = (process.env.NODE_ENV !== 'development');
loadStripe();
saveAffiliateCode();

window.vastServiceOrderWidget = ({
  mountTargetSelector,
  apiUrl,
  apiToken,
  language = 'en',
  currency = 'USD',
  availableNetworks = [],
  availableServiceTypes = [],
  networksAreShown = true,
  title = '',
  theme = {},
}) => {
  const processedApiUrl = processApiUrl(apiUrl);

  const initialConfig = {
    apiUrl: processedApiUrl,
    apiToken,
    language,
    currency,
    networksAreShown,
    theme: {
      mainBackground: '#FFFFFF',
      titleColor: '#201F37',
      textColor: '#201F37',
      inputBackgroundColor: '#D6DDEF',
      inputTextColor: '#000000',
      selectedElementsColor: '#50A78D',
      errorsColor: '#FF5252',
      modalBackground: '#FFFFFF',
      modalTextColor: '#201F37',
      loaderColor: '#201F37',
      networkColor: '',
      networkTextColor: '#4C5D8C',
      networkSelectedColor: '#4C5D8C',
      networkSelectedTextColor: '#FFFFFF',
      iconColor: '#D6DDEF',
      primaryButtonColor: '#4C5D8C',
      primaryButtonTextColor: '#FFFFFF',
      primaryButtonDisabledColor: '#C7C7C7',
      primaryButtonDisabledTextColor: '#000000',
      secondaryButtonColor: '#4C5D8C',
      secondaryButtonDisabledColor: '#4C5D8C',
      serviceTypeIconShow: false,
      networks: undefined,
      ...theme,
    },
  };

  const widgetJSApiInstance = new WidgetJSApi();

  const api = apiFactory(initialConfig);
  const store = storeFactory(api, widgetJSApiInstance);

  store.dispatch('fetchCountries');
  store.dispatch('fetchLanguages');
  store.dispatch('paymentMethods/fetchPaymentMethods');
  store.dispatch('networks/fetchNetworks');
  store.dispatch('settings/fetchSettings');

  store.commit('config/SET_API_URL', processedApiUrl);
  store.commit('config/SET_API_TOKEN', apiToken);
  store.commit('config/SET_LANGUAGE', language);
  store.commit('config/SET_CURRENCY', currency);
  store.commit('config/SET_TITLE', title);
  store.commit('config/SET_AVAILABLE_NETWORKS', availableNetworks.map(Number));
  store.commit('config/SET_AVAILABLE_AVAILABLE_SERVICE_TYPES', availableServiceTypes.map(Number));
  store.commit('config/SET_NETWORKS_ARE_SHOWN', networksAreShown);
  store.commit('config/SET_THEME', initialConfig.theme);

  const instance = new Vue({
    store,
    provide: {
      api,
      widgetJSApiInstance,
    },
    render: (h) => h(App),
  }).$mount(mountTargetSelector);

  widgetJSApiInstance.addActionCallback('destroy', () => instance.$destroy());

  window.vastServiceOrderWidget.instances.push(widgetJSApiInstance);

  return widgetJSApiInstance;
};

window.vastServiceOrderWidget.build_hash = process.env.VUE_APP_BUILD_HASH;
window.vastServiceOrderWidget.instances = [];
window.vastServiceOrderWidget.instances.destroy = () => {
  window.vastServiceOrderWidget.instances.forEach((instance, i) => {
    instance.destroy();
    window.vastServiceOrderWidget.instances.splice(i, 1);
  });
};
