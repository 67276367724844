<template>
  <ModalContent>
    <ModalHeader @close="close">
      <ButtonBack @click="close" />

      {{ locale.selectLanguages }}
    </ModalHeader>

    <ModalBody>
      <LanguagesList
        v-model="selectedLanguages"
        :languages="languages"
        :view-type="target.type.viewType"
      />
    </ModalBody>

    <ModalFooter>
      <ApplySettingsBtn @click="save"/>
    </ModalFooter>
  </ModalContent>
</template>

<script>
import { mapGetters } from 'vuex';

import ButtonBack from '@/components/ButtonBack.vue';
import LanguagesList from './LanguagesList.vue';
import ApplySettingsBtn from './ApplySettingsBtn.vue';

export default {
  components: {
    ButtonBack,
    LanguagesList,
    ApplySettingsBtn,
  },
  props: {
    value: Array,
    languages: {
      type: Array,
      required: true,
    },
    target: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
    selectedLanguages: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      this.$emit('save');
    },
  },
};
</script>
