<template>
  <label
    :class="[
      'radio',
      {
        'radio--checked': checked,
        'radio--disabled': disabled
      }
    ]"
  >
    <input
      v-model="Current"
      :disabled="disabled"
      class="radio__el"
      :value="value"
      type="radio"
    >

    <div class="radio__row">
      <div class="radio__switch">
        <IconCheck class="radio__check" />
      </div>

      <div
        v-show="label"
        class="radio__texts"
      >
        <div
          class="radio__label"
        >
          {{ label }}
        </div>

        <div class="radio__hint">
          {{ hint }}
        </div>
      </div>
    </div>
  </label>
</template>

<script>
import IconCheck from '@/components/icons/IconCheck.vue';

export default {
  model: {
    prop: 'current',
    event: 'input',
  },
  props: {
    disabled: Boolean,
    current: [String, Number],
    value: [String, Number],
    hint: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  components: {
    IconCheck,
  },
  computed: {
    checked() {
      return this.Current === this.value;
    },
    Current: {
      get() {
        return this.current;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.radio {
  display: block;
  user-select: none;
  margin-bottom: 10px;
  cursor: pointer;

  &__row {
    display: flex;
  }

  &__switch {
    flex-shrink: 0;
    transform: translateY(3px);
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid var(--selected-elements-color);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: border .1s;
    SVG {
      opacity: 0;
    }
  }

  &__el {
    display: none;
  }

  &--checked {
    .radio {
      &__switch {
        background-color: var(--selected-elements-color);
        SVG {
          opacity: 1;
        }
      }
    }
  }

  &__texts {
    margin-left: 9px;
    width: 100%;
  }

  &__label {
    display: inline-block;
    font-size: 14px;
  }

  &__hint {
    font-size: 12px;
    color: #8B8B8B;
    margin-top: 3px;
  }
}
</style>
