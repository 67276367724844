<template>
  <div class="modal-content">
    <slot />
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100vh;
  padding: 40px 42px 34px;
}
@media (max-width: 768px) {
  .modal-content {
    padding: 40px 28px 34px;
  }
}
</style>
