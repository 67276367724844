<template>
  <component
    :is="targetViewComponent"
    :service-type="serviceType"
    :target="target"
    v-model="selectedTarget"
    @clearErrors="clearErrors"
  />
</template>

<script>
import TextAreaField from '@/components/ServiceSettingsModal/TextareaField.vue';
import ServiceType from '@/classes/ServiceType';
import InputField from './InputField.vue';
import Languages from './Languages.vue';
import Countries from './Countries.vue';

const TARGET_VIEWS_COMPONENT = {
  textarea: 'TextAreaField',
  comments: 'TextAreaField',
  seo_keywords: 'TextAreaField',
  input: 'InputField',
  float_input: 'InputField',
  number_input: 'InputField',
  url_input: 'InputField',
  languages: 'Languages',
  countries: 'Countries',
};

export default {
  components: {
    TextAreaField,
    Languages,
    Countries,
    InputField,
  },
  props: {
    target: {
      type: Object,
      required: true,
    },
    serviceType: ServiceType,
  },
  computed: {
    targetViewComponent() {
      if (this.target?.type.selectable) {
        return TARGET_VIEWS_COMPONENT[this.target?.type?.selectable];
      }
      return TARGET_VIEWS_COMPONENT[this.target?.type?.viewType];
    },
    selectedTarget: {
      get() {
        return this.serviceType.settings.targetableValues[this.targetableValueIndex].values;
      },
      set(val) {
        this.serviceType.settings.targetableValues[this.targetableValueIndex].values = val;
      },
    },
    targetableValueIndex() {
      return this.serviceType.settings.targetableValues.findIndex((target) => (
        target.targetId === this.target.id
      ));
    },
  },
  methods: {
    clearErrors() {
      this.$emit('clear-errors');
    },
  },
};
</script>
