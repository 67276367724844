<template>
  <div>
    <OCheckBox
      class="language"
      v-model="isSelected"
      :label="language.name"
    />
  </div>
</template>

<script>
export default {
  props: {
    language: Object,
    value: Array,
    viewType: String,
  },
  computed: {
    isSelected: {
      get() {
        return this.selectedLanguages.includes(this.language.id);
      },
      set(val) {
        if (val) {
          this.selectedLanguages = this.viewType === 'select' ? [this.language.id] : [
            ...this.selectedLanguages,
            this.language.id,
          ];
        } else {
          this.selectedLanguages = this.selectedLanguages.filter(
            (id) => id !== this.language.id,
          );
        }
      },
    },
    selectedLanguages: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.language {
  display: block;
  padding: 10px;

  &:hover {
    background: rgba(#000, .05);
  }
}
</style>
