export default function createTranslations(data) {
  return data.reduce((accumulator, obj) => {
    const { locale, ...rest } = obj;
    return {
      ...accumulator,
      [locale]: {
        description: rest.description,
        id: rest.id,
        locale: rest.locale,
        name: rest.name,
        placeholder: rest.placeholder,
        targetId: rest.target_id,
      },
    };
  }, {});
}
