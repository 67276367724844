<template>
  <div class="commission" >
    <div v-if="fee && +fee.percent">
      <span class="commission__label">{{ locale.commissionOfSelectedPS }}: </span>
      <span class="commission__info">
        {{ fee.value }}
        ({{ fee.percent * 100 }}%)
    </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Commission',
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
    ...mapState('costs', [
      'totalBonus',
      'fee',
    ]),
  },
};
</script>

<style scoped lang="scss">
  .commission {
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;

    &__label, &__info {
      color: var(--text-color);
    }
  }
</style>
