<template>
  <Modal v-model="isShown">
    <ModalHeader @close="isShown = false">
      {{ locale.qrCode }}
    </ModalHeader>

    <ModalBody>
      <div class="qrcode">
        <div>
          {{ locale.qrCodeText }}
        </div>

        <VueQrcode
          :value="url"
          :options="{ width: 200 }"
        />
      </div>
    </ModalBody>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import eventBus from '@/utils/eventBus';

export default {
  components: {
    VueQrcode,
  },
  data: () => ({
    url: '',
  }),
  mounted() {
    eventBus.$on('qrcode-payment', (url) => {
      this.url = url;
    });
  },
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
    isShown: {
      get() {
        return !!this.url;
      },
      set(val) {
        if (!val) {
          this.url = '';
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.qrcode {
  text-align: center;
  margin-top: 20px;
}
</style>
