<template functional>
  <div class="modal-body">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.modal-body {
  overflow: auto;
  height: 100%;
}
</style>
