<template>
  <div>
    <div class="coupon-input">
      <span class="coupon-input__label">
        {{ locale.couponLabel }}
      </span>

      <div class="coupon-input__container">
        <o-input
          v-model="couponCode"
          :success="hasCouponeBonus"
          :placeholder="locale.couponCode"
          :errors="errors"
          height="58px"
          class="coupon-input__field"
        />

        <div
          v-if="hasCouponeBonus"
          class="coupon-input__coupon-accepted"
        >
          <IconCouponAccepted />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import debounce from 'debounce';
import IconCouponAccepted from '@/components/icons/IconCouponAccepted.vue';

export default {
  components: {
    IconCouponAccepted,
  },
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
    ...mapState('costs', [
      'couponBonus',
    ]),
    couponCode: {
      get() {
        return this.$store.state.coupons.couponCode;
      },
      set(val) {
        this.$store.commit('coupons/SET_COUPON_CODE', val);
      },
    },
    hasCouponeBonus() {
      return this.couponBonus && this.couponBonus.value;
    },
  },
  created() {
    this.debouncedCalculateCost = debounce(this.calculateTotalCost, 800);

    this.$watch(
      () => this.couponCode,
      () => {
        this.debouncedCalculateCost();
      },
      {
        deep: true,
      },
    );
  },
  methods: {
    calculateTotalCost() {
      return this.$store.dispatch('costs/calculateTotalCost');
    },
  },
};
</script>

<style lang="scss" scoped>
  .coupon-input {
    margin-top: 3px;
    width: 100%;

    @media (max-width: 500px) {
      width: 100%;
    }

    &__label {
      margin-bottom: 10px;
      color: var(--title-color);
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }
    &__field {
      margin-bottom: 0px!important;
      ::v-deep .field__hint {
        display: none!important;
      }
    }
    &__container {
      position: relative;
    }
    &__coupon-accepted {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      display: flex;
    }
  }

  @media (max-width: 1024px) {
    .coupon-input {
      margin-top: 5px;
    }
  }
</style>
