<template>
  <Modal v-model="isShown">
    <ModalHeader @close="isShown = false">
      {{ locale.manualPayment }}
    </ModalHeader>

    <ModalBody>
      <div class="manual-payment">
        <div v-if="!paid">
          {{ locale.manualTransferToAccountText }}
          <br />
          <strong>{{ paymentData.account_number }}</strong>
          <br />
          {{ locale.manualCommentText }}
          <br />
          <strong>{{ paymentData.comment }}</strong>
          <br />
          <br />
        </div>

        <div v-else>
          {{ locale.manualDone }}
        </div>

        <div class="manual-payment__buttons">
          <OBtn
            v-if="!paid"
            :loading="loading"
            class="manual-payment__button"
            @click="confirmPaid()"
          >
            {{ locale.manualIPaid }}
          </OBtn>
        </div>
      </div>
    </ModalBody>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import eventBus from '@/utils/eventBus';

export default {
  inject: [
    'api',
  ],
  data: () => ({
    paymentData: {},
    loading: false,
    paid: false,
  }),
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
    isShown: {
      get() {
        return !!Object.keys(this.paymentData).length;
      },
      set(val) {
        this.paid = false;
        if (!val) {
          this.paymentData = {};
          this.loading = false;
        }
      },
    },
  },
  mounted() {
    eventBus.$on('manual-payment', (paymentData) => {
      this.paymentData = paymentData;
    });
  },
  methods: {
    confirmPaid() {
      this.loading = true;
      this.api.confirmPaid(this.paymentData.invoice_id)
        .then(() => {
          this.paid = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.manual-payment {
  text-align: center;

  &__buttons {
    margin-top: 20px;
  }

  &__button {
    margin-right: 10px;
    margin-bottom: 5px;
  }
}
</style>
