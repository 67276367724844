<template>
  <CardSelect
    :class="[
      'vast-service-order-now-network',
      {
        'vast-service-order-now-network--disabled': disabled,
        'vast-service-order-now-network--selected': selectedNetworkId === network.id,
      }
    ]"
    v-model="selectedNetworkId"
    :value="network.id"
  >
    <div
      v-if="network.icon"
      class="vast-service-order-now-network__logo"
      :style="iconStyle"
    />

    <div class="vast-service-order-now-network__label">
      {{ networkName }}
    </div>
  </CardSelect>
</template>

<script>
import CardSelect from '@/components/CardSelect.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    CardSelect,
  },
  props: {
    network: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
    networkName() {
      const slicedNetworkName = this.network.name.slice(0, 35);

      return slicedNetworkName.length < this.network.name.lengt
        ? `${slicedNetworkName}...`
        : slicedNetworkName;
    },
    selectedNetworkId: {
      get() {
        return this.$store.state.selectedNetworkId;
      },
      set(id) {
        if (this.disabled) {
          return;
        }

        this.$store.dispatch('setSelectedNetworkId', id);
        this.$emit('selectNetwork');
      },
    },
    iconStyle() {
      return { backgroundImage: `url(${this.network.icon})` };
    },
    disabled() {
      return !this.network.serviceTypes || !this.network.serviceTypes.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.vast-service-order-now-network {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 15px 15px 0;
  background-color: var(--network-color);
  color: var(--network-text-color);
  &__logo {
    width: 34px;
    height: 34px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: all .3s;
    margin-bottom: 6px;
  }

  &__label {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
  }

  &--selected {
    background-color: var(--network-selected-color);
    color: var(--network-selected-text-color);
  }
  &--disabled {
    cursor: default;

    .vast-service-order-now-network {
      &__logo {
        opacity: .3;
      }
      &__label {
        opacity: .4;
      }
    }
  }
  &:hover {
    box-shadow: 6px 6px 10px 0px rgba(0, 0, 0, 0.10);
  }
}

@media (max-width: 688px) {
  .vast-service-order-now-network {
    margin: 0px;
    &:hover {
      box-shadow: none;
    }
  }
}
</style>
