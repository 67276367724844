<template>
  <div class="tabs">
    <button
      v-for="tab in tabs"
      :key="tab.value"
      :class="[
        'tabs__tab',
        {
          'tabs__tab--selected': selectedTab === tab.value
        }
      ]"
      @click="selectedTab = tab.value"
    >
      {{ tab.label }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selectedTab: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  margin-top: 20px;
  margin-bottom: 25px;
  display: flex;

  &__tab {
    position: relative;
    background: 0;
    text-align: center;
    border: 0;
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding: 5px;
    outline: none;

    &--selected {
      border-bottom-color: transparent;
      font-weight: 600;
      &::after {
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: var(--modal-text-color);
        bottom: -5px;
        left: 0;
      }
    }
  }
}
</style>
