<template>
  <div class="chip">
    <div class="chip__label">
      {{ label }}
    </div>

    <button
      class="chip__close"
      @click="$emit('close')"
    >
      <IconDeleteChip />
    </button>
  </div>
</template>

<script>
import IconDeleteChip from '@/components/icons/IconDeleteChip.vue';

export default {
  components: {
    IconDeleteChip,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.chip {
  height: 30px;
  padding: 4px 12px;
  margin-bottom: 11px;
  display: inline-flex;
  margin-right: 16px;
  align-items: center;
  border-radius: 8px;
  background: var(--input-background-color);
  color: var(--input-text-color);

  &__label {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  &__close {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 0;
    width: 20px;
    margin-left: 20px;
    background: 0;
    padding: 0;
    color: #000;
  }
}
</style>
