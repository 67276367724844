<template>
    <ModalContent>
      <ModalHeader v-on="$listeners">{{ locale.bonusInfo }}</ModalHeader>
      <ModalBody>
        <div>
          {{ locale.bonusInfoText }}
        </div>
        <table v-if="paymentMethodBonusesWithActivate.length" class="bonus-table">
          <tbody>
          <tr class="bonus-table__header">
            <th class="bonus-table__header-from">
              {{ locale.fromAmount }}
            </th>

            <th>
              {{ locale.bonusPercent }}
            </th>

            <th>
              {{ locale.youWillGet }}
            </th>
          </tr>
          <tr
            v-for="(bonus, idx) in paymentMethodBonusesWithActivate"
            :key="idx"
            :class="{'active-bonus': bonus.activate}"
          >
            <td>
              {{ (+bonus.from_amount).toFixed(2) }}
            </td>

            <td class="bonus-table__percent">
              {{ bonus.bonus_percent *100 }}%
            </td>

            <td class="bonus-table__will-get">
              {{ formatSummaryString(bonus.bonus_percent) }}
            </td>
          </tr>
          </tbody>
        </table>
      </ModalBody>
    </ModalContent>
</template>

<script>
import useStoreBinding from '@/mixins/use-store-binding';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'BonusInfo',
  mixins: [
    useStoreBinding('isShown', 'bonusInfoModal.isShown', 'bonusInfoModal/SET_IS_SHOWN'),
  ],
  computed: {
    ...mapState('costs', [
      'summary',
    ]),
    ...mapGetters('config', [
      'locale',
    ]),
    ...mapGetters(['selectedPaymentMethod']),

    paymentMethodBonusesWithActivate() {
      const bonuses = this.selectedPaymentMethod.payment_method_bonuses;
      const amount = this.summary.replace(/[₽$\s]/g, '');
      if (amount) {
        return bonuses.reduce((acc, bonus, index) => {
          acc.push({ ...bonus, activate: true });
          if (+bonus.from_amount <= +amount) {
            if (index) {
              acc[index].activate = true;
              acc[index - 1].activate = false;
            }
          } else {
            acc[index].activate = false;
          }
          return acc;
        }, []);
      }
      return bonuses;
    },
  },
  methods: {
    formatSummaryString(percent) {
      if (this.summary) {
        return (this.summary.replace(/[₽$\s]/g, '') * percent).toFixed(2);
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
  .bonus-table {
    width: 100%;
    margin-top: 44px;
    border: none;
    border-collapse: collapse;
    th, td {
      padding: 10px;
      border: none;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
    &__header {
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }
    &__header-from {
      text-align: left;
    }
    &__percent, &__will-get {
      text-align: center;
    }
  }

  .active-bonus {
    background-color: var(--primary-button-color);
    color: var(--primary-button-text-color);
    font-weight: 700;
    :first-child {
      border-radius: 4px 0px 0px 4px;
    }
    :last-child {
      border-radius: 0px 4px 4px 0px;
    }
  }
</style>
