<template>
  <div
    class="vast-service-order-now-email-input"
  >
    <OLabel class="vast-service-order-now-email-input__label">
      {{ locale.yourEmail }}
    </OLabel>

    <div>
      <OInput
        v-model="email"
        placeholder="john@doe.com *"
        class="vast-service-order-now-email-input__field"
        :errors="errors.email"
        height="58px"
        @input="errors.email = []"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import OLabel from '@/components/global/OLabel.vue';
import useStoreBinding from '@/mixins/use-store-binding';

export default {
  components: {
    OLabel,
  },
  mixins: [
    useStoreBinding('email', 'email', 'SET_EMAIL'),
  ],
  inject: [
    'widgetJSApiInstance',
  ],
  created() {
    this.widgetJSApiInstance.addActionCallback('getEmail', () => this.email);

    this.widgetJSApiInstance.addActionCallback('setEmail', (email) => {
      this.email = email;
    });

    this.$watch(
      () => this.email,
      (val) => this.widgetJSApiInstance.dispatchEvent('onEmailInput', val),
    );
  },
  computed: {
    ...mapState([
      'errors',
    ]),
    ...mapGetters('config', [
      'locale',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.vast-service-order-now-email-input {
  width: 100%;

  @media (max-width: 500px) {
    width: 100%;
  }

  &__field {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 1024px) {
  .vast-service-order-now-email-input {
    margin-right: 0px;
  }
}
</style>
