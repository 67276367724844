export default (api, widgetJSApiInstance) => ({
  namespaced: true,
  state: {
    isModalShown: false,
    paymentMethods: [],
    loading: false,
  },
  mutations: {
    SET_IS_MODAL_SHOWN: (state, val) => { state.isModalShown = val; },
    SET_PAYMENT_METHODS: (state, val) => { state.paymentMethods = val; },
    SET_LOADING: (state, val) => { state.loading = val; },
  },
  actions: {
    fetchPaymentMethods({ commit }) {
      commit('SET_LOADING', true);
      api.getPaymentMethods()
        .then((paymentMethods) => {
          commit('SET_PAYMENT_METHODS', paymentMethods);
          widgetJSApiInstance.dispatchEvent('onPaymentMethodsLoaded', { paymentMethods });
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    },
  },
});
