<template>
  <ExpandTransition>
    <div
      v-if="errors[0]"
      class="errors"
      :style="{
        color: theme.errorsColor
      }"
    >
      {{ errors[0] }}
    </div>
  </ExpandTransition>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('config', [
      'theme',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.errors {
    font-size: 12px;
    margin: 3px;
}
</style>
