<template>
  <div
    class="vast-show-more"
    @click="showMore"
  >
    <div
      class="vast-show-more__line"
    />

    <span class="vast-show-more__text">
      {{ isShowMore ? locale.hide : locale.showMore }}
    </span>

    <span
      :class="[
        'vast-show-more__arrow',
        { open: isShowMore },
      ]"
    >
      <IconChevron width="11px" />
    </span>

    <div
      class="vast-show-more__line"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IconChevron from '@/components/icons/IconChevron.vue';

export default {
  name: 'ShowMoreLine',
  inject: [
    'widgetJSApiInstance',
  ],
  props: {
    isShowMore: Boolean,
  },
  components: {
    IconChevron,
  },
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
  },
  methods: {
    showMore() {
      this.widgetJSApiInstance.dispatchEvent('onClickShowMore');
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
  .vast-show-more {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--secondary-button-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    &__line {
      flex: 1;
      height: 1px;
      background: var(--secondary-button-color);
    }
    &__text, &__arrow {
      &:hover {
        cursor: pointer;
      }
    }
    &__text {
      margin-left: 38px;
      text-align: right;
    }
    &__arrow {
      display: flex;
      margin-left: 8px;
      margin-right: 38px;
      &.open {
        transform: rotateX(180deg);
      }
    }
  }
</style>
