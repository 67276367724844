import objectAssignByDictionary from '@/utils/objectAssignByDictionary';
import Service from './Service';

export default class ServiceType {
  constructor(data) {
    this.id = 0;
    this.name = '';
    this.description = '';
    this.icon = '';
    this.services = [];
    this.isDefault = false;

    this.settings = {
      calculableQuantity: false,
      selectedServiceId: 0,
      quantity: 0,
      comment: '',
      seoKeyword: '',
      keyword: '',
      countries: [],
      languages: [],
      targetableValues: '',

      dripFeedIsEnabled: false,
      dripFeedRuns: 2,
      dripFeedMinutesBetweenRuns: 0,
    };

    if (data) {
      objectAssignByDictionary(this, data, {
        used_as_default_for_order_widget: 'isDefault',
      });

      this.id = Number(this.id);
      this.services = this.services.map((serviceType) => new Service(serviceType));

      if (this.services.length > 0) {
        this.isDefault = Boolean(this.isDefault);
        this.settings.selectedServiceId = this.services[0].id;
        this.settings.quantity = this.services[0].defaultQuantity || this.services[0].min;
        this.settings.calculableQuantity = this.services[0].calculableQuantity;
        this.settings.targetableValues = this.services[0].targets;
      }
    }
  }
}
