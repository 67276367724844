/* eslint-disable no-underscore-dangle */

export default class EventCollector {
  constructor(events) {
    this._waitedEvents = events;
    this._doneEvents = [];
    this._callbacks = [];
    this._isDone = false;
  }

  then(callback) {
    this._callbacks.push(callback);
  }

  event(eventName, payload) {
    if (this._isDone) {
      return;
    }

    this._doneEvents.push({ eventName, payload });
    this._checkAllEventsDone();
  }

  _checkAllEventsDone() {
    const allDone = this._waitedEvents.every(
      (waitedEvent) => this._doneEvents.find(
        (doneEvent) => doneEvent.eventName === waitedEvent,
      ),
    );

    if (allDone) {
      this._done();
    }
  }

  _done() {
    this._isDone = true;
    this._callbacks.forEach((callback) => callback(this._doneEvents));
  }
}
