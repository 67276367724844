<template>
  <Modal v-model="isShown">
    <BonusInfo @close="isShown = !isShown" />
  </Modal>
</template>

<script>
import useStoreBinding from '@/mixins/use-store-binding';
import BonusInfo from '@/components/ModalBonusInfo/BonusInfo.vue';

export default {
  name: 'ModalBonusInfo',
  components: { BonusInfo },
  mixins: [
    useStoreBinding('isShown', 'bonusInfoModal.isShown', 'bonusInfoModal/SET_IS_SHOWN'),
  ],
};
</script>
