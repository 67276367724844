<template>
  <div class="vast-service-order-now-main-button__container">
    <OBtn
      :class="[
        'vast-service-order-now-main-button',
        { 'margin-top': couponInputIsShown }
      ]"
      height="58px"
      :disabled="!isButtonActive"
      @click="createOrder()"
    >
      <div v-if="creatingLoading">
        <OLoader />
        {{ locale.createOrderButtonLoadingText }}
      </div>

      <div v-else>
        {{ locale.createOrderButtonText }}
      </div>
    </OBtn>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  inject: [
    'widgetJSApiInstance',
    'api',
  ],
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
    ...mapGetters([
      'selectedPaymentMethod',
      'selectedServiceTypes',
    ]),
    ...mapState([
      'creatingLoading',
      'email',
    ]),
    ...mapState('url', [
      'url',
    ]),
    canCreateOrder() {
      return [
        this.selectedServiceTypes.length,
        this.selectedPaymentMethod,
        this.email,
        this.url,
      ].every((i) => i);
    },
    isButtonActive() {
      return !this.creatingLoading;
    },
    couponInputIsShown() {
      return this.$store.state.coupons.isShown;
    },
  },
  methods: {
    createOrder() {
      this.$store.dispatch('createOrder')
        .then(() => {
          this.widgetJSApiInstance.dispatchEvent('onGoToPaymentPage');
          this.api.sendgridPaymentPage();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.vast-service-order-now-main-button {
  width: 100%;
  border: 0;
  cursor: pointer;
  color: var(--primary-button-text-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  &[disabled] {
    width: 100%;
    border: 0;
    cursor: default;
  }
}
.vast-service-order-now-main-button__container {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-top: 28px;
  border-radius: 10px;
  & > * {
    width: 100%;
    text-align: center;
    display: block !important;
    border-radius: 10px!important;
    border: 0px!important;
    .field__hint {
      display: none!important;
    }
  }
}

@media (max-width: 1024px) {
  .vast-service-order-now-main-button__container {
    margin-top: 17px;
    width: 296px;
  }
}
</style>
