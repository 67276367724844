<template>
  <div
    :class="[
      'vast-service-order-now-service-item',
      { 'vast-service-order-now-service-item--opened': opened },
    ]">
    <ORadio
      v-model="Value"
      :value="service.id"
    />

    <div
      class="vast-service-order-now-service-item__main"
      @click="opened = !opened"
    >
      <div class="cursor-pointer">
        <div class="vast-service-order-now-service-item__label">
          {{ service.name }}
        </div>

        <div class="vast-service-order-now-service-item__cost">
          {{ cost }} / {{ service.pricingPer }}
        </div>
      </div>

      <ExpandTransition>
        <PackageDescription
          v-if="opened"
          :service="service"
        />
      </ExpandTransition>
    </div>

    <button
      class="vast-service-order-now-service-item__more"
      @click="opened = !opened"
    >
      <span
        :class="[
          'vast-service-order-now-service-item__more-icon',
          {
            'vast-service-order-now-service-item__more-icon--opened': opened,
          }
        ]"
      >
        <IconChevron width="11px" />
      </span>
    </button>
  </div>
</template>

<script>
import IconChevron from '@/components/icons/IconChevron.vue';
import { mapState, mapGetters } from 'vuex';
import Service from '@/classes/Service';
import PackageDescription from './PackageDescription.vue';

export default {
  components: {
    IconChevron,
    PackageDescription,
  },
  props: {
    service: Service,
    value: [Number, String],
  },
  data: () => ({
    opened: false,
  }),
  computed: {
    ...mapState('config', [
      'currency',
      'currencySymbol',
    ]),
    ...mapGetters('config', [
      'locale',
    ]),
    ...mapState('settings', [
      'currencies',
    ]),
    Value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    cost() {
      const cost = this.currencies?.default !== 'USD' && this.currencies?.exchangeRates?.[this.currency]
        ? parseFloat(this.service.price * this.currencies.exchangeRates[this.currency])
          .toFixed(2)
        : this.service.price;
      const currencySymbol = cost !== this.service.price
        ? this.currencySymbol[this.currency]
        : this.currencySymbol.USD;

      return `${currencySymbol} ${cost}`;
    },
  },
  mounted() {
    this.opened = this.Value === this.service.id;
  },
};
</script>

<style lang="scss" scoped>
.vast-service-order-now-service-item {
  padding: 8px 12px;
  display: flex;

  &__main {
    width: 100%;
    margin-left: 9px;
  }

  &__label {
    margin-top: 5px;
    color: var(--modal-text-color);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  &__cost {
    margin-top: 8px;
    color: var(--modal-text-color);
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
  }

  &__more {
    font-size: 12px;
    display: inline-block;
    background: 0;
    border: 0;
    cursor: pointer;
    transition: all .4s;
    outline: none;
    flex-shrink: 0;
    width: 30px;
    border-radius: 3px;
    color: var(--modal-text-color);
  }

  &__more-icon {
    display: block;
    transition: all .4s;

    &--opened {
      transform: rotate(180deg);
    }
  }

  &--opened {
    border-radius: 6px;
    background-color: var(--input-background-color);
    color: var(--input-text-color);
    .vast-service-order-now-service-item {
      &__label, &__cost, &__more {
        color: var(--input-text-color);
      }
    }
  }
}
</style>
