<template>
  <div class="summary-and-commission">
    <OLabel>
      {{ locale.orderSummary }}
    </OLabel>

    <div class="summary-and-commission__main-block">
      <Summary />
    </div>

    <div  class="summary-and-commission__commission">
      <Commission />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Commission from '@/components/Commission.vue';
import Summary from './Summary.vue';

export default {
  components: {
    Commission,
    Summary,
  },
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.summary-and-commission {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 58px;

  &__main-block {
    background: var(--input-background-color);
    padding: 12px 20px;
    border-radius: 12px;
  }

  &__commission {
    margin-top: 8px;
  }
}

@media (max-width: 768px) {
  .summary-and-commission {
    margin-bottom: 0px;
  }
}
</style>
