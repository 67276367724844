<template>
  <div>
    <br>
    <div class="block-title">
      {{ translations.name }}
    </div>

    <o-input
      v-model="inputValue"
      :type="typeInput"
      :placeholder="translations.placeholder"
      :errors="errors[`${serviceType.id}.targetable_values`]
      || errors[`${serviceType.id}.targetable_values.${targetableValueIndex}.values`]"
      @focus="clearErrors"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ServiceType from '@/classes/ServiceType';

export default {
  props: {
    target: {
      type: Object,
      required: true,
    },
    serviceType: ServiceType,
  },
  computed: {
    ...mapState([
      'errors',
    ]),
    ...mapState('config', [
      'language',
    ]),
    ...mapGetters('config', [
      'locale',
    ]),
    inputValue: {
      get() {
        return this.serviceType.settings.targetableValues[this.targetableValueIndex].values.join(' ');
      },
      set(val) {
        if (val.length === 0) {
          this.targetableValues = [];
        } else {
          this.targetableValues = this.number
            ? [this.handleInput(val)]
            : [val];
        }
      },
    },
    targetableValues: {
      get() {
        return this.serviceType.settings.targetableValues[this.targetableValueIndex].values;
      },
      set(val) {
        this.serviceType.settings.targetableValues[this.targetableValueIndex].values = val;
      },
    },
    targetableValueIndex() {
      return this.serviceType.settings.targetableValues.findIndex((target) => (
        target.targetId === this.target.id
      ));
    },
    typeInput() {
      return this.target?.type?.viewType === 'input' || this.target?.type?.viewType === 'url_input'
        ? 'text' : 'number';
    },
    number() {
      return this.target?.type?.viewType === 'number_input';
    },
    translations() {
      return this.target.translations[this.language] || this.target.translations.en;
    },
    viewType() {
      return this.target?.type?.viewType;
    },
  },
  methods: {
    clearErrors() {
      this.$emit('clear-errors');
    },
    handleInput(value) {
      return value.replace(/\D/g, '');
    },
  },
};
</script>
<style lang="scss" scoped>
.block-title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}
</style>
