export default {
  en: {
    settingsModalTitle: 'Do you want to exit settings without saving?',
    settingsModalSuccess: 'Save',
    settingsModalRefusal: 'Exit without saving',
    title: 'Instant order',
    networksTitle: '1. Select platform',
    urlLabel: '2. Paste URL',
    urlLabelWithoutNetworks: '1. Paste URL',
    back: 'Back',
    yourEmail: 'Your email',
    creditCardPaymentMethodLabel: 'Credit card',
    yandexMoneyPaymentMethodLabel: 'Yandex Money',
    orderSummary: 'Order summary',
    selectPaymentMethod: 'Payment method',
    createOrderButtonText: 'Order',
    createOrderButtonInfo: '*minimum order amount',
    createOrderButtonLoadingText: 'Creating order...',
    loadingText: 'Loading...',
    countrySearchPlaceholder: 'Find country',
    showOnlySelectedCountriesCheckboxText: 'Show only selected countries',
    allCountriesCheck: 'All countries',
    emptyPaymentMethod: 'There are no available payment methods. Please contact administrator.',
    qrCodeText: 'Please scan the QR code by the application of the selected payment system',
    qrCode: 'QR code',
    closeModalBtnText: 'Close',
    manualPayment: 'Manual payment',
    manualTransferToAccountText: 'Please transfer money to the account: ',
    manualCommentText: 'and indicate the following in the comment to the transfer: ',
    manualIPaid: 'I\'ve paid',
    manualDone: 'We have accepted your request.',
    selectService: '3. Select service type',
    selectServiceWithoutNetworks: '2. Select service type',
    noServices: 'No available services',
    qty: 'QTY',
    settings: 'Settings',
    likesQuantity: 'Likes quantity:',
    service: 'Service',
    useGeoTargeting: 'use geo targeting',
    quantity: 'Quantity:',
    subscribersQuantity: 'Subscribers quantity:',
    '24hoursStart': '0 - 24 hours start',
    instantStart: 'Instant start',
    retention30: 'Retention 30+ sec (0-24 hours start)',
    retention50: 'Retention 50+ sec (0 - 24 hours start)',
    upTo200k: 'Up to 200K/D Instant Start & Lifetime',
    viewsGeoTargeting: 'Views geo targeting',
    viewsLanguagesTargeting: 'Languages targeting',
    noCountriesSelected: 'No countries selected',
    noLanguagesSelected: 'No language selected',
    noCountriesFound: 'No countries found',
    noLanguagesFound: 'No languages found',
    selectCountries: 'Select countries',
    manageCountries: 'Manage countries',
    selectLanguages: 'Select languages',
    manageLanguages: 'Manage languages',
    searchCountries: 'Search countries',
    searchLanguages: 'Search languages',
    geoTargetingDisabled: 'Geo targeting is not available for selected option',
    alertTargets: 'Add information in settings',
    targetInfoCalculateQuantity: 'One line automatically adds +1 to quantity',
    serviceInactiveForUrl: 'Service is not available for the specified URL',
    lessThenMinimalQty: 'Quantity is less than the minimum',
    moreThenMaximumQty: 'Quantity is greater than the maximum',
    serviceError: 'Incorrect data specified',
    noAvailableServices: 'No services of this type are available.',
    or: 'or',
    allCountries: 'All countries',
    allLanguages: 'All languages',
    onlySelectedCountries: 'Selected',
    onlySelectedLanguages: 'Selected',
    commissionOfSelectedPS: 'Commission of selected payment system',
    yourBonus: 'Your order bonus',
    bonusInfo: 'Bonus info',
    bonusInfoText: 'After successful purchase you can use your bonus in your account. Login details will be available in your email.',
    fromAmount: 'From amount',
    bonusPercent: 'Bonus percent',
    youWillGet: 'You\'ll get',
    save: 'Save',
    couponCode: 'Coupon code',
    couponLabel: 'Coupon code',
    couponBonus: 'Bonus',
    couponBonusDiscount: 'Discount:',
    haveABonusCouponCode: 'Do you have a bonus coupon code?',
    couponAccepted: 'Coupon accepted',
    paymentSystemBonus: 'Payment system bonus',
    enableDripFeed: 'Enable drip-feed',
    numberOfRuns: 'Number of runs',
    dripFeedIntervalTitle: 'Interval between runs',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    price: 'Price:',
    comment: 'Comment',
    keywords: 'Keywords',
    seoKeywords: 'Keywords',
    languages: 'Languages',
    oneCommentPerLine: 'One comment per line or separated by a semicolon',
    oneSeoKeywordPerLine: 'Keywords separated by comma, semicolon or line break',
    networkIsChangeFromUrlBefore: 'Selected network has been switched to',
    networkIsChangeFromUrlAfter: '',
    urlInformation: 'The link defines the available service types',
    showMore: 'Show more',
    hide: 'Hide',
    emptyServicesModalTitle: 'You haven’t selected any services for promotion',
    emptyServicesModalButton: 'Choose a service',
    packgeDescriptionTitle: 'This package includes:',
  },
  ru: {
    title: 'Быстрый заказ',
    networksTitle: '1. Выберите платформу',
    urlLabel: '2. Добавьте ссылку',
    urlLabelWithoutNetworks: '1. Добавьте ссылку',
    back: 'Назад',
    yourEmail: 'Введите email',
    creditCardPaymentMethodLabel: 'Кредитная карта',
    yandexMoneyPaymentMethodLabel: 'Yandex Деньги',
    orderSummary: 'Сумма заказа',
    selectPaymentMethod: 'Способ оплаты',
    createOrderButtonText: 'Заказать',
    createOrderButtonInfo: '*минимальная сумма заказа',
    createOrderButtonLoadingText: 'Создание заказа...',
    loadingText: 'Загрузка...',
    countrySearchPlaceholder: 'Поиск страны',
    showOnlySelectedCountriesCheckboxText: 'Показать только выбранные страны',
    allCountriesCheck: 'Все страны',
    emptyPaymentMethod: 'Нет доступных способов оплаты. Пожалуйста, свяжитесь с администратором.',
    qrCodeText: 'Пожалуйста, отсканируйте QR-код приложением выбранной платежной системы',
    qrCode: 'QR-код',
    closeModalBtnText: 'Закрыть',
    manualPayment: 'Ручная оплата',
    manualTransferToAccountText: 'Пожалуйста, переведите деньги на счет: ',
    manualCommentText: 'Укажите следующий текст в комментарии к платежу: ',
    manualIPaid: 'Я оплатил',
    manualDone: 'Мы приняли вашу заявку.',
    selectService: '3. Выберите тип сервиса',
    selectServiceWithoutNetworks: '2. Выберите тип сервиса',
    noServices: 'Нет доступных сервисов',
    qty: 'Кол-во',
    settings: 'Настроить',
    service: 'Сервис',
    useGeoTargeting: 'использовать геотаргетинг',
    quantity: 'Количество',
    '24hoursStart': 'старт в течении 0 - 24 часов',
    instantStart: 'Мгновенный старт',
    retention30: 'Retention 30+ sec (0-24 hours start)',
    retention50: 'Retention 50+ sec (0 - 24 hours start)',
    upTo200k: 'Up to 200K/D Instant Start & Lifetime',
    viewsGeoTargeting: 'Геотаргетинг просмотров',
    viewsLanguagesTargeting: 'Языковой таргетинг',
    noCountriesSelected: 'Нет выбранных стран',
    noLanguagesSelected: 'Нет выбранных языков',
    noCountriesFound: 'Нет найденных стран',
    noLanguagesFound: 'Нет найденных языков',
    selectCountries: 'Выбрать страны',
    manageCountries: 'Настроить страны',
    selectLanguages: 'Выбрать языки',
    manageLanguages: 'Настроить языки',
    searchCountries: 'Поиск по странам',
    searchLanguages: 'Поиск по языкам',
    geoTargetingDisabled: 'Геотаргетинг недоступен для выбранной опции',
    alertTargets: 'Добавьте информацию в настройках',
    targetInfoCalculateQuantity: 'Одна строка автоматически добавляет +1 к количеству',
    serviceInactiveForUrl: 'Сервис недоступен для указанного URL',
    lessThenMinimalQty: 'Количество меньше минимально допустимого',
    moreThenMaximumQty: 'Количество больше максимально допустимого',
    serviceError: 'Указаны некорректные данные',
    noAvailableServices: 'Нет доступных сервисов этого типа',
    or: 'или',
    allCountries: 'Все страны',
    allLanguages: 'Все языки',
    onlySelectedCountries: 'Выбранные',
    onlySelectedLanguages: 'Выбранные',
    commissionOfSelectedPS: 'Комиссия выбранной платежной системы',
    yourBonus: 'Бонус за ваш заказ',
    bonusInfo: 'Информация по бонусу',
    bonusInfoText: 'После успешной покупки вы сможете использовать ваш бонус в личном кабинете. Данные для входа будут отправлены вам на электронную почту.',
    fromAmount: 'От суммы',
    bonusPercent: '% на бонус',
    youWillGet: 'Вы получите',
    save: 'Сохранить',
    settingsModalTitle: 'Хотите выйти из настроек без сохранения?',
    settingsModalSuccess: 'Сохранить',
    settingsModalRefusal: 'Выйти без сохранения',
    couponCode: 'Купон код',
    couponLabel: 'Купон-код',
    couponBonus: 'Бонус',
    couponBonusDiscount: 'Скидка:',
    haveABonusCouponCode: 'У вас есть купон код?',
    couponAccepted: 'Купон принят',
    paymentSystemBonus: 'Бонус платежной системы',
    enableDripFeed: 'Включить drip-feed',
    dripFeedFirstInfo: 'Позволяет осуществлять накрутку в несколько этапов через заданные промежутки времени',
    dripFeedSecondInfo: 'Все запланированное количество будет равномерно распределенно между запусками',
    numberOfRuns: 'Кол-во запусков',
    dripFeedIntervalTitle: 'Интервал между запусками',
    days: 'Дни',
    hours: 'Часы',
    minutes: 'Минуты',
    price: 'Стоимость:',
    comment: 'Комментарий',
    keywords: 'Ключевые слова',
    seoKeywords: 'Ключевые слова',
    languages: 'Языки',
    oneCommentPerLine: 'Один комментарий в строке или через точку с запятой',
    oneSeoKeywordPerLine: 'Ключевые слова, разделённые запятой, точкой с запятой или переносом строки',
    networkIsChangeFromUrlBefore: 'Выбранная платформа изменилась на',
    networkIsChangeFromUrlAfter: '',
    urlInformation: 'Ссылка определяет доступные типы сервисов',
    showMore: 'Показать больше',
    hide: 'Свернуть',
    emptyServicesModalTitle: 'Вы не выбрали сервисы для продвижения',
    emptyServicesModalButton: 'Выбрать сервис',
    packgeDescriptionTitle: 'Этот пакет включает:',
  },
  es: {
    settingsModalTitle: '¿Quieres salir de la configuración sin guardar?',
    settingsModalSuccess: 'Sauvegarder',
    settingsModalRefusal: 'Salir sin guardar',
    title: 'Orden instantánea',
    networksTitle: '1. Seleccionar plataforma',
    urlLabel: '2. Agregar un enlace',
    urlLabelWithoutNetworks: '1. Agregar un enlace',
    back: 'atras',
    yourEmail: 'Ingrese su correo electrónico',
    creditCardPaymentMethodLabel: 'Tarjeta de crédito',
    yandexMoneyPaymentMethodLabel: 'Yandex Money',
    orderSummary: 'Cantidad del pedido',
    selectPaymentMethod: 'Método de pago',
    createOrderButtonText: 'Orden',
    createOrderButtonInfo: '*cantidad mínima de pedido',
    createOrderButtonLoadingText: 'Creando un pedido ...',
    loadingText: 'Cargando ...',
    countrySearchPlaceholder: 'Búsqueda de país',
    showOnlySelectedCountriesCheckboxText: 'Mostrar solo países seleccionados',
    allCountriesCheck: 'Todos los países',
    emptyPaymentMethod: 'No hay métodos de pago disponibles. Por favor, póngase en contacto con el administrador. ',
    qrCodeText: 'Escanee el código QR con la aplicación del sistema de pago seleccionado',
    qrCode: 'código QR',
    closeModalBtnText: 'Cerrar',
    manualPayment: 'Pago manual',
    manualTransferToAccountText: 'Transfiera dinero a la cuenta:',
    manualCommentText: 'Indique el siguiente texto en el comentario de pago:',
    manualIPaid: 'Pagué',
    manualDone: 'Hemos aceptado su solicitud',
    selectService: '3. Seleccionar tipo de servicio',
    selectServiceWithoutNetworks: '2. Seleccionar tipo de servicio',
    noServices: 'No hay servicios disponibles',
    qty: 'Cantidad',
    settings: 'Configurar',
    service: 'Servicio',
    useGeoTargeting: 'usar orientación geográfica',
    quantity: 'cantidad',
    '24hoursStart': 'comienza dentro de 0 - 24 horas',
    instantStart: 'Instant Start',
    retention30: 'Retención 30+ segundos (0-24 horas de inicio)',
    retention50: 'Retención 50+ segundos (0 - 24 horas de inicio)',
    upTo200k: 'Hasta 200K / D Inicio instantáneo y vida útil',
    viewsGeoTargeting: 'Vistas de orientación geográfica',
    viewsLanguagesTargeting: 'Segmentación por idiomas',
    noCountriesSelected: 'No hay países seleccionados',
    noLanguagesSelected: 'Ningún idioma seleccionado',
    noCountriesFound: 'No se encontraron países',
    noLanguagesFound: 'No se encontraron idiomas',
    selectCountries: 'Seleccionar países',
    manageCountries: 'Configurar países',
    selectLanguages: 'Seleccionar idiomas',
    manageLanguages: 'Administrar idiomas',
    searchCountries: 'Buscar por país',
    searchLanguages: 'Idiomas de búsqueda',
    geoTargetingDisabled: 'La orientación geográfica no está disponible para la opción seleccionada',
    alertTargets: 'Agregar información en la configuración',
    targetInfoCalculateQuantity: 'Una línea agrega automáticamente +1 a la cantidad',
    serviceInactiveForUrl: 'El servicio no está disponible para la URL especificada',
    lessThenMinimalQty: 'La cantidad es menor que el mínimo permitido',
    moreThenMaximumQty: 'La cantidad es mayor que el máximo permitido',
    serviceError: 'Datos no válidos especificados',
    noAvailableServices: 'No hay servicios de este tipo disponibles.',
    or: 'o',
    allCountries: 'Todos los países',
    allLanguages: 'Todos los idiomas',
    onlySelectedCountries: 'Seleccionado',
    onlySelectedLanguages: 'Seleccionado',
    commissionOfSelectedPS: 'Comisión del sistema de pago seleccionado',
    yourBonus: 'Su bono de pedido',
    bonusInfo: 'Información adicional',
    bonusInfoText: 'Después de una compra exitosa, puede usar su bono en su cuenta. Los detalles de inicio de sesión estarán disponibles en su correo electrónico.',
    fromAmount: 'De cantidad',
    bonusPercent: 'Porcentaje de bonificación',
    youWillGet: 'Usted obtendrá',
    save: 'Ahorrar',
    couponCode: 'Cupón',
    couponLabel: 'Cupón',
    couponBonus: 'Bono de cupón',
    couponBonusDiscount: 'Descuento:',
    haveABonusCouponCode: '¿Tiene un código de cupón de bonificación?',
    couponAccepted: 'Cupón aceptado',
    paymentSystemBonus: 'Bonificación del sistema',
    enableDripFeed: 'Habilitar drip-feed',
    numberOfRuns: 'Numero de carreras',
    dripFeedIntervalTitle: 'Intervalo entre carreras',
    days: 'Dias',
    hours: 'Horas',
    minutes: 'Minutos',
    price: 'Precio:',
    comment: 'Сomentario',
    keywords: 'Palabras clave',
    seoKeywords: 'Palabras clave',
    languages: 'Idiomas',
    oneCommentPerLine: 'Un comentario por línea o separado por punto y coma',
    oneSeoKeywordPerLine: 'Palabras clave separadas por coma, punto y coma o salto de línea',
    networkIsChangeFromUrlBefore: 'La red seleccionada se ha cambiado a',
    networkIsChangeFromUrlAfter: '',
    urlInformation: 'El enlace define los tipos de servicios disponibles.',
    showMore: 'Mostrar más',
    hide: 'Hise',
    packgeDescriptionTitle: 'Este paquete incluye:',
  },
  de: {
    settingsModalTitle: 'Möchten Sie die Einstellungen ohne Speichern verlassen?',
    settingsModalSuccess: 'Speichern',
    settingsModalRefusal: 'Beenden ohne Speichern',
    title: 'Pedido sin registro',
    networksTitle: '1. Plattform auswählen',
    urlLabel: '2. Fügen Sie einen Link hinzu',
    urlLabelWithoutNetworks: '1. Fügen Sie einen Link hinzu',
    back: 'zurück',
    yourEmail: 'Geben Sie Ihre E-Mail-Adresse ein',
    creditCardPaymentMethodLabel: 'Kreditkarte',
    yandexMoneyPaymentMethodLabel: 'Yandex Money',
    orderSummary: 'Bestellbetrag',
    selectPaymentMethod: 'Bezahlverfahren',
    createOrderButtonText: 'Befehl',
    createOrderButtonInfo: '*Mindestbestellmenge',
    createOrderButtonLoadingText: 'Bestellung erstellen ...',
    loadingText: 'Loading...',
    countrySearchPlaceholder: 'Ländersuche',
    showOnlySelectedCountriesCheckboxText: 'Nur ausgewählte Länder anzeigen',
    allCountriesCheck: 'Alle Länder',
    emptyPaymentMethod: 'Keine Zahlungsmethoden verfügbar. Bitte wenden Sie sich an den Administrator. ',
    qrCodeText: 'Bitte scannen Sie den QR-Code mit der Anwendung des ausgewählten Zahlungssystems',
    qrCode: 'QR-Code',
    closeModalBtnText: 'Schließen',
    manualPayment: 'Manuelle Zahlung',
    manualTransferToAccountText: 'Bitte überweisen Sie Geld auf das Konto:',
    manualCommentText: 'Geben Sie im Zahlungskommentar den folgenden Text an:',
    manualIPaid: 'Ich habe bezahlt',
    manualDone: 'Wir haben Ihre Bewerbung angenommen.',
    selectService: '3. Wählen Sie den Servicetyp aus',
    selectServiceWithoutNetworks: '2. Wählen Sie den Servicetyp aus',
    noServices: 'Keine Dienste verfügbar',
    qty: 'Menge',
    settings: 'Konfigurieren',
    service: 'Service',
    useGeoTargeting: 'Standort-Targeting verwenden',
    quantity: 'Menge',
    '24hoursStart': 'Start innerhalb von 0 - 24 Stunden',
    instantStart: 'Sofortstart',
    retention30: 'Retention 30+ Sek. (Beginn 0-24 Stunden)',
    retention50: 'Retention 50+ Sek. (0 - 24 Stunden Start)',
    upTo200k: 'Sofortstart und Lebensdauer von bis zu 200K / D',
    viewsGeoTargeting: 'Ansichten Geo-Targeting',
    viewsLanguagesTargeting: 'Ausrichtung auf Sprachen',
    noCountriesSelected: 'Keine ausgewählten Länder',
    noLanguagesSelected: 'Keine Sprache ausgewählt',
    noCountriesFound: 'Keine Länder gefunden',
    noLanguagesFound: 'Keine Sprachen gefunden',
    selectCountries: 'Länder auswählen',
    manageCountries: 'Länder anpassen',
    selectLanguages: 'Sprachen auswählen',
    manageLanguages: 'Sprachen verwalten',
    searchCountries: 'Suche nach Land',
    searchLanguages: 'Sprachen suchen',
    geoTargetingDisabled: 'Geo-Targeting ist für die ausgewählte Option nicht verfügbar',
    alertTargets: 'Fügen Sie Informationen in den Einstellungen hinzu',
    targetInfoCalculateQuantity: 'Eine Zeile addiert automatisch +1 zur Menge',
    serviceInactiveForUrl: 'Dienst ist für die angegebene URL nicht verfügbar',
    lessThenMinimalQty: 'Menge ist kleiner als das zulässige Minimum',
    moreThenMaximumQty: 'Menge ist größer als das maximal zulässige',
    serviceError: 'Ungültige Daten angegeben',
    noAvailableServices: 'Es sind keine Dienste dieses Typs verfügbar.',
    or: 'oder',
    allCountries: 'Alle Länder',
    allLanguages: 'Alle Sprachen',
    onlySelectedCountries: 'Selected',
    onlySelectedLanguages: 'Selected',
    commissionOfSelectedPS: 'Kommission des ausgewählten Zahlungssystems',
    yourBonus: 'Ihr Bestellbonus',
    bonusInfo: 'Bonusinfo',
    bonusInfoText: 'Nach dem erfolgreichen Kauf können Sie Ihren Bonus auf Ihrem Konto verwenden. Die Anmeldedaten finden Sie in Ihrer E-Mai',
    fromAmount: 'von Menge',
    bonusPercent: 'Bonus Prozent',
    youWillGet: 'Du wirst kriegen',
    save: 'Speichern',
    couponCode: 'Gutscheincode',
    couponLabel: 'Gutscheincode',
    couponBonus: 'Gutscheinbonus',
    couponBonusDiscount: 'Rabatt:',
    haveABonusCouponCode: 'Haben Sie einen Bonus-Gutscheincode?',
    couponAccepted: 'Gutschein akzeptiert',
    paymentSystemBonus: 'Zahlungssystem Bonus',
    enableDripFeed: 'Activer drip-feed',
    numberOfRuns: 'Nombre de courses',
    dripFeedIntervalTitle: 'Intervalle entre les courses',
    days: 'Journées',
    hours: 'Heures',
    minutes: 'Minutes',
    price: 'Preis:',
    comment: 'Kommentar',
    keywords: 'Schlüsselwörter',
    seoKeywords: 'Schlüsselwörter',
    languages: 'Sprachen',
    oneCommentPerLine: 'Ein Kommentar pro Zeile oder durch Semikolon getrennt',
    oneSeoKeywordPerLine: 'Schlüsselwörter durch Komma, Semikolon oder Zeilenumbruch getrennt',
    networkIsChangeFromUrlBefore: 'Das ausgewählte Netzwerk wurde auf',
    networkIsChangeFromUrlAfter: 'umgestellt',
    urlInformation: 'Der Link definiert die verfügbaren Servicetypen',
    showMore: 'Zeig mehr',
    hide: 'Hise',
    packgeDescriptionTitle: 'Dieses Paket beinhaltet:',
  },
  fr: {
    settingsModalTitle: 'Voulez-vous quitter les paramètres sans enregistrer ?',
    settingsModalSuccess: 'Ahorrar',
    settingsModalRefusal: 'Quitter sans sauvegarder',
    title: ' Commande instantanée',
    networksTitle: '1. Sélectionnez la plateforme',
    urlLabel: '2. Ajouter un lien',
    urlLabelWithoutNetworks: '1. Ajouter un lien',
    back: 'Arrière',
    yourEmail: 'Votre email',
    creditCardPaymentMethodLabel: 'Carte bancaire',
    yandexMoneyPaymentMethodLabel: 'Yandex Money',
    orderSummary: 'Récapitulatif de la commande',
    selectPaymentMethod: 'Mode de paiement',
    createOrderButtonText: 'Commande',
    createOrderButtonInfo: '*montant minimum de commande',
    createOrderButtonLoadingText: 'Création d’une commande...',
    loadingText: 'Chargement...',
    countrySearchPlaceholder: 'Recherche d’un pays',
    showOnlySelectedCountriesCheckboxText: 'Afficher uniquement les pays sélectionnés',
    allCountriesCheck: 'Tous les pays',
    emptyPaymentMethod: 'Il n\'y a pas de méthodes de paiement disponibles. Veuillez contacter l\'administrateur',
    qrCodeText: 'Veuillez scanner le code QR par l\'application du système de paiement sélectionné',
    qrCode: 'QR code',
    closeModalBtnText: 'Fermer',
    manualPayment: 'Paiement manuel',
    manualTransferToAccountText: 'Veuillez transférer de l\'argent sur le compte: ',
    manualCommentText: 'et indiquez ce qui suit dans le commentaire du transfert: ',
    manualIPaid: 'j\'ai payé',
    manualDone: 'Nous avons accepté votre demande.',
    selectService: '3. Sélectionnez le type de service',
    selectServiceWithoutNetworks: '2. Sélectionnez le type de service',
    noServices: 'Aucun service disponible',
    qty: 'QTY',
    settings: 'Paramètres',
    likesQuantity: 'Quantité de j\'aime:',
    service: 'Service',
    useGeoTargeting: 'utilisez le ciblage géographique',
    quantity: 'Quantité:',
    subscribersQuantity: 'Quantité d\'abonnés:',
    '24hoursStart': '0-24 heures début',
    instantStart: 'Démarrage instantané',
    retention30: 'Rétention 30+ s (0-24 heures début)',
    retention50: 'Rétention 50+ s (0 - 24 heures début)',
    upTo200k: 'Jusqu\'à 200K / D Instant Start & Lifetime',
    viewsGeoTargeting: 'Ciblage géographique des vues',
    viewsLanguagesTargeting: 'Ciblage linguistique',
    noCountriesSelected: 'Aucun pays sélectionné',
    noLanguagesSelected: 'Aucune langue sélectionnée',
    noCountriesFound: 'Aucun pays trouvé',
    noLanguagesFound: 'Aucune langue trouvée',
    selectCountries: 'Sélectionnez les pays',
    manageCountries: 'Gérez les pays',
    selectLanguages: 'Sélectionnez les langues',
    manageLanguages: 'Gérez les langues',
    searchCountries: 'Recherche par pays',
    searchLanguages: 'Langues de recherche',
    geoTargetingDisabled: 'Le service de géolocalisation n’est pas disponible pour l’URL spécifiée',
    alertTargets: 'Ajouter des informations dans les paramètres',
    targetInfoCalculateQuantity: 'Une ligne ajoute automatiquement +1 à la quantité',
    serviceInactiveForUrl: 'Le service n’est pas disponible pour l’URL spécifiée',
    lessThenMinimalQty: ' La quantité est inférieure au minimum',
    moreThenMaximumQty: 'La quantité est supérieure au maximum',
    serviceError: 'Données incorrectes spécifiées',
    noAvailableServices: 'Aucun service de ce type n\'est disponible.',
    or: 'ou',
    allCountries: 'Tous les pays',
    allLanguages: 'Toutes les langues',
    onlySelectedCountries: 'Sélectionnés',
    onlySelectedLanguages: 'Sélectionnés',
    commissionOfSelectedPS: 'Commission du système de paiement sélectionné',
    yourBonus: 'Votre bonus de commande',
    bonusInfo: 'Info bonus',
    bonusInfoText: 'Après un achat réussi, vous pouvez utiliser votre bonus dans votre compte. L’information de connection sera disponible dans votre e-mail.',
    fromAmount: 'De quantité',
    bonusPercent: 'Pourcentage de bonus',
    youWillGet: 'Tu auras',
    save: 'Sauvegarder',
    couponCode: 'Code de coupon',
    couponLabel: 'Coupon',
    couponBonus: 'Bonus de coupon',
    couponBonusDiscount: 'Rabais:',
    haveABonusCouponCode: 'Avez-vous un code de coupon bonus?',
    couponAccepted: 'Coupon accepté',
    paymentSystemBonus: 'Bonus du système de paiement',
    enableDripFeed: 'Aktivieren Sie drip-feed',
    numberOfRuns: 'Anzahl der Läufe',
    dripFeedIntervalTitle: 'Intervall zwischen den Läufen',
    days: 'Tage',
    hours: 'Std',
    minutes: 'Protokoll',
    price: 'Prix:',
    comment: 'Commenter',
    keywords: 'Mots clés',
    seoKeywords: 'Mots clés',
    languages: 'Langages',
    oneCommentPerLine: 'Un commentaire par ligne ou séparé par un point virgule',
    oneSeoKeywordPerLine: 'Mots-clés séparés par une virgule, un point-virgule ou un saut de ligne',
    networkIsChangeFromUrlBefore: 'Le réseau sélectionné a été basculé sur',
    networkIsChangeFromUrlAfter: '',
    urlInformation: 'Le lien définit les types de services disponibles',
    showMore: 'Montre plus',
    hide: 'Son',
    packgeDescriptionTitle: 'Ce forfait comprend:',
  },
};
