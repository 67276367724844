import Analytics from './Analytics';

export default class SettingsWidget {
  constructor(data) {
    this.analytics = new Analytics(data.analytics);
    this.minimumPurchaseAmount = parseFloat(data.minimum_purchase_amount || 5).toFixed(2);
    this.currencies = data.currencies
      ? {
        default: data.currencies.default,
        exchangeRates: data.currencies.exchange_rates
          ? {
            USD: data.currencies.exchange_rates.USD || null,
            RUB: data.currencies.exchange_rates.RUB || null,
          }
          : null,
      }
      : {};
  }
}
