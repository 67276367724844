import createTranslations from '@/classes/createTranslations';

export default class TargetAttachment {
  constructor(data) {
    this.id = Number(data.id);
    this.name = data.name;
    this.placeholder = data.placeholder;
    this.type = {
      enabledForService: data.target_type.enabled_for_service,
      id: data.target_type.id,
      name: data.target_type.name,
      selectable: data.target_type.selectable,
      title: data.target_type.title,
      viewType: data.target_type.view_type,
    };
    this.selectables = data.selectable_values || [];
    this.translations = createTranslations(data.translations) || {};
  }
}
