<template>
  <button
    class="vast-service-order-now-selected-payment-method"
    @click="$emit('click')"
  >
    <div class="vast-service-order-now-selected-payment-method__left">
      <OLoader v-if="loading" />

      <template v-else-if="selectedPaymentMethod">
        <div class="vast-service-order-now-selected-payment-method__img-wrapper">
          <img
            class="vast-service-order-now-selected-payment-method__img"
            :src="selectedPaymentMethod.icon"
            :alt="selectedPaymentMethod.name"
          >
        </div>

        <div class="vast-service-order-now-selected-payment-method__name">
          {{ selectedPaymentMethod.name }}
        </div>
      </template>

      <div v-if="paymentMethods.length === 0 && !loading">
        {{ locale.emptyPaymentMethod }}
      </div>
    </div>

    <div class="vast-service-order-now-selected-payment-method__right">
      <IconChevron width="11px" />
    </div>
  </button>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import IconChevron from '@/components/icons/IconChevron.vue';

export default {
  components: {
    IconChevron,
  },
  computed: {
    ...mapState('paymentMethods', [
      'paymentMethods',
      'loading',
    ]),
    ...mapGetters([
      'selectedPaymentMethod',
    ]),
    ...mapGetters('config', [
      'locale',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.vast-service-order-now-selected-payment-method {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 58px;
  padding: 12px 20px;
  cursor: pointer;
  background: var(--input-background-color);
  border: 0px;
  border-radius: 12px;
  color: var(--input-text-color);

  &__left {
    display: flex;
    align-items: center;
  }

  &__img-wrapper {
    height: 29px;
    margin-right: 8px;
  }

  &__img {
    height: 100%;
  }

  &__name {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  &__right {
    transform: rotate(-90deg);
  }
}

</style>
