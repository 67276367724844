<template>
  <ModalContent>
    <ModalBody>
      <div class="vast-service-order-empty-services">
        <p class="vast-service-order-empty-services__title">
          {{ locale.emptyServicesModalTitle }}
        </p>

        <div class="vast-service-order-empty-services__button">
          <OBtn @click="close">
            {{ locale.emptyServicesModalButton }}
          </OBtn>
        </div>
      </div>
    </ModalBody>
  </ModalContent>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'EmptyServices',
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.vast-service-order-empty-services {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  &__title {
    margin: 0px;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  &__button {
    margin-top: 24px;
    border-radius: 10px;
    & > * {
      width: 100%;
      text-align: center;
      display: block !important;
      background-color: var(--primary-button-color);
      color: var(--primary-button-text-color);
      border-radius: 10px!important;
      border: 0px!important;
    }
  }
}
</style>
