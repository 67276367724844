<template>
  <div class="vast-payment-methods">
    <PaymentMethodsSelectModal />

    <OLabel>
      {{ locale.selectPaymentMethod }}
    </OLabel>

    <SelectedPaymentMethod
      @click="openSelectModal()"
    />

    <div
      v-if="paymentMethodBonus && paymentMethodBonus.percent"
      class="vast-bonus-and-coupon__bonus-summary-section__payment"
    >
      <span class="vast-payment-methods__bonus-title">
        {{ locale.paymentSystemBonus }}
      </span>

      <div class="vast-payment-methods__bonus-container">
        <span class="vast-payment-methods__bonus">
          {{paymentMethodBonus.value}} ({{ paymentMethodBonus.percent * 100 }}%)
        </span>

        <div
          class="vast-bonus-and-coupon__bonus-section__button"
          @click="showModal"
        >
          <IconBonusPaymentMethod />
        </div>
      </div>
      <modal-bonus-info />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ModalBonusInfo from '@/components/ModalBonusInfo/Index.vue';
import IconBonusPaymentMethod from '@/components/icons/IconBonusPaymentMethod.vue';
import SelectedPaymentMethod from './SelectedPaymentMethod.vue';
import PaymentMethodsSelectModal from '../PaymentMethodSelectModal/Index.vue';

export default {
  components: {
    SelectedPaymentMethod,
    PaymentMethodsSelectModal,
    ModalBonusInfo,
    IconBonusPaymentMethod,
  },
  watch: {
    paymentMethods() {
      const [firstPaymentMethod] = this.paymentMethods;

      if (firstPaymentMethod) {
        this.$store.commit('SET_SELECTED_PAYMENT_METHOD_ID', firstPaymentMethod.id);
        this.$store.dispatch('costs/calculateTotalCost');
      }
    },
  },
  computed: {
    ...mapState('paymentMethods', [
      'paymentMethods',
      'loading',
    ]),
    ...mapGetters('config', [
      'locale',
    ]),
    ...mapState('costs', [
      'paymentMethodBonus',
    ]),
  },
  methods: {
    openSelectModal() {
      this.$store.commit('paymentMethods/SET_IS_MODAL_SHOWN', true);
    },
    showModal() {
      this.$store.commit('bonusInfoModal/SET_IS_SHOWN', true);
    },
  },
};
</script>

<style lang="scss" scoped>
.vast-payment-methods {
  width: 100%;

  @media (max-width: 800px) {
    margin-right: 0;
  }
  &__bonus-title {
    color: var(--text-color);
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
  &__bonus {
    color: var(--text-color);
    margin-right: 12px;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
  &__bonus-container {
    display: flex;
    align-items: center;
  }
}
.vast-bonus-and-coupon__bonus-summary-section__payment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 11px;
  width: 100%;
}
.vast-bonus-and-coupon__bonus-section__button {
  &:hover {
    cursor: pointer;
  }
}
</style>
