<template>
  <Modal
    v-model="isShown"
    width="286px"
    height="240px"
  >
    <EmptyServices @close="isShown = !isShown" />
  </Modal>
</template>

<script>
import useStoreBinding from '@/mixins/use-store-binding';
import EmptyServices from './EmptyServices.vue';

export default {
  name: 'ModalEmptyServices',
  components: { EmptyServices },
  mixins: [
    useStoreBinding('isShown', 'isShownEmptyServicesModal', 'SET_IS_SHOWN_EMPTY_SERVICES_MODAL'),
  ],
};
</script>
