var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"serviceTypesContainer",staticClass:"vast-service-service-select"},[_c('ServiceSettingsModal'),_c('OLabel',{staticClass:"vast-service-service-select__label"},[_vm._v(" "+_vm._s(_vm.selectServiceTitle)+" ")]),(_vm.loading)?_c('OLoader'):_vm._e(),_c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.updateResize),expression:"updateResize"}],staticClass:"vast-service-service-select__scroll-wrapper"},[(_vm.selectedNetwork
        && _vm.selectedNetwork.serviceTypes
        && _vm.selectedNetwork.serviceTypes.length
        && _vm.sortedServiceTypes.length > 0)?_c('div',{ref:"serviceSelectRow",staticClass:"vast-service-service-select__scroll"},[_c('div',{staticClass:"vast-service-service-select__first"},_vm._l((_vm.firstServiceTypes),function(serviceType,index){return _c('div',{key:index,staticClass:"vast-service-service-select__container"},[(serviceType.id)?_c('ServiceType',{class:{ 'last-card-in-row': ((index + 1) % _vm.countRow) === 0 },attrs:{"service-type":serviceType}}):_c('div',{class:[
              'vast-service-service-select__empty-card',
              { 'last-card-in-row': ((index + 1) % _vm.countRow) === 0 }
            ]})],1)}),0),_c('div',{class:[
          'vast-service__animated-element',
          { show: _vm.isAllServiceTypeShown }
        ],style:(_vm.elementStyle)},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAllServiceTypeShown),expression:"isAllServiceTypeShown"}],ref:"content",staticClass:"vast-service-service-select__second vast-service__inner-content"},_vm._l((_vm.secondServiceTypes),function(serviceType,index){return _c('div',{key:index,staticClass:"vast-service-service-select__container"},[(serviceType.id)?_c('ServiceType',{class:{
                'last-card-in-row': ((index + 1) % _vm.countRow) === 0
              },attrs:{"service-type":serviceType}}):_c('div',{class:[
                'vast-service-service-select__empty-card',
                { 'last-card-in-row': ((index + 1) % _vm.countRow) === 0 }
              ]})],1)}),0)])]):(!_vm.loading)?_c('div',{staticClass:"vast-service-service-select__empty"},[_vm._v(" "+_vm._s(_vm.locale.noServices)+" ")]):_vm._e(),(_vm.isShownMore)?_c('ShowMoreLine',{attrs:{"is-show-more":_vm.isAllServiceTypeShown},on:{"click":_vm.showMore}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }