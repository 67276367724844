var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'vast-service-order-now-card',
    {
      'vast-service-order-now-card--selected': _vm.isSelected,
      'vast-service-order-now-card--inactive': _vm.isInactive,
      'vast-service-order-now-card--dense': _vm.dense,
      'vast-service-order-now-card--selected_from-link': _vm.networkIsChangedFromLink.color
    }
  ],style:({
    minHeight: _vm.minHeight,
  }),on:{"click":function($event){_vm.isSelected = _vm.value}}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }