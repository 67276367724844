import settings from '@/settings';

export default ({ text, viewType }) => {
  if (!text) {
    return [];
  }
  return `${text}`
    .split(settings.splitters[viewType])
    .map((item) => {
      const trimmedItem = item.trim();
      return trimmedItem.length ? trimmedItem : null;
    })
    .filter((item) => item !== null);
};
