<template functional>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.53341 25.3337L6.66675 23.467L14.1334 16.0003L6.66675 8.53366L8.53341
      6.66699L16.0001 14.1337L23.4667 6.66699L25.3334 8.53366L17.8667 16.0003L25.3334
      23.467L23.4667 25.3337L16.0001 17.867L8.53341 25.3337Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: [String, Number],
      default: '100%',
    },
    height: {
      type: [String, Number],
      default: '100%',
    },
  },
};
</script>
