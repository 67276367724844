<template>
  <div
    v-if="networksAreShown"
    ref="networksContainer"
    v-resize="updateResize"
  >
    <OLabel>
      {{ locale.networksTitle }}
    </OLabel>

    <OLoader v-if="loading" />

    <div
      v-else-if="isMobile"
      class="vast-networks__mobile"
    >
      <Network
        v-for="network in availableNetworks"
        :key="network.id"
        :network="network"
      />
    </div>

    <div v-else>
      <div class="vast-networks">
        <div
          v-for="(network, index) in firstNetworks"
          :key="index"
        >
          <Network
            v-if="network.id"
            :network="network"
            :class="{ 'last-card-in-row': ((index + 1) % countNetworksInRow) === 0 }"
          />

          <div
            v-else
            :class="[
              'vast-networks__empty-card',
              { 'last-card-in-row': ((index + 1) % countNetworksInRow) === 0 }
            ]"
          />
        </div>
      </div>

      <div
        :class="[
          'vast-networks__animated-element',
          { show: isAllNetworksShown }
        ]"
        :style="elementStyle"
      >
        <div
          v-show="isAllNetworksShown"
          class="vast-networks vast-networks__inner-content"
          ref="content"
        >
          <div
            v-for="(network, index) in secondNetworks"
            :key="index"
          >
            <Network
              v-if="network.id"
              :network="network"
              :class="{ 'last-card-in-row': ((index + 1) % countNetworksInRow) === 0 }"
            />

            <div
              v-else
              :class="[
                'vast-networks__empty-card',
                { 'last-card-in-row': ((index + 1) % countNetworksInRow) === 0 }
              ]"
            />
          </div>
        </div>
      </div>

      <ShowMoreLine
        v-if="isShownMore"
        :is-show-more="isAllNetworksShown"
        @click="showMore"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ShowMoreLine from '@/components/ShowMoreLine.vue';
import Network from './Network.vue';

export default {
  components: {
    Network,
    ShowMoreLine,
  },
  inject: [
    'widgetJSApiInstance',
  ],
  data: () => ({
    width: 0,
    countNetworksInRow: 13,
    isAllNetworksShown: false,
    isMobile: false,
    PER_PAGE_CUSTOM: [
      [270, 3],
      [370, 4],
      [465, 5],
      [558, 6],
      [651, 7],
    ],
    SLIDE_WIDTH: 78,
    colorActiveNavigation: '#848484',
    colorNavigation: '#C4C4C4',
    elementStyle: {
      maxHeight: '0',
      overflow: 'hidden',
      transition: 'max-height 0.5s ease-in-out',
    },
  }),
  created() {
    this.widgetJSApiInstance.addActionCallback('getNetworks', () => this.$store.state.networks);
    this.widgetJSApiInstance.addActionCallback('getSelectedNetwork', () => this.$store.state.selectedNetworkId);

    this.widgetJSApiInstance.addActionCallback('selectNetwork', (id) => {
      const foundNetwork = this.networks.find((social) => social.id === id);
      if (foundNetwork) {
        this.$store.dispatch('setSelectedNetworkId', id);
      }
    });
    this.widgetJSApiInstance.addActionCallback('hideNetworks', () => {
      this.isAllNetworksShown = false;
    });
    this.widgetJSApiInstance.addActionCallback('showNetworks', () => {
      this.isAllNetworksShown = true;
    });
  },
  watch: {
    networks() {
      if (!this.isNetworkSelected) {
        const [firstNetwork] = this.networks;
        this.$store.dispatch('setSelectedNetworkId', firstNetwork.id);
        this.colorActiveNavigation = getComputedStyle(document.documentElement).getPropertyValue('--network-selected-color');
        this.colorNavigation = getComputedStyle(document.documentElement).getPropertyValue('--primary-button-disabled-color');
      }
    },
  },
  computed: {
    ...mapState('networks', [
      'networks',
      'loading',
    ]),
    ...mapGetters('networks', [
      'availableNetworks',
    ]),
    ...mapState('config', [
      'networksAreShown',
    ]),
    ...mapGetters([
      'isNetworkSelected',
    ]),
    ...mapGetters('config', [
      'locale',
    ]),
    firstNetworks() {
      const networks = this.availableNetworks.slice(0, this.countNetworksInRow);
      const countEmptyNet = this.availableNetworks.length > this.countNetworksInRow
        ? 0
        : this.countNetworksInRow - networks.length;

      return [...networks, ...new Array(countEmptyNet).fill({})];
    },
    secondNetworks() {
      const countNetworks = this.availableNetworks.length - this.countNetworksInRow;
      const networks = this.availableNetworks.slice(countNetworks * -1);
      const countEmptyNet = networks.length > this.countNetworksInRow
        ? this.countNetworksInRow - (networks.length % this.countNetworksInRow)
        : this.countNetworksInRow - networks.length;

      return [...networks, ...new Array(countEmptyNet).fill({})];
    },
    isShownMore() {
      return this.countNetworksInRow < this.availableNetworks.length;
    },
  },
  methods: {
    showMore() {
      if (!this.isAllNetworksShown) {
        this.isAllNetworksShown = true;
        this.$nextTick(() => {
          this.elementStyle.maxHeight = `${this.$refs.content.scrollHeight}px`;
        });
      } else {
        this.elementStyle.maxHeight = '0';
        setTimeout(() => {
          this.isAllNetworksShown = false;
        }, 500);
      }
    },
    checkIsMobile({ containerWidth }) {
      this.isMobile = containerWidth < 688;
    },
    updateResize() {
      const containerWidth = this.$refs.networksContainer.getBoundingClientRect().width;
      this.countNetworksInRow = parseInt((containerWidth + 15) / (this.SLIDE_WIDTH + 15), 10);
      this.checkIsMobile({ containerWidth });
    },
  },
};
</script>

<style lang="scss" scoped>
  .vast-networks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &__empty-card {
      width: 78px;
      height: 80px;
      margin: 0 15px 15px 0;
    }
    &__mobile {
      display: flex;
      gap: 8px;
      overflow: auto;
      padding-bottom: 12px;
    }
  }
  ::v-deep .last-card-in-row {
    margin-right: 0px!important;
  }
</style>
