<template>
  <div class="vast-service-order-now-modal-header">
    <div>
      <slot />
    </div>

    <button
      @click="$emit('close')"
      class="vast-service-order-now-modal-header__close"
    >
      <IconClose width="23" />
    </button>
  </div>
</template>

<script>
import IconClose from '@/components/icons/IconClose.vue';

export default {
  components: {
    IconClose,
  },
};
</script>

<style lang="scss" scoped>
.vast-service-order-now-modal-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 32px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0px;
    border: 0;
    border-radius: 4px;
    outline: none;
    color: var(--secondary-button-color);
    background: 0;
    transition: all 0.25s;

    &:hover, &:focus {
      cursor: pointer;
    }
    &:hover {
      box-shadow: 6px 6px 10px 0px rgba(0, 0, 0, 0.10);
    }
  }
}
</style>
