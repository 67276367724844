<template>
  <CardSelect
    :class="[
      'vast-payment-method',
      { 'vast-payment-method--selected':
        computedSelectedPaymentMethodId === paymentMethod.id },
    ]"
    v-model="computedSelectedPaymentMethodId"
    :value="paymentMethod.id"
    :min-height="minHeight"
    dense
  >

    <img
      :class="[
        'vast-payment-method__logo',
        {
          'vast-payment-method__logo--selected':
            computedSelectedPaymentMethodId === paymentMethod.id,
        }
      ]"
      :src="paymentMethod.icon"
      :alt="paymentMethod.name"
    >

    <div class="vast-payment-method__label">
      {{ paymentMethod.name }}
    </div>
  </CardSelect>
</template>

<script>
import CardSelect from '@/components/CardSelect.vue';

export default {
  components: {
    CardSelect,
  },
  props: {
    paymentMethod: {
      type: Object,
      default: () => ({}),
    },
    selectedPaymentMethodId: {
      type: [String, Number],
      default: null,
    },
    minHeight: {
      type: String,
      default: '0px',
    },
  },
  computed: {
    computedSelectedPaymentMethodId: {
      get() {
        return this.selectedPaymentMethodId;
      },
      set(val) {
        this.$emit('selected', val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.vast-payment-method {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  justify-self: center;
  padding: 12px;
  width: 140px;
  height: auto;
  border-radius: 12px;
  background-color: var(--network-color);
  color: var(--network-text-color);

  &__logo {
    height: 29px;

    &--selected {
      opacity: 1;
    }
  }

  &__label {
    margin-top: 6px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-align: center;

    &--inactive {
      color: #B6B6B6;
    }
  }
  &:hover {
    box-shadow: 6px 6px 30px 0px rgba(0, 0, 0, 0.20);
  }
  &--selected {
    background-color: var(--network-selected-color);
    color: var(--network-selected-text-color);
  }
}

@media (max-width: 768px) {
  .vast-payment-method {
    width: 133px;
  }
}
</style>
