var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.networksAreShown)?_c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.updateResize),expression:"updateResize"}],ref:"networksContainer"},[_c('OLabel',[_vm._v(" "+_vm._s(_vm.locale.networksTitle)+" ")]),(_vm.loading)?_c('OLoader'):(_vm.isMobile)?_c('div',{staticClass:"vast-networks__mobile"},_vm._l((_vm.availableNetworks),function(network){return _c('Network',{key:network.id,attrs:{"network":network}})}),1):_c('div',[_c('div',{staticClass:"vast-networks"},_vm._l((_vm.firstNetworks),function(network,index){return _c('div',{key:index},[(network.id)?_c('Network',{class:{ 'last-card-in-row': ((index + 1) % _vm.countNetworksInRow) === 0 },attrs:{"network":network}}):_c('div',{class:[
            'vast-networks__empty-card',
            { 'last-card-in-row': ((index + 1) % _vm.countNetworksInRow) === 0 }
          ]})],1)}),0),_c('div',{class:[
        'vast-networks__animated-element',
        { show: _vm.isAllNetworksShown }
      ],style:(_vm.elementStyle)},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAllNetworksShown),expression:"isAllNetworksShown"}],ref:"content",staticClass:"vast-networks vast-networks__inner-content"},_vm._l((_vm.secondNetworks),function(network,index){return _c('div',{key:index},[(network.id)?_c('Network',{class:{ 'last-card-in-row': ((index + 1) % _vm.countNetworksInRow) === 0 },attrs:{"network":network}}):_c('div',{class:[
              'vast-networks__empty-card',
              { 'last-card-in-row': ((index + 1) % _vm.countNetworksInRow) === 0 }
            ]})],1)}),0)]),(_vm.isShownMore)?_c('ShowMoreLine',{attrs:{"is-show-more":_vm.isAllNetworksShown},on:{"click":_vm.showMore}}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }