export default {
  namespaced: true,
  state: {
    isShown: false,
    couponCode: '',
  },
  mutations: {
    SET_IS_SHOWN: (state, val) => { state.isShown = val; },
    SET_COUPON_CODE: (state, val) => { state.couponCode = val; },
  },
};
