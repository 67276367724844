<template>
  <field v-bind="$props">
    <textarea
      v-bind="$props"
      v-model="modeledValue"
      :rows="rows"
      class="inherited-field-styles o-textarea"
      :placeholder="placeholder"
      :style="{
        color: theme.mainTextColFor
      }"
      @focus="$emit('focus')"
    />
  </field>
</template>

<script>
import { mapState } from 'vuex';
import useInputOptions from '@/mixins/use-input-options';
import useVModel from '@/mixins/use-vmodel';
import Field from './Field.vue';

export default {
  components: {
    Field,
  },
  mixins: [
    useInputOptions,
    useVModel(),
  ],
  computed: {
    ...mapState('config', [
      'theme',
    ]),
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    rows: {
      type: [String, Number],
      default: 5,
    },
  },
};
</script>

<style lang="scss" scoped>
.o-textarea {
  resize: vertical;
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &::placeholder {
    color: var(--input-text-color);
  }
  &:focus {
    outline: none;
    border: 1px solid var(--input-text-color)!important;
  }
}
</style>
