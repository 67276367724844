<template>
  <div
    class="label"
    :style="{
      color: theme.titleColor
    }"
  >
    <slot />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('config', [
      'theme',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.label {
  margin-bottom: 10px;
  color: var(--title-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
</style>
