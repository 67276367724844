import Vue from 'vue';
import Vuex from 'vuex';

import ValidationError from '@/utils/ValidationError';
import eventBus from '@/utils/eventBus';
import { getPaymentStrategy } from '@clickadilla/components/PaymentStrategies';
import getGaClientId from '@/utils/getGaClientId';
import getGa4ClientId from '@/utils/getGa4ClientId';
import getGa4SessionId from '@/utils/getGa4SessionId';
import bonusInfoModalModule from '@/store/bonusInfoModal';
import settings from '@/settings';
import couponsModule from '@/store/coupons';
import saveGateway from '@/utils/saveGateway';
import configModule from './config';
import serviceSettingsModalModule from './serviceSettingsModal';
import paymentMethodsModuleFactory from './paymentMethods';
import costsModuleFactory from './costs';
import urlModuleFactory from './url';
import networksModuleFactory from './networks';
import settingsModuleFactory from './settings';

Vue.use(Vuex);

export default (api, widgetJSApiInstance) => new Vuex.Store({
  state: {
    countries: [],
    languages: [],

    selectedNetworkId: '',
    selectedServiceTypesIds: [],
    email: '',
    selectedPaymentMethodId: null,

    errors: {},
    creatingLoading: false,
    countriesLoading: false,

    networkIsChangedFromLink: {
      color: false,
      text: false,
    },
    isShownEmptyServicesModal: false,
  },
  mutations: {
    SET_SELECTED_NETWORK_ID: (state, id) => {
      state.selectedNetworkId = id;
      widgetJSApiInstance.dispatchEvent('onNetworkSelected', id);
    },
    SET_SELECTED_SERVICE_TYPES_IDS: (state, val) => {
      state.selectedServiceTypesIds = val;
      widgetJSApiInstance.dispatchEvent('onServicesSelected', val);
    },
    SET_EMAIL: (state, val) => { state.email = val; },
    SET_SELECTED_PAYMENT_METHOD_ID: (state, val) => { state.selectedPaymentMethodId = val; },

    SET_COUNTRIES_LOADING: (state, val) => { state.countriesLoading = val; },
    SET_COUNTRIES: (state, val) => { state.countries = val; },

    SET_LANGUAGES: (state, val) => { state.languages = val; },

    SET_ERRORS: (state, val) => { state.errors = val; },
    SET_CREATING_LOADING: (state, val) => { state.creatingLoading = val; },
    SET_NETWORK_IS_CHANGED_FROM_LINK_COLOR: (state, val) => {
      state.networkIsChangedFromLink.color = val;
    },
    SET_NETWORK_IS_CHANGED_FROM_LINK_TEXT: (state, val) => {
      state.networkIsChangedFromLink.text = val;
    },
    SET_IS_SHOWN_EMPTY_SERVICES_MODAL: (state, val) => { state.isShownEmptyServicesModal = val; },
  },
  actions: {
    async createOrder({
      state, rootState, commit, getters, dispatch,
    }) {
      if (!getters.selectedServiceTypes.length) {
        dispatch('showEmptyServicesModal');

        return;
      }
      const { strategy } = getters.selectedPaymentMethod;
      widgetJSApiInstance.dispatchEvent('onFormSend');
      eventBus.$emit(settings.saveEmailEvent, state.email);

      commit('SET_CREATING_LOADING', true);
      commit('SET_ERRORS', {});
      const order = {
        selectedServiceTypes: getters.selectedServiceTypes,
        url: state.url.url,
        email: state.email,
        paymentMethod: getters.selectedPaymentMethod,
        gaClientId: getGaClientId(),
        ga4ClientId: null,
        ga4SessionId: null,
        couponCode: state.coupons.couponCode,
      };
      order.ga4ClientId = await getGa4ClientId(
        rootState.settings.analytics?.googlAnalytics4Id,
      );
      order.ga4SessionId = await getGa4SessionId(
        rootState.settings.analytics?.googlAnalytics4Id,
      );
      api.createOrder(order)
        .then((data) => {
          const method = getPaymentStrategy(strategy);

          if (method) {
            const showableData = method(data);
            saveGateway();
            widgetJSApiInstance.dispatchEvent('onInvoiceCreated', {
              strategy,
              data: showableData,
            });

            if (showableData) {
              if (strategy === 'qr_code') {
                eventBus.$emit('qrcode-payment', showableData);
              }
              if (strategy === 'message') {
                eventBus.$emit('manual-payment', showableData);
              }
            }
          }
        })
        .catch((error) => {
          if (error instanceof ValidationError) {
            commit('SET_ERRORS', error.messages);
          }
        })
        .finally(() => {
          commit('SET_CREATING_LOADING', false);
        });
    },
    showEmptyServicesModal({ commit }) {
      commit('SET_IS_SHOWN_EMPTY_SERVICES_MODAL', true);
    },
    fetchCountries({ commit }) {
      commit('SET_COUNTRIES_LOADING', true);
      api.getCountries()
        .then((countries) => {
          commit('SET_COUNTRIES', countries);
        })
        .finally(() => {
          commit('SET_COUNTRIES_LOADING', false);
        });
    },
    fetchLanguages({ commit }) {
      api.getLanguages()
        .then((languages) => {
          commit('SET_LANGUAGES', languages);
        });
    },
    setSelectedNetworkId({ commit }, val) {
      commit('SET_SELECTED_NETWORK_ID', val);
    },
    networkChangedFromLinkText({ commit }, value) {
      commit('SET_NETWORK_IS_CHANGED_FROM_LINK_TEXT', value);
    },
    networkChangedFromLink({ commit, dispatch }) {
      commit('SET_NETWORK_IS_CHANGED_FROM_LINK_COLOR', true);
      dispatch('networkChangedFromLinkText', true);
      setTimeout(() => {
        commit('SET_NETWORK_IS_CHANGED_FROM_LINK_COLOR', false);
      }, 600);
    },
  },
  modules: {
    config: configModule,
    serviceSettingsModal: serviceSettingsModalModule,
    bonusInfoModal: bonusInfoModalModule,
    coupons: couponsModule,
    paymentMethods: paymentMethodsModuleFactory(api, widgetJSApiInstance),
    costs: costsModuleFactory(api, widgetJSApiInstance),
    url: urlModuleFactory(api, widgetJSApiInstance),
    networks: networksModuleFactory(api, widgetJSApiInstance),
    settings: settingsModuleFactory(api, widgetJSApiInstance),
  },
  getters: {
    isNetworkSelected(state) {
      return !!state.network;
    },
    selectedPaymentMethod(state) {
      return state.paymentMethods.paymentMethods.find(
        (method) => method.id === state.selectedPaymentMethodId,
      );
    },
    selectedNetwork(state) {
      return state.networks.networks.find(
        (network) => network.id === state.selectedNetworkId,
      );
    },
    selectedServiceTypes(state, getters) {
      return state.selectedServiceTypesIds.reduce((acc, id) => {
        const selectedServiceType = getters.selectedNetwork.serviceTypes.find(
          (serviceType) => serviceType.id === id,
        );

        if (selectedServiceType) {
          acc.push(selectedServiceType);
        }

        return acc;
      }, []);
    },
    allServiceTypes(_, getters) {
      return getters?.selectedNetwork?.serviceTypes || [];
    },
    isPaymentMethodSelected(state) {
      return !!Object.keys(state.paymentMethod).length;
    },
  },
});
