import Vue from 'vue';

const requireComponent = require.context(
  './components/global',
  false,
  /\.(vue|js)$/,
);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);

  Vue.component(
    fileName
      .split('/')
      .pop()
      .replace(/\.\w+$/, ''),
    componentConfig.default || componentConfig,
  );
});
