<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9999 20.1673C16.0625 20.1673 20.1666 16.0633 20.1666
      11.0007C20.1666 5.93804 16.0625 1.83398 10.9999 1.83398C5.93731
      1.83398 1.83325 5.93804 1.83325 11.0007C1.83325 16.0633 5.93731
      20.1673 10.9999 20.1673Z"
      fill="#008001"
      stroke="#008001"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 7.33398V11.0007"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 14.666H11.0092"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
};
</script>
