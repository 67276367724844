<template>
  <div class="vast-service-order-now-url-input">
    <div class="vast-service-order-now-url-input__container">
      <OInput
        v-model="urlModel"
        class="vast-service-order-now-url-input__field"
        :label="urlLabelTitle"
        :placeholder="placeholder"
        :errors="errors"
        :loading="loading"
        @input="clearErrors"
        @blur="sendgridUrl"
        @keydown="keydownUrlInput"
        large
      />
      <span class="vast-service-order-now-url-input__info-text">
        {{ locale.urlInformation }}
      </span>
    </div>

    <p
      v-show="networkIsChangedFromLink.text"
      class="vast-service-order-now-url-input__network-change-from-url"
    >
      {{ locale.networkIsChangeFromUrlBefore }}
      {{ selectedNetworkName }}
      {{ locale.networkIsChangeFromUrlAfter }}
    </p>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import debounce from 'debounce';
import useStoreBinding from '@/mixins/use-store-binding';

export default {
  mixins: [
    useStoreBinding('url', 'url.url', 'url/SET_URL'),
  ],
  inject: [
    'widgetJSApiInstance',
    'api',
  ],
  created() {
    this.widgetJSApiInstance.addActionCallback('getURL', () => this.url);

    this.widgetJSApiInstance.addActionCallback('setURL', (url) => {
      this.url = url;
      this.urlModel = url;
    });

    this.networkChangedFromLinkTextDebounce = debounce(this.networkChangedFromLinkText, 2000);
  },
  watch: {
    // eslint-disable-next-line
    'networkIsChangedFromLink.color' (newVal) {
      if (newVal) return;
      this.networkChangedFromLinkTextDebounce();
    },
  },
  computed: {
    ...mapState('url', [
      'loading',
    ]),
    ...mapState({
      creatingErrors: (state) => state.errors,
      networkIsChangedFromLink: (state) => state.networkIsChangedFromLink,
    }),
    ...mapState('url', {
      urlCheckErrors: (state) => state.errors,
    }),
    ...mapState('config', [
      'networksAreShown',
    ]),
    ...mapGetters('config', [
      'locale',
    ]),
    ...mapGetters([
      'selectedNetwork',
    ]),
    errors() {
      return [
        ...(this.urlCheckErrors.url || []),
        ...(this.creatingErrors.url || []),
      ];
    },
    placeholder() {
      return this.selectedNetwork ? this.selectedNetwork.urlPlaceholder : '';
    },
    selectedNetworkName() {
      return this.selectedNetwork ? this.selectedNetwork.name : '';
    },
    urlModel: {
      get() {
        return this.url;
      },
      set(val) {
        this.$store.dispatch('networks/identifyNetworkAndFetchServiceTypes', val);
        this.widgetJSApiInstance.dispatchEvent('onURLInput', val);
        this.url = val;
      },
    },
    styleInput() {
      return {
        backgroundColor: 'red',
      };
    },
    urlLabelTitle() {
      return this.networksAreShown ? this.locale.urlLabel : this.locale.urlLabelWithoutNetworks;
    },
  },
  methods: {
    clearErrors() {
      this.urlCheckErrors.url = [];
      this.creatingErrors.url = [];
    },
    sendgridUrl() {
      this.api.sendgridUrl();
    },
    networkChangedFromLinkText() {
      this.$store.dispatch('networkChangedFromLinkText', false);
    },
    keydownUrlInput(event) {
      const isCtrlPressed = (event.ctrlKey || event.metaKey);
      const isVPressed = (event.key === 'v' || event.code === 'KeyV');

      if (isCtrlPressed && isVPressed) {
        this.widgetJSApiInstance.dispatchEvent('onURLInsert');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vast-service-order-now-url-input {
  margin-top: 20px;
}
.vast-service-order-now-url-input__network-change-from-url {
  color: #ffb116;
}
.vast-service-order-now-url-input__container {
  display: flex;
  align-items: center;
}
.vast-service-order-now-url-input__field {
  width: 100%;
}
.vast-service-order-now-url-input__info-text {
  min-width: 239px;
  margin-left: 14px;
  color: var(--text-color);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

@media (max-width: 768px) {
  .vast-service-order-now-url-input__container {
    flex-wrap: wrap;
  }
  .vast-service-order-now-url-input__info-text {
    width: 100%;
    margin-left: 0px;
  }
}
</style>
